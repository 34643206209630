import {
	Paper,
	Icon,
	Box,
	Typography,
	Grid2 as Grid,
	Card,
	Button,
	List,
	ListSubheader,
	Tooltip,
	ListItem
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { CircularProgressCustom, FerramentasDaListagem } from 'shared/components';
import { LayoutBaseDePagina } from 'shared/layouts';

import './mapLinks.css';
import { useEmpresaSelecionadaContext, useEspera } from 'contexts';
import { combineLatest, from, of, switchMap, take, tap, throwError } from 'rxjs';

import { Setor, Tipologia } from 'models';
import { ToastErro } from 'utils/toast';
import { ListaDocumentos } from 'shared/components/listagemDocumentos';
import { SetorService } from 'shared/services/Setores/SetoresService';
import { CINZA } from 'styles';
import { Moment } from 'moment';
import { PermissaoService } from 'shared/services/Permissoes/PermissoesService';
import { EPermissao } from 'enums';


export const DocumentosAvulsos: React.FC = () => {
	const navigate = useNavigate();
	const { state } = useLocation();

	const [tipologia, setTipologia] = useState<Tipologia | undefined>();
	const [setores, setSetores] = useState<Setor[]>([]);
	const [permissoes, setPermissoes] = useState<EPermissao[]>([]);

	const { empresaSelecionada, perfilSelecionado } = useEmpresaSelecionadaContext();
	const { BloquearTela, DesbloquearTela } = useEspera();

	const [busca, setBusca] = useState<string>(state?.busca || '');
	const [pagina, setPagina] = useState<number>(state?.pagina || 1);
	const [datas, setDatas] = useState<(Moment | null)[] | []>([]);

	useEffect(() => {
		if (!perfilSelecionado || !empresaSelecionada)
			return;
		BloquearTela();
		combineLatest({
			setores: from(SetorService.getSectorsWithTipologies(empresaSelecionada.empresaID))
				.pipe(
					switchMap((resultado) => {
						if (resultado instanceof Error)
							return throwError(() => resultado.message);
						return of(resultado);
					})
				),
			permissoes: from(PermissaoService.getPermissionsByModule(state.moduloID || 1, perfilSelecionado?.perfilID || 1))
				.pipe(
					switchMap((resposta) => {
						if (resposta instanceof Error) return throwError(() => resposta.message);
						return of(resposta.map(permissaoId => permissaoId as EPermissao));
					})
				),
		})
			.pipe(
				take(1),
				tap(() => DesbloquearTela())
			)
			.subscribe({
				next: ({ setores, permissoes }: any) => {
					setPermissoes(permissoes);
					setSetores(setores);
				},
				error: (error) => ToastErro(error)
			});
	}, [perfilSelecionado, empresaSelecionada]);

	useEffect(() => {
		if (setores.length) {
			const todasTipologias = setores.reduce((final, { tipologias }) => ([...final, ...tipologias]), [] as Tipologia[]);
			setTipologia(todasTipologias.find(({ tipologiaID }) => tipologiaID === state?.tipologiaId));
		}
	}, [setores]);

	const handlerChangeTipologia = (tipologia: Tipologia | undefined) => {
		setTipologia(tipologia);
	};

	return (
		<LayoutBaseDePagina>
			<Box padding={1} display='flex' justifyContent='center' alignItems='center' sx={{ backgroundColor: '#D3D3D3' }}>
				<Box sx={{ flexGrow: 1 }}>
					<Grid container spacing={1}>
						<Grid size={2}>
							<List
								sx={{ bgcolor: 'background.paper', borderRadius: 2, overflow: 'hidden' }}
								component='nav'
								aria-labelledby='lista-tipologia'
								subheader={
									<ListSubheader sx={{ px: 1, borderRadius: 2, width: '100%' }} id='lista-tipologia'>
										Setor/Tipologias
									</ListSubheader>
								}
								dense
							>
								<CircularProgressCustom loadding={!setores.any()}>
									{setores.map(({ setorID, nome, tipologias }) => (
										<ListItem key={setorID} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start', padding: 0 }}>
											<Tooltip title={nome} arrow placement='right'>
												<Box display='flex' flexDirection='row' alignItems='center' width='100%' >
													<Icon color='disabled'>arrow_right</Icon>
													<Typography whiteSpace='nowrap' fontSize={12} fontStyle='italic' color={CINZA}>
														{nome}
													</Typography>
												</Box>
											</Tooltip>
											<List component='div' disablePadding>
												{tipologias.map((itemTipologia) => (
													<Tooltip title={itemTipologia.nome} arrow placement='right' key={itemTipologia.tipologiaID}>
														<ListItem
															sx={{ pl: 2, cursor: 'pointer' }}
															onClick={() => handlerChangeTipologia(itemTipologia)}
														>
															<Icon>arrow_right</Icon>
															<Typography whiteSpace='nowrap' fontSize={15} fontWeight={tipologia?.tipologiaID == itemTipologia.tipologiaID ? 600 : 0} display='flex' flexDirection='row' justifyContent='center'>
																{itemTipologia.nome}
															</Typography>
														</ListItem>
													</Tooltip>
												))}
											</List>
										</ListItem>
									))}
								</CircularProgressCustom>
							</List>
						</Grid>
						<Grid size={10} component={Paper} borderRadius={2} p={1}>
							<Box className='mapFolder' p={2}>
								<Box className='mapMain'>
									<Box className='mapLink' onClick={() => navigate(-1)}>Home</Box>
									<Box className='mapLink' onClick={() => navigate(-1)}>&nbsp;/ Tipologia</Box>&nbsp;
								</Box>
							</Box>
							<FerramentasDaListagem
								mostrarInputBuscaPorData={true}
								mostrarInputBusca={true}
								mostrarBotaoVoltar={false}
								mostrarBotaoNovo={false}
								textoBotaoNovo='Novo'
								textoDaBusca={busca}
								tipologiaBuscaIndexado={tipologia?.tipologiaID.toString()}
								aoClicarEmNovo={() => navigate(-1)}
								marginX={0}
								padding={0}
								aoMudarTextoDeBusca={texto => setBusca(texto)}
								aoMudarDatas={(dataStart, dataEnd) => setDatas([dataStart, dataEnd])}
							/>
							<CircularProgressCustom loadding={!setores.any()}>
								<Box display='flex' p={1} width={300}>
									<Box p={1} flexGrow={1}>
										<Card variant='outlined' sx={{
											boxShadow: 1,
											borderRadius: 1,
											p: 1,
											minWidth: 200,
											maxWidth: 250,
											display: 'flex',
											flexDirection: 'row',
											justifyContent: 'space-between',
											alignItems: 'center'
										}} >
											<Typography key={0} whiteSpace='nowrap' fontSize={10} align='left' color='#085784' fontWeight='bold' minHeight={16}>
												{tipologia?.nome || 'TODAS'}
											</Typography>
											{
												tipologia &&
												<Icon fontSize='small' onClick={() => handlerChangeTipologia(undefined)} sx={{ cursor: 'pointer' }}>close</Icon>
											}
										</Card>
									</Box>
								</Box>
							</CircularProgressCustom>
							<ListaDocumentos
								busca={busca}
								pagina={pagina}
								datas={datas}
								tipologiaId={tipologia?.tipologiaID.toString()}
								botaoDownload={permissoes.some(permissaoId => permissaoId == EPermissao.DOWNLOAD)}
								botaoCancelar={permissoes.some(permissaoId => permissaoId == EPermissao.CANCELAR)}
								onChangePagination={(novaPagina: number) => setPagina(novaPagina)}
							/>
						</Grid>
					</Grid>
				</Box>
			</Box>
			<Grid container sx={{ padding: '1rem', justifyContent: 'start' }}>
				<Button
					color='info'
					disableElevation
					variant='contained'
					onClick={() => navigate(-1)}
					startIcon={<Icon>arrow_back</Icon>} >
					<Typography variant='button' whiteSpace='nowrap' textOverflow='ellipsis' overflow='hidden'>
						Voltar
					</Typography>
				</Button>
			</Grid>
		</LayoutBaseDePagina >
	);
};