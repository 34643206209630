import { Box, Icon, Typography, Grid2 as Grid, List, ListSubheader, Divider, ListItem, Paper, Pagination } from '@mui/material';
import { useEmpresaSelecionadaContext, useEspera, useMensagem, useTema } from 'contexts';
import { FC, useCallback, useEffect, useState } from 'react';
import { combineLatest, from, of, switchMap, tap, throwError } from 'rxjs';
import { IDetalheDocumentosAvulsos } from 'shared/services/DocumentosAvulsos/DocumentosAvulsosService';
import { ApplyDiacritics } from 'utils';
import { ToastErro, ToastSucesso } from 'utils/toast';
import { CircularProgressCustom } from '../circularProgress';
import { DefaultButtonWithIcon } from '../buttons';
import { Documentoservice } from 'shared/services/Documentos/DocumentosService';
import { PDFModal } from '../modalpdf';
import moment, { Moment } from 'moment';

const TAM_TEXTO_DADOS_ANTES = 75;
const TAM_TEXTO_DADOS_DEPOIS = 250;
const TAM_TEXTO_TITULO_ANTES = 100;
const TAM_TEXTO_TITULO_DEPOIS = 200;

interface IDocumentoTitulo {
	dataHoraCadastro: Moment;
	documentoNome: string;
	busca: string;
}

const DocumentoTitulo: FC<IDocumentoTitulo> = ({ documentoNome, busca, dataHoraCadastro }) => {
	let posicoesNome: any[] = [];
	if (busca.length)
		posicoesNome = [...ApplyDiacritics(documentoNome).matchAll(new RegExp(busca, 'gi'))];

	if (!posicoesNome.length)
		return (
			<Box display='flex' alignItems='center'>
				<Icon color='error' sx={{ marginRight: 1 }}>picture_as_pdf</Icon>
				<Typography fontWeight='bold'>{documentoNome}</Typography>
				<Typography fontStyle='italic' fontSize={12} pl={1}>({moment(dataHoraCadastro).format('DD/MM/YYYY')})</Typography>
			</Box>
		);

	return (
		<>
			{
				posicoesNome
					.map(match => match.index)
					.map((posicao, key) => {
						const tamanhoMaximo = documentoNome.length;
						const tamanhoBusca = busca.length;
						const texto = documentoNome.substring(posicao!, posicao! + tamanhoBusca);

						let posicaoTextoAntecessor = posicao! - texto.length - TAM_TEXTO_TITULO_ANTES;
						if (posicaoTextoAntecessor < 0)
							posicaoTextoAntecessor = 0;
						const posicaoInicialTextoSucessor = posicao! + tamanhoBusca;
						let posicaoFinalTextoSucessor = posicaoInicialTextoSucessor + TAM_TEXTO_TITULO_DEPOIS;
						if (posicaoFinalTextoSucessor > tamanhoMaximo)
							posicaoFinalTextoSucessor = tamanhoMaximo;

						return (
							<Box component='span' key={key} display='flex' justifyContent='start'>
								<Icon color='error' sx={{ marginRight: 1 }}>picture_as_pdf</Icon>
								<Typography style={{ fontWeight: 'bold' }}>
									{`${posicaoTextoAntecessor != 0 ? `...${documentoNome.substring(posicaoTextoAntecessor, posicao)}` : documentoNome.substring(posicaoTextoAntecessor, posicao)}`}
									<Box
										component='span'
										sx={{
											p: 0,
											color: 'red',
											textDecoration: 'underline',
											fontWeight: 'bold'
										}}>{texto}</Box>
									{`${posicaoFinalTextoSucessor < tamanhoMaximo ? `${documentoNome.substring(posicaoInicialTextoSucessor, posicaoFinalTextoSucessor)}...` : documentoNome.substring(posicaoInicialTextoSucessor, posicaoFinalTextoSucessor)}`}
								</Typography>
							</Box>
						);
					})
			}
		</>
	);
};

interface IDocumentoItem {
	dados: string;
	busca: string;
}

const DocumentoItem: FC<IDocumentoItem> = ({ dados, busca }) => {
	let posicoesDados: any[] = [];
	if (busca.length)
		posicoesDados = [...ApplyDiacritics(dados).matchAll(new RegExp(busca, 'gi'))];

	if (!posicoesDados.length)
		return (
			<Box>
				<Typography>{`${dados.substring(0, TAM_TEXTO_DADOS_ANTES + TAM_TEXTO_DADOS_DEPOIS)}...`}</Typography>
			</Box>
		);

	return (
		<>
			{
				posicoesDados
					.map(match => match.index)
					.map((posicao, key) => {
						const tamanhoMaximo = dados.length;
						const tamanhoBusca = busca.length;
						const texto = dados.substring(posicao!, posicao! + tamanhoBusca);

						let posicaoTextoAntecessor = posicao! - texto.length - TAM_TEXTO_DADOS_ANTES;
						if (posicaoTextoAntecessor < 0)
							posicaoTextoAntecessor = 0;
						const posicaoInicialTextoSucessor = posicao! + tamanhoBusca;
						let posicaoFinalTextoSucessor = posicaoInicialTextoSucessor + TAM_TEXTO_DADOS_DEPOIS;
						if (posicaoFinalTextoSucessor > tamanhoMaximo)
							posicaoFinalTextoSucessor = tamanhoMaximo;

						return (
							<>
								<Box component='span' key={key}>
									{`${posicaoTextoAntecessor != 0 ? `...${dados.substring(posicaoTextoAntecessor, posicao)}` : ''}`}
									<Box
										component='span'
										sx={{
											p: 0,
											color: 'red',
											textDecoration: 'underline',
											fontWeight: 'bold'
										}}>{texto}</Box>
									{`${posicaoFinalTextoSucessor < tamanhoMaximo ? `${dados.substring(posicaoInicialTextoSucessor, posicaoFinalTextoSucessor)}...` : ''}`}
								</Box>
								<Box component='br' />
								<Box component='br' />
							</>
						);
					})
			}
		</>
	);
};

type TDocumentos = {
	documentos: IDetalheDocumentosAvulsos[];
	quantidade: number;
}

type TBotaoAction = boolean | (() => void);


interface IListaDocumentos {
	pagina: number;
	busca: string;
	datas?: (Moment | null)[];
	tipologiaId?: string;
	botaoDownload?: TBotaoAction;
	botaoVisualizar?: TBotaoAction;
	botaoCancelar?: TBotaoAction;
	botaoDeletar?: TBotaoAction;
	onChangePagination?: (pagina: number) => void;
}

export const ListaDocumentos: FC<IListaDocumentos> = ({ pagina, busca, datas = [], tipologiaId, onChangePagination, botaoDownload, botaoVisualizar, botaoDeletar, botaoCancelar }) => {
	const [isLoading, setIsLoading] = useState(true);
	const [modalOpen, setModalOpen] = useState(false);
	const [base64PDF, setBase64PDF] = useState<string>('');
	const [state, setState] = useState<TDocumentos>({
		documentos: [],
		quantidade: 0,
	});

	const { empresaSelecionada } = useEmpresaSelecionadaContext();

	const {
		cores: { CINZA_OPACO, ERRO, INFO, FUNDO, CINZA }
	} = useTema();

	const { BloquearTela, DesbloquearTela } = useEspera();
	const { Informacao } = useMensagem();

	const { documentos, quantidade } = state;
	const [dataInicio, dataFim] = datas;

	const Visualizar = useCallback((documentoEletronicoId: number) => {
		BloquearTela();
		from(Documentoservice.download(documentoEletronicoId))
			.pipe(
				switchMap((resultado) => {
					if (resultado instanceof Error)
						return throwError(() => resultado.message);
					return of(resultado.data);
				}),
				tap(() => DesbloquearTela())
			)
			.subscribe({
				next: ({ fileContents, fileDownloadName }) => setBase64PDF(fileContents),
				error: (error) => {
					DesbloquearTela();
					ToastErro(error || 'Erro ao buscar os documentos para download');
				},
				complete: () => botaoDownload && typeof botaoDownload != 'boolean' && botaoDownload()
			});
	}, []);

	const Download = useCallback((documentoEletronicoId: number) => {
		BloquearTela();
		from(Documentoservice.download(documentoEletronicoId))
			.pipe(
				switchMap((resultado) => {
					if (resultado instanceof Error)
						return throwError(() => resultado.message);
					return of(resultado.data);
				})
			)
			.subscribe({
				next: ({ fileContents, fileDownloadName }) => {
					DesbloquearTela();
					const byteCharacters = atob(fileContents);
					const byteNumbers = new Array(byteCharacters.length);
					for (let i = 0; i < byteCharacters.length; i++)
						byteNumbers[i] = byteCharacters.charCodeAt(i);
					const byteArray = new Uint8Array(byteNumbers);
					const file = new Blob([byteArray], { type: 'application/pdf;base64' });
					const fileURL = URL.createObjectURL(file);

					const prntWin = window.open('', '_blank', 'maximized=true, resizable=yes, height=660, width=616')!;
					prntWin.document.write('<html><head><title>' + fileDownloadName + '</title></head><body style="margin: 0px;">'
						+ '<embed width="100%" height="100%" name="plugin" src="' + fileURL + '" '
						+ 'type="application/pdf" internalinstanceid="21"></body></html>');
					prntWin.document.close();
					// const janela = window.open(fileURL, '_blank', 'maximized=true, resizable=yes, height=660, width=616');
					// if (janela?.document) {
					// 	janela.document.getElementsByTagName('html')[0]
					// 		.appendChild(document.createElement('head'))
					// 		.appendChild(document.createElement('title'))
					// 		.appendChild(document.createTextNode(fileDownloadName));
					// }
				},
				error: (error) => {
					DesbloquearTela();
					ToastErro(error || 'Erro ao buscar o documento para download');
				},
				complete: () => botaoDownload && typeof botaoDownload != 'boolean' && botaoDownload()
			});
	}, []);

	const handleCloseModal = () => {
		setBase64PDF('');
	};

	const Deletar = useCallback((documentoEletronicoId: number, documentoNome: string) => {
		Informacao(
			'Atenção',
			() =>
				<Typography display='flex' flexDirection='row' whiteSpace='nowrap'>
					Deseja deletar o doocumento <Typography fontWeight={700}>&nbsp;{documentoNome}&nbsp;</Typography>?
				</Typography>,
			[
				{
					label: 'Sim',
					onPress: () => {
						BloquearTela();
						from(Documentoservice.deletar(documentoEletronicoId))
							.pipe(
								switchMap((resultado) => {
									if (resultado instanceof Error)
										return throwError(() => resultado.message);
									return of(resultado.data);
								}),
								tap(() => DesbloquearTela())
							)
							.subscribe({
								next: () => {
									ToastSucesso('Documento deletado com sucesso');
									pesquisarDocumentos();
								},
								error: (error) => ToastErro(error || 'Erro ao deletar documento'),
								complete: () => botaoDeletar && typeof botaoDeletar != 'boolean' && botaoDeletar()
							});
					}
				},
				{
					label: 'Cancelar',
					fechar: true
				}
			]
		);
	}, []);

	const Cancelar = useCallback((documentoEletronicoId: number, documentoNome: string) => {
		Informacao(
			'Atenção',
			() =>
				<Typography display='flex' flexDirection='row' whiteSpace='nowrap'>
					Deseja cencelar o doocumento <Typography fontWeight={700}>&nbsp;{documentoNome}&nbsp;</Typography>?
				</Typography>,
			[
				{
					label: 'Sim',
					onPress: () => {
						BloquearTela();
						from(Documentoservice.cancelar(documentoEletronicoId))
							.pipe(
								switchMap((resultado) => {
									if (resultado instanceof Error)
										return throwError(() => resultado.message);
									return of(resultado.data);
								}),
								tap(() => DesbloquearTela())
							)
							.subscribe({
								next: () => {
									ToastSucesso('Documento cancelado com sucesso');
									pesquisarDocumentos();
								},
								error: (error) => ToastErro(error || 'Erro ao cancelar documento'),
								complete: () => botaoCancelar && typeof botaoCancelar != 'boolean' && botaoCancelar()
							});
					}
				},
				{
					label: 'Cancelar',
					fechar: true
				}
			]
		);
	}, []);

	useEffect(() => {
		pesquisarDocumentos();
	}, [pagina, busca, tipologiaId, dataInicio, dataFim, empresaSelecionada]);

	const pesquisarDocumentos = () => {
		if (!empresaSelecionada)
			return;
		setIsLoading(true);
		BloquearTela();
		combineLatest({
			documentos: from(Documentoservice.getAll(pagina, busca, tipologiaId, empresaSelecionada.empresaID, dataInicio, dataFim))
				.pipe(
					switchMap((resultado) => {
						if (resultado instanceof Error)
							return throwError(() => resultado.message);
						return of(resultado.data);
					})
				),
			quantidade: from(Documentoservice.getTotal(busca, tipologiaId, empresaSelecionada.empresaID, dataInicio, dataFim))
				.pipe(
					switchMap((resultado) => {
						if (resultado instanceof Error)
							return throwError(() => resultado.message);
						return of(resultado.totalCount);
					})
				)
		}).pipe(
			tap(() => {
				setIsLoading(false);
				DesbloquearTela();
			})
		).subscribe({
			next: ({ documentos, quantidade }) => {
				setState({
					documentos,
					quantidade
				});
			},
			error: (error) => ToastErro(error || 'Erro ao buscar os documentos')
		});
	};

	return (
		<CircularProgressCustom loadding={isLoading}>
			<Grid container spacing={1} display='flex' flexDirection='column' width='100%' justifyContent='center'>
				<List
					sx={{ bgcolor: 'background.paper', borderRadius: 2 }}
					component='nav'
					aria-labelledby='lista-tipologia'
					subheader={
						<>
							<ListSubheader sx={{ px: 1, borderRadius: 2 }} component='div' id='lista-tipologia'>
								Documentos
							</ListSubheader>
							<Divider sx={{ mb: 2 }} />
						</>
					}
					dense
				>
					{
						documentos.map(({ documentoEletronicoID, documentoNome, dados, dataHoraCadastro }) =>
							<ListItem
								component={Paper} elevation={3}
								sx={{ p: 0, display: 'flex', flexDirection: 'column', alignItems: 'start', marginY: 2, border: 1 }}
								key={documentoEletronicoID}>
								<Box bgcolor={CINZA_OPACO.opacidade(10)} p={.5} display='flex' justifyContent='space-between' width='100%' sx={{ borderTopLeftRadius: 5, borderTopRightRadius: 5, borderBottom: 1 }}>
									<DocumentoTitulo documentoNome={documentoNome} busca={busca} dataHoraCadastro={dataHoraCadastro} />
									<Box>
										{
											botaoDownload &&
											<DefaultButtonWithIcon
												icon='download'
												sizeIcon={20}
												bgColor={CINZA}
												color={FUNDO}
												tooltip={{ title: 'Download' }}
												sx={{ padding: .2, borderRadius: 1 }}
												onClick={() => Download(documentoEletronicoID)} />
										}
										{
											botaoVisualizar &&
											<DefaultButtonWithIcon
												icon='visibility'
												sizeIcon={18}
												bgColor={INFO}
												color={FUNDO}
												tooltip={{ title: 'Visualizar' }}
												sx={{ padding: .3, borderRadius: 1 }}
												onClick={() => Visualizar(documentoEletronicoID)} />
										}
										{
											botaoDeletar &&
											<DefaultButtonWithIcon
												icon='delete'
												sizeIcon={18}
												bgColor={ERRO}
												color={FUNDO}
												tooltip={{ title: 'Excluir' }}
												sx={{ padding: .3, borderRadius: 1 }}
												onClick={() => Deletar(documentoEletronicoID, documentoNome)} />
										}
										{
											botaoCancelar &&
											<DefaultButtonWithIcon
												icon='close'
												sizeIcon={20}
												bgColor={ERRO}
												color={FUNDO}
												tooltip={{ title: 'Cancelar' }}
												sx={{ padding: .2, borderRadius: 1 }}
												onClick={() => Cancelar(documentoEletronicoID, documentoNome)} />
										}
									</Box>
								</Box>
								<Box p={1}>
									<DocumentoItem dados={dados} busca={busca} />
								</Box>
							</ListItem>
						)
					}
					{!documentos.length && !isLoading &&
						<Typography display='flex' justifyContent='center' fontWeight={500}>
							{process.env.REACT_APP_LISTAGEM_VAZIA}
						</Typography>
					}
				</List>
				<Box padding={2} display='flex' justifyContent='center' alignItems='center'>
					<Pagination
						page={pagina}
						count={Math.ceil(quantidade / parseInt(process.env.REACT_APP_LIMITE_DE_LINHAS!))}
						onChange={(_, novaPagina) => onChangePagination?.(novaPagina)}
					/>
				</Box>
			</Grid>
			<PDFModal base64PDF={base64PDF} open={base64PDF != ''} onClose={handleCloseModal} />
		</CircularProgressCustom>
	);
};