import React from 'react';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import {
	DatePicker,
	DatePickerProps,
	//PickerValidDate,
} from '@mui/x-date-pickers';
import moment, { Moment } from 'moment';
import { useEffect, useState } from 'react';
import CustomCalendarHeader from './components/CustomCalendarHeader';
import Layout from './components/CustomCalenderLayout';
import Day from './components/CustomCalenderDay';
import { DateRangePickerStyled } from './styled';
import { Button, Icon, IconButton, InputAdornment, TextField } from '@mui/material';

moment.locale('pt');

moment.updateLocale('pt', {
	months: [
		'Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho',
		'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'
	],
	monthsShort: 'Jan_Fev_Mar_Abr_Mai_Jun_Jul_Ago_Set_Out_Nov_Dez'.split('_'),
	weekdays: 'Domingo_Segunda-Feira_Terça-Feira_Quarta-Feira_Quinta-Feira_Sexta-Feira_Sábado'.split('_'),
	weekdaysShort: 'Dom_Seg_Ter_Qua_Qui_Sex_Sáb'.split('_'),
	weekdaysMin: 'Dom_2ª_3ª_4ª_5ª_6ª_Sáb'.split('_'),
	longDateFormat: {
		LT: 'HH:mm',
		L: 'DD/MM/YYYY',
		LL: 'D [de] MMMM [de] YYYY',
		LLL: 'D [de] MMMM [de] YYYY [às] LT',
		LLLL: 'dddd, D [de] MMMM [de] YYYY [às] LT',
		LTS: ''
	},
	ordinal: (numero: number) => `${numero}º`
});

export type DateRange = [Moment | null, Moment | null];

interface DateRangePickerProps
	extends Omit<
		DatePickerProps<any>,
		'onChange' | 'value'
	> {
	value: DateRange | null;
	onChange: (dateStart: Moment | null, dateEnd: Moment | null) => void;
}

export const DateRangePicker = ({
	value,
	onChange,
	...restProps
}: DateRangePickerProps) => {
	const [startDate, setStartDate] = useState<Moment | null>(value?.[0] || null);
	const [endDate, setEndDate] = useState<Moment | null>(value?.[1] || null);
	const [open, setOpen] = useState(false);

	const isInRange = (date: Moment): boolean => {
		if (!startDate || !endDate) return false;
		return date.isBetween(startDate, endDate, 'day', '[]');
	};

	const selectAndCloseCalendar = (start: Moment | null, end: Moment | null) => {
		if (start && !end) {
			end = start.clone();
		}
		onChange(start, end);
		setOpen(false);
	};

	const handleToolbarAction = (
		start: Moment | null,
		end: Moment | null,
		action: string
	) => {
		setStartDate(start);
		setEndDate(end);
		if (action !== 'reset') {
			selectAndCloseCalendar(start, end);
		}
	};

	const handleDateChange = (date: Moment | null) => {
		if (!startDate || endDate || (date && date.isBefore(startDate, 'day'))) {
			setStartDate(date);
			setEndDate(null);
		} else {
			setEndDate(date);
			//selectAndCloseCalendar(startDate, date);
		}
	};

	useEffect(() => {
		if (value) {
			setStartDate(value[0]);
			setEndDate(value[1]);
		}
	}, [value]);

	const dataInicial = startDate ? moment(startDate).format('DD/MM/YYYY') : '__/__/____';
	const dataFinal = endDate ? moment(endDate).format('DD/MM/YYYY') : '__/__/____';
	const valorCampo = `${dataInicial} até ${dataFinal}`;
	const iconeLimpar = startDate && endDate;

	return (
		<DateRangePickerStyled>
			<LocalizationProvider dateAdapter={AdapterMoment}>
				<TextField
					size='small'
					id='datarange'
					name='datarange'
					value={valorCampo}
					// onChange={(e) => aoMudarTextoDeBusca?.(e.target.value)}
					placeholder={'dd/mm/aaaa até dd/mm/aaaa'}
					sx={{ width: 275 }}
					onClick={() => setOpen(true)}
					InputProps={{
						readOnly: true,
						endAdornment: (
							<>
								{
									!iconeLimpar &&
									<InputAdornment position='start'>
										<Icon>calendar_month</Icon>
									</InputAdornment>
								}
								{
									iconeLimpar &&
									<InputAdornment position='start'>
										<Icon onClick={() => handleToolbarAction(null, null, 'reset')}>close</Icon>
									</InputAdornment>
								}
							</>
						)
					}} />
				<DatePicker
					sx={{
						'fieldset, .MuiInputAdornment-positionEnd.MuiInputAdornment-outlined.MuiInputAdornment-sizeMedium': {
							display: 'none'
						},
						'input.MuiInputBase-input.MuiOutlinedInput-input.MuiInputBase-inputAdornedEnd': {
							height: 0,
							width: 265,
							padding: 0
						}
					}}
					views={['month', 'year', 'day']}

					value={endDate || startDate || null}
					closeOnSelect={false}
					//disableHighlightToday
					open={open}
					onOpen={() => setOpen(true)}
					onClose={() => selectAndCloseCalendar(startDate, endDate)}
					showDaysOutsideCurrentMonth
					dayOfWeekFormatter={(day, date) =>
						['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'][date.day()]
					}
					slots={{
						day: (day) => (
							<Day
								isInRange={isInRange}
								startDate={startDate}
								endDate={endDate}
								onDateClick={handleDateChange}
								{...day}
							/>
						),
						calendarHeader: (props) => (
							<CustomCalendarHeader
								date={props.currentMonth}
								onMonthChange={props.onMonthChange}
								onViewChange={props.onViewChange}
							/>
						),
						layout: (prop) => (
							<Layout
								handleToolbarAction={handleToolbarAction}
								startDate={startDate}
								endDate={endDate}
								handleClose={() => setOpen(false)}
							>
								{prop.children}
							</Layout>
						),
					}}
					{...restProps}
				/>
			</LocalizationProvider>
		</DateRangePickerStyled>
	);
};
