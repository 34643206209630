import { ESituacao } from 'enums';
import { EmpresaTipologiaRequest } from './EmpresaTipologiaRequest';

export class EmpresaSetorRequest {
	setorID: number;
	empresaSetorID: number;
	situacaoID: ESituacao;
	empresasSetoresTipologias: EmpresaTipologiaRequest[];

	constructor(dto?: any) {
		this.setorID = dto?.setorID;
		this.empresaSetorID = dto?.empresaSetorID;
		this.situacaoID = dto?.situacaoID;
		this.empresasSetoresTipologias = dto?.tipologias?.map((tipologiaEmpresa: EmpresaTipologiaRequest) => new EmpresaTipologiaRequest(tipologiaEmpresa)) || [];
	}
}