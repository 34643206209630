import classNames from 'classnames';
import moment, { Moment } from 'moment';
import { useEffect, useState } from 'react';
import { CalenderLayoutContainer } from '../styled';
import ToolbarButton from './QuickRangeSelector';
import React from 'react';
import { Box, Button, Grid2 as Grid } from '@mui/material';

interface LayoutProps {
	handleToolbarAction: (
		startDate: Moment | null,
		endDate: Moment | null,
		action: string
	) => void;
	handleClose: () => void;
	children: React.ReactNode;
	startDate: Moment | null;
	endDate: Moment | null;
}

const today = moment();

const quickRanges = [
	{
		label: 'Hoje',
		action: 'hoje',
		start: today.clone().startOf('day'),
		end: today.clone().endOf('day'),
	},
	{
		label: 'Ontem',
		action: 'ontem',
		start: today.clone().subtract(1, 'day').startOf('day'),
		end: today.clone().subtract(1, 'day').endOf('day'),
	},
	{
		label: 'Semana Passada',
		action: 'semanapassada',
		start: today.clone().subtract(1, 'week').startOf('week'),
		end: today.clone().subtract(1, 'week').endOf('week'),
	},
	{
		label: 'Mês Passado',
		action: 'mespassado',
		start: today.clone().subtract(1, 'month').startOf('month'),
		end: today.clone().subtract(1, 'month').endOf('month'),
	},
	{
		label: 'Trimestre Passado',
		action: 'trimestrepassado',
		start: today.clone().subtract(1, 'quarter').startOf('quarter'),
		end: today.clone().subtract(1, 'quarter').endOf('quarter'),
	},
] as const;

const Layout = ({
	handleToolbarAction,
	handleClose,
	children,
	startDate,
	endDate,
}: LayoutProps) => {
	const [selectedToolbarAction, setSelectedToolbarAction] = useState<
		string | null
	>(null);

	const handleQuickRangeChange = (action: string) => {
		const range = quickRanges.find((range) => range.action === action);
		handleToolbarAction(range?.start || null, range?.end || null, action);
	};

	useEffect(() => {
		const selectedRange = quickRanges.find(
			({ start, end }) =>
				startDate?.isSame(start, 'day') && endDate?.isSame(end, 'day')
		);
		setSelectedToolbarAction(selectedRange?.action || null);
	}, [startDate, endDate]);

	return (
		<CalenderLayoutContainer sx={{ display: 'flex', flexDirection: 'column' }}>
			<Box display='flex' flexDirection='row'>
				<Box className='toolbar-container' data-testid='quick-range-container'>
					<Box>
						{quickRanges.map(({ label, action }) => (
							<ToolbarButton
								key={action}
								label={label}
								selected={selectedToolbarAction === action}
								action={action}
								onActionClick={handleQuickRangeChange}
							/>
						))}
					</Box>
				</Box>
				{children}
			</Box>
			<Grid display='flex' justifyContent='center'>
				<Button
					sx={{ flex: 1, mx: 1 }}
					color='info'
					disableElevation
					variant='contained'
					onClick={() => handleToolbarAction(startDate, endDate, '')}
				>confirmar</Button>
				<Button
					sx={{ flex: 1, mx: 1 }}
					color='inherit'
					disableElevation
					variant='contained'
					onClick={() => handleQuickRangeChange('reset')}
				>limpar</Button>
			</Grid>
		</CalenderLayoutContainer>
	);
};

export default Layout;
