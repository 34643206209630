import { Usuario } from 'models';
import { ObjectToQuery } from 'utils';
import { Api } from '../Axios';
import { EOrdenacao } from 'enums';
import { UsuarioRequest } from 'requests';

export interface IListagemUsuario {
	usuarioID: number;
	nome: string;
	usuarioIDCadastro: string;
	usuarioNomeCadastro: string;
	usuarioIDAlteracao: string;
	usuarioNomeAlteracao: string;
}

type TUsuariosComTotalCount = {
	data: Usuario[];
	totalCount: number;
}

type TUsuariosSomenteTotalCount = {
	totalCount: number;
}

export interface IListagemUsuarioCampos {
	usuarioID: string;
	fieldName: string;
	ordem: string;
	tipoID: string;
	titulo: string;
}

type TUsuariosComTotal = {
	usuarios: Usuario[];
	total: number;
}

const getTotal = async (page = 1, filter = ''): Promise<TUsuariosSomenteTotalCount | Error> => {
	try {
		const LOCAL_STORAGE_KEY__ACCESS_TOKEN = process.env.REACT_APP_LOCAL_STORAGE_TOKEN;
		const accessToken = localStorage.getItem(LOCAL_STORAGE_KEY__ACCESS_TOKEN!) || '';

		const urlRelativa = '/Usuario/ObterQtdeTotal';
		// const urlRelativa = `/usuarios/getTotal.php?accessToken=${accessToken}&_page=${page}&_limit=${parseInt(process.env.REACT_APP_LIMITE_DE_LINHAS!)}&usuario_nome_like=${filter}`;
		const { data } = await Api.get(urlRelativa);
		return {
			totalCount: Number(data.data),
		};
	} catch (error) {
		return new Error((error as { message: string }).message || 'Erro ao listar os registros.');
	}
};

const getAll = async (usuarioID?: number): Promise<TUsuariosComTotalCount | Error> => {
	try {
		const params = ObjectToQuery({ usuarioID });
		const urlRelativa = `/Usuario/ObterTodos?${params}`;
		const { data, headers } = await Api.get(urlRelativa);

		if (data) {
			return {
				data: data.data,
				totalCount: Number(headers['x-total-count'] || parseInt(process.env.REACT_APP_LIMITE_DE_LINHAS!)),
			};
		}
		return new Error('Erro ao listar os registros.');
	} catch (error) {
		return new Error((error as { message: string }).message || 'Erro ao listar os registros.');
	}
};

const getAllFiels = async (): Promise<IListagemUsuarioCampos[] | Error> => {
	try {
		const LOCAL_STORAGE_KEY__ACCESS_TOKEN = process.env.REACT_APP_LOCAL_STORAGE_TOKEN;
		const accessToken = localStorage.getItem(LOCAL_STORAGE_KEY__ACCESS_TOKEN!) || '';

		const urlRelativa = '/UsuarioCampos/ObterTodos';
		// const urlRelativa = `/usuarios/getAll.php?accessToken=${accessToken}&_page=${page}&_limit=${parseInt(process.env.REACT_APP_LIMITE_DE_LINHAS!)}&usuario_nome_like=${filter}`;
		const { data, headers } = await Api.get(urlRelativa);

		if (data)
			return data.data;

		return new Error('Erro ao listar os registros.');
	} catch (error) {
		return new Error((error as { message: string }).message || 'Erro ao listar os registros.');
	}
};

const getFields = async (usuarioId = '', processoId = ''): Promise<IListagemUsuarioCampos[] | Error> => {
	try {
		const LOCAL_STORAGE_KEY__ACCESS_TOKEN = process.env.REACT_APP_LOCAL_STORAGE_TOKEN;
		const accessToken = localStorage.getItem(LOCAL_STORAGE_KEY__ACCESS_TOKEN!) || '';

		const urlRelativa = `/UsuarioCampos/ObterCamposPorUsuarioId?usuarioId=${usuarioId}&processoId=${processoId}`;
		// const urlRelativa = `/usuarios/getFields.php?accessToken=${accessToken}&usuarioId=${usuarioId}&processoId=${processoId}`;
		const { data, headers } = await Api.get(urlRelativa);

		if (data) {
			return data.data;
		}
		return new Error('Erro ao listar os registros.');
	} catch (error) {
		return new Error((error as { message: string }).message || 'Erro ao listar os registros.');
	}
};

const getWithPagination = async (pagina: number = 1, qtdLinha: number = 10, campoOrdenacao: string, ordenacaoPor: EOrdenacao = EOrdenacao.ASC, filtros: any = {}): Promise<TUsuariosComTotal | Error> => {
	try {
		const queryParams = ObjectToQuery({
			pagina: pagina,
			qtdLinha,
			ordenacaoPor,
			campoOrdenacao,
			...filtros
		});
		const urlRelativa = `/Usuario/ObterPaginado?${queryParams}`;
		const { data } = await Api.get(urlRelativa);

		if (data && data.data)
			return {
				usuarios: data.data.lista?.map((usuario: any) => new Usuario(usuario)),
				total: data.data.total
			};

		return new Error('Erro ao listar os registros.');
	} catch (error) {
		return new Error((error as { message: string }).message || 'Erro ao listar os registros.');
	}
};

const getById = async (id: number): Promise<Usuario | Error> => {
	try {
		const { data } = await Api.get(`/Usuario/ObterPorId?usuarioID=${id}`);

		if (data) {
			return data.data;
		}
		return new Error('Erro ao obter o registro.');
	} catch (error) {
		return new Error((error as { message: string }).message || 'Erro ao obter o registro.');
	}
};

const getByEnterpriseId = async (id: number): Promise<Usuario[] | Error> => {
	try {
		const { data } = await Api.get(`/Usuario/ObterPorId?usuarioID=${id}`);

		if (data) {
			return data.data;
		}
		return new Error('Erro ao obter o registro.');
	} catch (error) {
		return new Error((error as { message: string }).message || 'Erro ao obter o registro.');
	}
};

const inative = async (usuarioID: number): Promise<boolean | Error> => {
	try {
		const urlRelativa = `/Usuario/Inativar`;
		const { data } = await Api.post(urlRelativa, { usuarioID });

		if (data) {
			if (data.data)
				return data.data;

			return new Error(data.errors.join('<br/>'));
		} else
			return new Error('Erro ao inativar usuario.');
	} catch (error) {
		return new Error((error as { message: string }).message || 'Erro ao autenticar!!');
	}
};

const update = async (usuario: UsuarioRequest): Promise<Usuario | Error> => {
	try {
		const urlRelativa = `/Usuario/AlterarEmpresas`;
		const { data } = await Api.post(urlRelativa, usuario);

		if (data) {
			if (data.data)
				return data.data;

			return new Error(data.errors.join('<br/>'));
		} else
			return new Error('Erro ao alterar empresa(s) do usuário.');
	} catch (error) {
		return new Error((error as { message: string }).message || 'Erro ao alterar empresa(s) do usuário.');
	}
};

export const UsuarioService = {
	getTotal,
	getAll,
	getFields,
	getById,
	getByEnterpriseId,
	getAllFiels,
	getWithPagination,
	inative,
	update
};