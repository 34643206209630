import { ESituacao } from '../enums';
import { Municipio } from './Municipio';
import { Perfil } from './Perfil';
import { Setor } from './Setor';

export class Empresa {
	empresaID: number;
	nomeFantasia: string;
	razaoSocial: string;
	responsavel: string;
	logradouro: string;
	numero: string;
	complemento?: string;
	cpfcnpj: string;
	cep: string;
	bairro: string;
	municipio: Municipio;
	telefoneCelular?: string;
	telefoneFixo?: string;
	email: string;
	situacaoID: ESituacao;
	matriz?: Empresa;
	filiais?: Empresa[];
	usuarioIDCadastro?: number;
	usuarioNomeCadastro?: string;
	dataHoraCadastro?: string;
	usuarioIDAlteracao?: number;
	usuarioNomeAlteracao?: string;
	dataHoraAlteracao?: string;
	perfils?: Perfil[];
	perfil?: Perfil;
	setores: Setor[];
	ehNova: boolean;

	constructor(dto?: any) {
		this.empresaID = dto?.empresaID;
		this.nomeFantasia = dto?.nomeFantasia;
		this.razaoSocial = dto?.razaoSocial;
		this.responsavel = dto?.responsavel;
		this.logradouro = dto?.logradouro;
		this.numero = dto?.numero;
		this.complemento = dto?.complemento;
		this.cpfcnpj = dto?.cpfcnpj;
		this.cep = dto?.cep;
		this.bairro = dto?.bairro;
		this.municipio = new Municipio(dto?.municipio);
		this.telefoneCelular = dto?.telefoneCelular;
		this.telefoneFixo = dto?.telefoneFixo;
		this.email = dto?.email;
		this.situacaoID = dto?.situacaoID as ESituacao;
		this.matriz = dto?.matriz ? new Empresa(dto?.matriz) : undefined;
		this.filiais = dto?.filiais?.map((filial: Empresa) => new Empresa(filial));
		this.usuarioIDCadastro = dto?.usuarioIDCadastro;
		this.usuarioNomeCadastro = dto?.usuarioNomeCadastro;
		this.dataHoraCadastro = dto?.dataHoraCadastro;
		this.usuarioIDAlteracao = dto?.usuarioIDAlteracao;
		this.usuarioNomeAlteracao = dto?.usuarioNomeAlteracao;
		this.dataHoraAlteracao = dto?.dataHoraAlteracao;
		this.ehNova = dto?.ehNova || false;
		this.perfil = dto?.perfil;
		this.perfils = dto?.perfils.map((perfil: any) => new Perfil(perfil));
		this.setores = (dto?.empresasSetoresTipologias?.map((setorEmpresa: any) => {
			const tipologias = dto.empresasSetoresTipologias.filter(({ setorID }: any) => setorID == setorEmpresa.setorID);
			return new Setor({ ...setorEmpresa, tipologias });
		}) || []).distinct();
	}
}