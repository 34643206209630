import { createRoot } from 'react-dom/client';
import './index.css';
import { App } from './App';
import 'utils/prototypes';

function AppWithCallbackAfterRender() {
	return <App />;
}

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(<AppWithCallbackAfterRender />);