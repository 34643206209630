import { ESituacao } from 'enums';
import { Tipologia } from './Tipologia';

export class Setor {
	setorID: number;
	nome: string;
	situacaoID: ESituacao;
	usuarioIDCadastro?: number;
	usuarioNomeCadastro?: string;
	dataHoraCadastro?: string;
	usuarioIDAlteracao?: number;
	usuarioNomeAlteracao?: string;
	dataHoraAlteracao?: string;
	empresaSetorID: number;
	tipologias: Tipologia[];

	constructor(dto?: any) {
		this.setorID = dto?.setorID;
		this.nome = dto?.nome || dto?.setorNome;
		this.situacaoID = dto?.situacaoID as ESituacao;
		this.usuarioIDCadastro = dto?.usuarioIDCadastro;
		this.usuarioNomeCadastro = dto?.usuarioNomeCadastro;
		this.dataHoraCadastro = dto?.dataHoraCadastro?.replace('Z', '');
		this.usuarioIDAlteracao = dto?.usuarioIDAlteracao;
		this.usuarioNomeAlteracao = dto?.usuarioNomeAlteracao;
		this.dataHoraAlteracao = dto?.dataHoraAlteracao?.replace('Z', '');
		this.empresaSetorID = dto?.empresaSetorID;
		this.tipologias = dto?.tipologias?.map((tipologia: any) => new Tipologia(tipologia)) || [];
	}
}