import React, { FC, useCallback, useEffect, useState } from 'react';
import {
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableRow,
	Box,
	Typography,
	TableHead,
	Icon,
	Button,
	styled,
	Divider,
	List,
	ListItem,
	ListSubheader,
	Stepper,
	Step,
	StepLabel,
	StepContent
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import { LayoutBaseDePagina } from 'shared/layouts';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDebounce } from 'shared/hooks';
import { Empresa, Setor, Tipologia } from 'models';
import { EmpresaService } from 'shared/services/Empresas/EmpresasService';
import { useEspera, useTema } from 'contexts';
import { from, of, switchMap, tap, throwError } from 'rxjs';

import { ESituacao, EUnidadeTempo } from 'enums';
import { ToastErro } from 'utils/toast';
import { LineGrid, FormGrid } from 'shared/components';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, { AccordionSummaryProps, accordionSummaryClasses } from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { BRANCO, ERRO, GRAFITE, INFO } from 'styles';

const Accordion = styled((props: AccordionProps) => (
	<MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
	border: `1px solid ${theme.palette.divider}`,
	borderRadius: 5,
	marginBottom: 2,
	padding: 0,
	'&:not(:last-child)': {
		// borderBottom: 0,
	},
	'&::before': {
		display: 'none',
	},
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
	<MuiAccordionSummary
		expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
		{...props}
	/>
))(({ theme }) => ({
	backgroundColor: INFO,
	borderTopLeftRadius: 5,
	borderTopRightRadius: 5,
	color: BRANCO,
	flexDirection: 'row-reverse',
	[`.${accordionSummaryClasses.expandIconWrapper}`]: {
		color: BRANCO
	},
	[`& .${accordionSummaryClasses.expandIconWrapper}.${accordionSummaryClasses.expanded}`]:
	{
		transform: 'rotate(90deg)'
	},
	[`& .${accordionSummaryClasses.content}`]: {
		marginLeft: theme.spacing(1),
	},
	...theme.applyStyles('dark', {
		backgroundColor: INFO,
	}),
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
	padding: theme.spacing(2),
}));

export const VisualizarEmpresa: React.FC = () => {
	const { state } = useLocation();
	const { empresaID } = state;

	const { BloquearTela, DesbloquearTela } = useEspera();
	const {
		cores: { INFO }
	} = useTema();

	const [isLoading, setIsLoading] = useState(true);
	const [empresa, setEmpresa] = useState<Empresa>();
	const { debounce } = useDebounce(300, false);
	const navigate = useNavigate();

	useEffect(() => {
		debounce(() => {
			setIsLoading(true);
			BloquearTela();
			from(EmpresaService.getById(empresaID))
				.pipe(
					tap(() => setIsLoading(false)),
					switchMap((resposta) => {
						setIsLoading(false);
						if (resposta instanceof Error)
							return throwError(() => resposta.message);
						return of(new Empresa(resposta));
					}),
					tap(() => DesbloquearTela()),
				).subscribe({
					next: (formulario) => setEmpresa(formulario),
					error: (erro) => ToastErro(erro)
				});
		});
	}, []);

	const DadosCadastro = useCallback(() => (
		<Step active>
			<StepLabel>
				<Typography variant='h4'>
					Dados Cadastro
				</Typography>
			</StepLabel>
			<StepContent>
				<Grid size={12} container spacing={3} component='form' sx={{ flexGrow: 1 }}>
					<FormGrid size={{ xs: 12, md: 6 }}>
						<Typography fontWeight={600}>Usuario Cadastro</Typography>
						<Typography>{empresa?.usuarioNomeCadastro}</Typography>
					</FormGrid>
					<FormGrid size={{ xs: 12, md: 6 }}>
						<Typography fontWeight={600}>Data e Hora Cadastro</Typography>
						<Typography>{empresa?.dataHoraCadastro
							? new Date(`${empresa.dataHoraCadastro}+0000`).formatar()
							: 'Data não disponível'}</Typography>
					</FormGrid>
				</Grid>
			</StepContent>
		</Step>
	), [empresa]);

	const DadosAlteracao = useCallback(() => {
		if (empresa?.usuarioIDAlteracao)
			return (
				<Step active>
					<StepLabel>
						<Typography variant='h4'>
							Dados Alteração
						</Typography>
					</StepLabel>
					<StepContent>
						<Grid size={12} container spacing={3} component='form' sx={{ flexGrow: 1 }}>
							<FormGrid size={{ xs: 12, md: 6 }}>
								<Typography fontWeight={600}>Usuario Alteracao</Typography>
								<Typography>{empresa?.usuarioNomeAlteracao}</Typography>
							</FormGrid>
							<FormGrid size={{ xs: 12, md: 6 }}>
								<Typography fontWeight={600}>Data e Hora Alteracao</Typography>
								<Typography>{empresa?.dataHoraCadastro
									? new Date(`${empresa.dataHoraAlteracao}+0000`).formatar()
									: 'Data não disponível'}</Typography>
							</FormGrid>
						</Grid>
					</StepContent>
				</Step>
			);
		return null;
	}, [empresa]);

	return (
		<LayoutBaseDePagina>
			<Box padding={1} display='flex' justifyContent='center' alignItems='center' sx={{ backgroundColor: '#D3D3D3' }}>
				<Grid
					container
					spacing={1}
					component={Paper}
					elevation={0}
					sx={{ flexGrow: 1 }}
					display='flex'
					flexDirection='column'
					justifyContent='center'
					alignItems='center'
					padding={2}
				>
					<Stepper orientation='vertical' sx={{ width: '100%' }}>
						<Step active>
							<StepLabel icon={<Icon sx={{ width: 0 }}></Icon>} optional={<Typography variant='caption'>Dados da Empresa</Typography>}>
								<Typography variant='h4'>
									Empresa
								</Typography>
							</StepLabel>
							<StepContent>
								<Grid size={12} container spacing={3} component='form' sx={{ flexGrow: 1 }}>
									<LineGrid>
										<FormGrid size={{ xs: 12, md: 4 }}>
											<Typography fontWeight={600}>CPF/CNPJ</Typography>
											<Typography>{empresa?.cpfcnpj?.formatarCPFOuCNPJ()}</Typography>
										</FormGrid>
									</LineGrid>
									<FormGrid size={{ xs: 12, md: 6 }}>
										<Typography fontWeight={600}>Razão Social</Typography>
										<Typography>{empresa?.razaoSocial}</Typography>
									</FormGrid>
									<FormGrid size={{ xs: 12, md: 6 }}>
										<Typography fontWeight={600}>Nome Fantasia</Typography>
										<Typography>{empresa?.nomeFantasia}</Typography>
									</FormGrid>

									<FormGrid size={{ xs: 12, md: 6 }}>
										<Typography fontWeight={600}>Responsável</Typography>
										<Typography>{empresa?.responsavel}</Typography>
									</FormGrid>
									<LineGrid>
										<FormGrid size={{ xs: 6, md: 2 }}>
											<Typography fontWeight={600}>CEP</Typography>
											<Typography>{empresa?.cep?.formatarCEP()}</Typography>
										</FormGrid>
									</LineGrid>
									<FormGrid size={{ xs: 12, md: 6 }}>
										<Typography fontWeight={600}>Logaradouro</Typography>
										<Typography>{empresa?.logradouro}</Typography>
									</FormGrid>
									<FormGrid size={{ xs: 6, md: 2 }}>
										<Typography fontWeight={600}>Número</Typography>
										<Typography>{empresa?.numero}</Typography>
									</FormGrid>
									<FormGrid size={{ xs: 6, md: 4 }}>
										<Typography fontWeight={600}>Bairro</Typography>
										<Typography>{empresa?.bairro}</Typography>
									</FormGrid>
									<FormGrid size={{ xs: 12, md: 6 }}>
										<Typography fontWeight={600}>Complemento</Typography>
										<Typography>{empresa?.complemento}</Typography>
									</FormGrid>
									<FormGrid size={{ xs: 6, md: 2 }}>
										<Typography fontWeight={600}>Estado</Typography>
										<Typography>{empresa?.municipio.ufNome}</Typography>
									</FormGrid>
									<FormGrid size={{ xs: 6, md: 3 }}>
										<Typography fontWeight={600}>Município</Typography>
										<Typography>{empresa?.municipio.nome}</Typography>
									</FormGrid>
									<FormGrid size={{ xs: 4, md: 3 }}>
										<Typography fontWeight={600}>Telefone Celular</Typography>
										<Typography>{empresa?.telefoneCelular?.formatarTelefone()}</Typography>
									</FormGrid>
									<FormGrid size={{ xs: 4, md: 3 }}>
										<Typography fontWeight={600}>Telefone Fixo</Typography>
										<Typography>{empresa?.telefoneFixo?.formatarTelefone()}</Typography>
									</FormGrid>
									<FormGrid size={{ xs: 4, md: 6 }}>
										<Typography fontWeight={600}>E-mail</Typography>
										<Typography>{empresa?.email}</Typography>
									</FormGrid>
									{
										empresa?.matriz &&
										<FormGrid size={{ xs: 12, md: 6 }}>
											<Typography fontWeight={600}>Matriz</Typography>
											<Typography>{`${empresa?.matriz?.cpfcnpj.formatarCPFOuCNPJ()} - ${empresa?.matriz?.nomeFantasia}`}</Typography>
										</FormGrid>
									}
									<FormGrid size={{ xs: 4, md: 6 }}>
										<Typography fontWeight={600}>Situação</Typography>
										<Typography>{ESituacao[empresa?.situacaoID || 0]}</Typography>
									</FormGrid>
								</Grid>
							</StepContent>
						</Step>
						<Step active>
							<StepLabel icon={<Icon sx={{ width: 0 }}></Icon>} optional={<Typography variant='caption'>Setores da Empresa</Typography>}>
								<Typography variant='h4'>
									Setor/Tipologia
								</Typography>
							</StepLabel>
							<StepContent>
								<Grid>
									{
										empresa?.setores
											.filter(setor => setor.situacaoID == ESituacao.ATIVO)
											.map((setor: Setor) =>
												<AccordionSetor
													key={setor.setorID}
													setor={setor} />)
									}
								</Grid>
							</StepContent>
						</Step>
						<DadosCadastro />
						<DadosAlteracao />
					</Stepper>
				</Grid>
			</Box>
			<Grid container sx={{ padding: '1rem', justifyContent: 'space-between', alignSelf: 'end' }}>
				<Button
					color='info'
					disableElevation
					variant='contained'
					onClick={() => navigate(-1)}
					startIcon={<Icon>arrow_back</Icon>} >
					<Typography variant='button' whiteSpace='nowrap' textOverflow='ellipsis' overflow='hidden'>
						Voltar
					</Typography>
				</Button>
			</Grid>
		</LayoutBaseDePagina>
	);
};

interface IAccordionSetor {
	setor: Setor
}

const AccordionSetor: FC<IAccordionSetor> = ({ setor }) => {
	const [tipologiasSelecionadas, setTipologiasSelecionadas] = useState<Tipologia[]>(setor.tipologias);
	const { setorID, nome } = setor;

	useEffect(() => {
		if (setor.tipologias != tipologiasSelecionadas)
			setor.tipologias = tipologiasSelecionadas;
	}, [tipologiasSelecionadas]);

	useEffect(() => {
		if (setor.tipologias != tipologiasSelecionadas)
			setTipologiasSelecionadas(setor.tipologias);
	}, [setor.tipologias]);

	return (
		<Accordion expanded>
			<AccordionSummary
				aria-controls={`painel${setorID}-content`}
				id={`painel${setorID}-header`}
			>
				<Grid size={3}>
					<Typography sx={{ flexShrink: 0 }}>
						{nome}
					</Typography>
				</Grid>
				<Grid size={7}>
					<Typography sx={{ opacity: .9 }}>
						[{tipologiasSelecionadas.filter(tipologia => tipologia.situacaoID == ESituacao.ATIVO).length}] Tipologia(s)
					</Typography>
				</Grid>
			</AccordionSummary>
			<AccordionDetails sx={{ padding: 0 }}>
				<List
					sx={{
						bgcolor: 'background.paper',
						overflow: 'hidden',
						width: '100%',
						padding: 0,
						borderTopLeftRadius: 0,
						borderTopRightRadius: 0,
						borderBottomLeftRadius: 0,
						borderBottomRightRadius: 0,
						'.MuiListItem-root.MuiListItem-dense': {
							padding: 0,
							paddingLeft: 3
						}
					}}
					dense>
					{
						tipologiasSelecionadas
							?.filter(tipologia => tipologia.situacaoID == ESituacao.ATIVO)
							.map(({ tipologiaID, unidadeTempoID, tempo, nome }) =>
								<ListItem
									key={tipologiaID}>
									<LineGrid
										display='flex'
										flexDirection='row'
										justifyContent='center'
										alignItems='center'
										bgcolor={GRAFITE}
										py={.3}
										sx={{
											'&:not(:last-child)': {
												borderBottomLeftRadius: 0,
												borderBottomRightRadius: 0,
											},
											'&:last-child': {
												borderBottomLeftRadius: 5,
												borderBottomRightRadius: 5,
												borderBottom: `1px solid ${BRANCO}`
											},
										}}
									>
										<Grid size={8} display='flex' alignItems='center' color={BRANCO}>
											<Typography paddingLeft={2}>
												{nome}
											</Typography>
										</Grid>
										<Grid size={2} color={BRANCO}>
											<Typography fontWeight={600}>Unidade Tempo</Typography>
											<Typography>{EUnidadeTempo[unidadeTempoID || 0]}</Typography>
										</Grid>
										<Grid size={2} color={BRANCO}>
											<Typography fontWeight={600}>Tempo</Typography>
											<Typography>{tempo}</Typography>
										</Grid>
									</LineGrid>
								</ListItem>)
					}
				</List>
			</AccordionDetails>
		</Accordion>
	);
};

AccordionSetor.displayName = 'AccordionSetor';
