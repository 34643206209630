import {
	Paper,
	Box,
	Grid2 as Grid,
	Typography
} from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { FerramentasDaListagem } from 'shared/components';
import { LayoutBaseDePagina } from 'shared/layouts';
import { ListaDocumentos } from 'shared/components/listagemDocumentos';
import { EPermissao } from 'enums';
import { PermissaoService } from 'shared/services/Permissoes/PermissoesService';
import { from, of, switchMap, tap, throwError } from 'rxjs';
import { EModulo } from 'enums';
import { useAuthContext, useEmpresaSelecionadaContext } from 'contexts';
import { ToastErro } from 'utils/toast';

export const ListagemDeDocumentos: React.FC = () => {
	const [permissoes, setPermissoes] = useState<EPermissao[]>([]);
	const [searchParams, setSearchParams] = useSearchParams();
	const navigate = useNavigate();
	const { state } = useLocation();
	const { perfilSelecionado } = useEmpresaSelecionadaContext();
	const { moduloID = EModulo.DOCUMENTOS } = state || {};

	useEffect(() => {
		if (!perfilSelecionado)
			return;
		from(PermissaoService.getPermissionsByModule(moduloID, perfilSelecionado.perfilID))
			.pipe(
				switchMap((resposta) => {
					if (resposta instanceof Error) return throwError(() => resposta.message);
					return of(resposta.map(permissaoId => permissaoId as EPermissao));
				}),
			)
			.subscribe({
				next: (permissoes) => setPermissoes(permissoes),
				error: (error) => ToastErro(error)
			});
	}, [perfilSelecionado]);

	const busca = useMemo(() => {
		return searchParams.get('busca') || '';
	}, [searchParams]);

	const pagina = useMemo(() => {
		return Number(searchParams.get('pagina') || '1');
	}, [searchParams]);

	return (
		<LayoutBaseDePagina>
			<Box padding={1} display='flex' justifyContent='center' alignItems='center' sx={{ backgroundColor: '#D3D3D3' }}>
				<Grid
					container
					spacing={1}
					component={Paper}
					elevation={0}
					sx={{ flexGrow: 1 }}
					display='flex'
					flexDirection='column'
					justifyContent='center'
					alignItems='center'
					padding={2}
				>
					<FerramentasDaListagem
						mostrarInputBusca={true}
						mostrarBotaoVoltar={false}
						mostrarBotaoNovo={false}
						mostrarBotaoNovoModal={permissoes.some(permissaoId => permissaoId == EPermissao.CADASTRAR)}
						textoBotaoNovo='Novo'
						textoDaBusca={searchParams.get('busca') ?? ''}
						aoClicarEmNovo={() => { /* handleOpen();*/ }}
						aoMudarTextoDeBusca={texto => { setSearchParams({ busca: texto, pagina: '1' }, { replace: true }); }}
					/>
					<ListaDocumentos
						busca={busca}
						pagina={pagina}
						botaoDownload={permissoes.some(permissaoId => permissaoId == EPermissao.DOWNLOAD)}
						botaoCancelar={permissoes.some(permissaoId => permissaoId == EPermissao.CANCELAR)}
						botaoVisualizar={permissoes.some(permissaoId => permissaoId == EPermissao.VISUALIZAR)}
						onChangePagination={(novaPagina: number) => setSearchParams({ busca: busca, pagina: novaPagina.toString() }, { replace: true })}
					/>
					<Box padding={2} display='flex' justifyContent='center' alignItems='center'>
						<Grid display='flex' justifyContent='center' alignItems='center' width={250} height={100} border={1} borderRadius={2} bgcolor='#e7e7e7' style={{ cursor: 'pointer' }}>
							<Box display='flex' justifyContent='center' alignItems='center' onClick={() => navigate('/listardocumentosprocessos')}>
								<Typography variant='h5' align='center' color='#085784' fontWeight='bold'>
									Meus processos
								</Typography>
							</Box>
						</Grid>
					</Box>
				</Grid>
			</Box>
		</LayoutBaseDePagina >
	);
};