import { ObjectToQuery } from 'utils';
import { Api } from '../Axios';
import moment, { Moment } from 'moment';

export interface IDetalheDocumento {
	tipologiaID: number;
	documentoEletronicoID: number;
	documentoNome: string;
	dados: string;
	dataHoraCadastro: Moment;
}

type TDocumentos = {
	data: [];
}

type TDocumentosSommenteTotalCount = {
	totalCount: number;
}
moment.locale('pt');

const getAll = async (pagina = 1, conteudo = '', tipologiaId = '', empresaId: number, dataInicio: Moment | null, dataFim: Moment | null): Promise<TDocumentos | Error> => {
	try {
		const inicio = dataInicio ? moment(dataInicio).toISOString() : null;
		const fim = dataFim ? moment(dataFim).toISOString() : null;
		const params = ObjectToQuery({ pagina, conteudo, tipologiaId, empresaId, dataInicio: inicio, dataFim: fim });
		const urlRelativa = `/DocumentoEletronico/ObterTodos?${params}`;
		const { data, headers } = await Api.get(urlRelativa);

		if (data) {
			return {
				data: data.data
			};
		}
		return new Error('Erro ao listar os registros.');
	} catch (error) {
		console.error(error); // pra depurar no console do navegador!
		return new Error((error as { message: string }).message || 'Erro ao listar os registros.');
	}
};

const getTotal = async (conteudo = '', tipologiaId = '', empresaId: number, dataInicio: Moment | null, dataFim: Moment | null): Promise<TDocumentosSommenteTotalCount | Error> => {
	try {
		const inicio = dataInicio ? moment(dataInicio).toISOString() : null;
		const fim = dataFim ? moment(dataFim).toISOString() : null;
		const params = ObjectToQuery({ conteudo, tipologiaId, empresaId, dataInicio: inicio, dataFim: fim });
		const urlRelativa = `/DocumentoEletronico/ObterQtdeTotal?${params}`;
		const { data } = await Api.get(urlRelativa);
		return {
			totalCount: Number(data.data),
		};
	} catch (error) {
		console.error(error); // pra depurar no console do navegador!
		return new Error((error as { message: string }).message || 'Erro ao listar os registros.');
	}
};

const getById = async (id: number): Promise<IDetalheDocumento | Error> => {
	try {
		const LOCAL_STORAGE_KEY__ACCESS_TOKEN = process.env.REACT_APP_LOCAL_STORAGE_TOKEN;
		const accessToken = localStorage.getItem(LOCAL_STORAGE_KEY__ACCESS_TOKEN!) || '';

		const { data } = await Api.get(`/DocumentoEletronico/ObterPorId?id_documento_eletronico=${id}`);

		if (data) {
			return data.data;
		}
		return new Error('Erro ao obter o registro.');
	} catch (error) {
		console.error(error); // pra depurar no console do navegador!
		return new Error((error as { message: string }).message || 'Erro ao obter o registro.');
	}
};

const download = async (documentoEletronicoID: number): Promise<any | Error> => {
	try {
		const urlRelativa = `/DocumentoEletronico/DownloadDocumento`;
		const { data } = await Api.post(urlRelativa, { documentoEletronicoID });

		if (data) {
			if (data.data)
				return { data: data.data };

			return new Error(data.errors.join('<br/>'));
		} else
			return new Error('Erro ao gerar o downlaod do documento.');
	} catch (error) {
		console.error(error); // pra depurar no console do navegador!
		return new Error((error as { message: string }).message || 'Erro ao autenticar!!');
	}
};

const deletar = async (documentoEletronicoID: number): Promise<any | Error> => {
	try {
		const urlRelativa = `/DocumentoEletronico/DeletarDocumento`;
		const { data } = await Api.post(urlRelativa, { documentoEletronicoID });

		if (data) {
			if (data.data)
				return { data: data.data };

			return new Error(data.errors.join('<br/>'));
		} else
			return new Error('Erro ao excluir o documento.');
	} catch (error) {
		console.error(error); // pra depurar no console do navegador!
		return new Error((error as { message: string }).message || 'Erro ao autenticar!!');
	}
};

const cancelar = async (documentoEletronicoID: number): Promise<any | Error> => {
	try {
		const urlRelativa = `/DocumentoEletronico/CancelarDocumento`;
		const { data } = await Api.post(urlRelativa, { documentoEletronicoID });

		if (data) {
			if (data.data)
				return { data: data.data };

			return new Error(data.errors.join('<br/>'));
		} else
			return new Error('Erro ao cancelar o documento.');
	} catch (error) {
		console.error(error); // pra depurar no console do navegador!
		return new Error((error as { message: string }).message || 'Erro ao autenticar!!');
	}
};


export const Documentoservice = {
	getTotal,
	getAll,
	getById,
	download,
	deletar,
	cancelar
};
