import { Box, Button, Card, CardContent, CircularProgress, Grid, ListItem, Paper, Stack, Typography } from '@mui/material';
import { useEffect, useState, useMemo } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { TreeView, TreeItem } from '@mui/x-tree-view';
import { LayoutBaseDePagina } from 'shared/layouts';
import { IListagemTipologia, TipologiaService } from 'shared/services/Tipologias/TipologiasService';
import { IListagemEmpresa, EmpresaService } from 'shared/services/Empresas/EmpresasService';
import { IAccess, AuthService } from 'shared/services/Auth/AuthService';
import { useDebounce, useDebounce1, useDebounce2, useDebounce3, useDebounceAccess, useDebounceChecaLogin } from 'shared/hooks/UseDebounce';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import TipsAndUpdatesOutlinedIcon from '@mui/icons-material/TipsAndUpdatesOutlined';
import DoDisturbOffIcon from '@mui/icons-material/DoDisturbOff';

import App from './App.js';
import { from, of, switchMap, tap, throwError } from 'rxjs';
import { Empresa } from 'models';
import { ToastErro } from 'utils/toast';

export const DetalheDeDocumentos: React.FC = () => {
	const [searchParams, setSearchParams] = useSearchParams();
	const navigate = useNavigate();
	const [totalAccessCount, setTotalAccessCount] = useState(0);
	const [isLoadingEmpresas, setIsLoadingEmpresas] = useState(true);
	const [isLoadingTipologias, setIsLoadingTipologias] = useState(true);
	const [isLoadingSetores, setIsLoadingSetores] = useState(true);
	const [isLoadingFolders, setIsLoadingFolders] = useState(true);
	const [isLoadingAccess, setIsLoadingAccess] = useState(true);
	const [rowsEmpresas, setRowsEmpresas] = useState<Empresa[]>([]);
	const [rowsTipologia, setRowsTipologia] = useState<IListagemTipologia[]>([]);
	const [rowsSetor, setRowsSetor] = useState<any[]>([]);
	const [rowsFolders, setRowsFolders] = useState<any[]>([]);
	const [rowsAccess, setRowsAccess] = useState<IAccess[]>([]);
	const { debounce } = useDebounce(50, false);
	const { debounce1 } = useDebounce1(50, false);
	const { debounce2 } = useDebounce2(50, false);
	const { debounce3 } = useDebounce3(50, false);
	const { debounceAccess } = useDebounceAccess(50, false);
	const { debounceChecaLogin } = useDebounceChecaLogin(50, false);

	const [tmp_busca_id_tipologia, setTmp_busca_id_tipologia] = useState('');
	const [tmp_busca_id_setor, setTmp_busca_id_setor] = useState('');

	const tipologiaId = useMemo(() => {
		return searchParams.get('tipologiaId') || '';
	}, [searchParams]);
	const busca_id_setor = useMemo(() => {
		return searchParams.get('busca_id_setor') || '';
	}, [searchParams]);

	useEffect(() => {
		/* ************************************************************************** */
		debounceChecaLogin(() => {
			AuthService.checklogin()
				.then((result) => {
					if (result instanceof Error) {
						alert(result.message);
						return;
					} else {
						let checalogin = '';
						(result.data.map(x => checalogin = x.loginstatus));
						if (checalogin == '') {
							const LOCAL_STORAGE_KEY__ACCESS_TOKEN = process.env.REACT_APP_LOCAL_STORAGE_TOKEN;
							localStorage.removeItem(LOCAL_STORAGE_KEY__ACCESS_TOKEN!);
							navigate(-1);
							// alert('Token excluido!');
							// setAccessToken(undefined);
						}
					}
				});
		});
		if (isLoadingAccess) {
			/* ************************************************************************** */
			// Verificando acesso!
			debounceAccess(() => {
				AuthService.getAccess('DetalheDeDocumentos')
					.then((result) => {
						if (result instanceof Error) {
							alert(result.message);
							return;
						} else {
							setIsLoadingAccess(false);
							if (result.totalCount >= 1) {
								let acesso = 0;
								(result.data.map(x => (acesso = (x.nome_aplicacao == 'DetalheDeDocumentos' ? 1 : acesso))));
								setTotalAccessCount(acesso);
								setRowsAccess(result.data); // Contém o map dos acessos desta aplicação!
							}
						}
					});
			});
			/* ************************************************************************** */
		}
		if (tipologiaId == '' && busca_id_setor == '') {
			debounce(() => {
				if (isLoadingEmpresas) {
					from(EmpresaService.getAll())
						.pipe(
							tap(() => setIsLoadingEmpresas(false)),
							switchMap((retorno: Empresa[] | Error) => {
								if (retorno instanceof Error)
									return throwError(() => retorno);
								return of(retorno.map((empresa: any) => new Empresa(empresa)));
							})
						)
						.subscribe({
							next: (empresas: Empresa[]) => setRowsEmpresas(empresas),
							error: (error) => ToastErro(error)
						});
				}
			});
			debounce1(() => {
				setIsLoadingSetores(true);
				// Usuario_Tipologia_SetorService.getAll()
				// 	.then((result) => {
				// 		if (result instanceof Error) {
				// 			alert(result.message);
				// 			return;
				// 		} else {
				// 			setIsLoadingSetores(false);
				// 			setRowsSetor(result.data);
				// 		}
				// 	});
			});
			debounce2(() => {
				setIsLoadingTipologias(true);
				TipologiaService.getAll()
					.then((result) => {
						if (result instanceof Error) {
							alert(result.message);
							return;
						} else {
							setIsLoadingTipologias(false);
							setRowsTipologia(result.data);
						}
					});
			});
		}
		debounce3(() => {
			setIsLoadingFolders(true);
			// Usuario_Tipologia_SetorService.getAll(1, tipologiaId, busca_id_setor)
			// 	.then((result) => {
			// 		setIsLoadingFolders(false);
			// 		if (result instanceof Error) {
			// 			alert(result.message);
			// 			return;
			// 		} else {
			// 			setRowsFolders(result.data);
			// 		}
			// 	});
		});
	}, [tipologiaId, busca_id_setor]);

	const atualizafolders = (par_busca_id_setor: string, par_busca_id_tipologia: string) => {
		if (tmp_busca_id_setor != par_busca_id_setor || tmp_busca_id_tipologia != par_busca_id_tipologia) {
			setSearchParams({ busca_id_setor: par_busca_id_setor, tipologiaId: par_busca_id_tipologia }, { replace: true });
		}
		setTmp_busca_id_setor(par_busca_id_setor);
		setTmp_busca_id_tipologia(par_busca_id_tipologia);
	};

	let cont = 1;
	return (
		<LayoutBaseDePagina>
			{isLoadingAccess && (
				<CircularProgress />
			)}
			{!isLoadingAccess && totalAccessCount == 0 && (
				<div style={{
					background: '#ccccc',
					width: '300px',
					height: '80px',
					textAlign: 'center',
					margin: 'auto',
					padding: '200px',
				}}>
					<div className='box' style={{
						border: '1px solid',
						borderColor: '#085784a8',
						padding: '10px',
						borderRadius: '5px',
						background: '#e7e7e7'
					}}>
						<DoDisturbOffIcon sx={{ color: '#a70b00' }} />
						<Typography>Seu usuário não possui acesso à inclusão de documentos!</Typography>
						<Button variant='outlined' href='/'> OK</Button>
					</div>
				</div>
			)}
			{!isLoadingAccess && totalAccessCount >= 1 && (
				<Stack direction='row'>
					<Box margin={1} display='flex' flexDirection='column' component={Paper} variant='outlined' sx={{ m: 1, width: '20%' }}>
						<TreeView
							aria-label='file system navigator'
							defaultCollapseIcon={<ExpandMoreIcon />}
							defaultExpanded={['1']}
							defaultExpandIcon={<ChevronRightIcon />}
							sx={{ height: 500, flexGrow: 1, maxWidth: '90%' }}>
							<TreeItem
								key={cont}
								nodeId={cont.toString()}
								label='Tipologias'
								onClick={() => atualizafolders('', '')}>
								{isLoadingTipologias && (
									<CircularProgress />
								)}
								{!isLoadingTipologias && rowsTipologia.map(rowTip => {
									const n1_tipologia = rowTip.tipologiaID;
									cont++;
									return (
										<TreeItem
											key={cont}
											nodeId={cont.toString()}
											label={rowTip.nome}
											onClick={() => atualizafolders('', rowTip.tipologiaID.toString())}>
											{isLoadingSetores && (
												<CircularProgress />
											)}
											{!isLoadingSetores && rowsSetor.map(rowSet => {
												const n2_tipologia = rowSet.tipologiaId;
												if (n1_tipologia.toString() == n2_tipologia) {
													cont++;
													return (
														<TreeItem
															key={cont}
															nodeId={cont.toString()}
															label={rowSet.nome_setor}
															onClick={() => atualizafolders(rowSet.id_setor, rowSet.tipologiaId.toString())} />
													);
												}
											})}
										</TreeItem>
									);
								})}
							</TreeItem>
						</TreeView>
					</Box>
					<Box margin={1} display='flex' flexDirection='column' component={Paper} variant='outlined' sx={{ m: 1, width: '80%' }}>
						<Box margin={1} display='flex' flexDirection='column' component={Paper} variant='outlined' >
							<Grid container direction='column' padding={2} spacing={2} >
								<Grid item >
									{isLoadingEmpresas && (
										<Grid item >
											<CircularProgress />
										</Grid>
									)}
									{!isLoadingEmpresas && rowsEmpresas.map(rowEmpresa => {
										cont++;
										return (
											<Typography key={cont} variant='h5' align='center' color='#085784' fontWeight='bold'>
												Empresa: {rowEmpresa.nomeFantasia}
											</Typography>
										);
									})}
									<Grid container margin={2}>
										<Grid item container spacing={2}>
											<Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
												<Card>
													<Grid border={1} borderRadius={2} bgcolor='#e7e7e7'>
														<CardContent>
															<Typography variant='h5' align='center' color='#085784' fontWeight='bold'
																onClick={() => navigate(-1)}>
																Pesquisar
															</Typography>
														</CardContent>
													</Grid>
												</Card>
											</Grid>
											&nbsp;&nbsp;&nbsp;
											<Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
												<Card>
													<Grid border={1} borderRadius={2} bgcolor='#e7e7e7'>
														<CardContent>
															<Typography variant='h5' align='center' color='#085784' fontWeight='bold'
																onClick={() => navigate('/bi')}>
																B.I.
															</Typography>
														</CardContent>
													</Grid>
												</Card>
											</Grid>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
							<Grid container direction='column' padding={2} spacing={2} >
								<Grid item >
									<Grid container margin={2}>
										<Grid item container spacing={2}>
											<TipsAndUpdatesOutlinedIcon sx={{ color: '#246a92' }} />
											&nbsp; &nbsp;
											<Typography variant='h6' align='center' color='#085784' >
												Arraste e solte ou clique no nome da Tipologia/Setor para carregar documentos...
											</Typography>

										</Grid>
									</Grid>
								</Grid>
							</Grid>
							<Grid container margin={2}>
								<Grid item container spacing={2}>
									{isLoadingFolders && (
										<Grid item >
											<CircularProgress />
										</Grid>
									)}
									{!isLoadingFolders && rowsFolders.map(row => {
										cont++;
										return (
											<Grid key={cont} item xs={12} sm={12} md={6} lg={4} xl={3}>
												<ListItem alignItems='center'>
													<div style={{
														width: '250px',
														height: '80px',
														padding: '20px'
													}}>
														<App
															nome_setor={row.nome_setor}
															tipologia_nome={row.tipologia_nome}
															id_tipologia_setor_empresa={row.id_tipologia_setor_empresa}
														/>
													</div>
													<br></br>
												</ListItem>
											</Grid>
										);
									})}
								</Grid>
							</Grid>
							{ /* */}
						</Box>
					</Box >
				</Stack >
			)}
		</LayoutBaseDePagina >
	);
};