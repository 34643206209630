import * as cores from 'styles/colors';
import * as fontes from 'styles/typography';
import JSEncrypt from 'jsencrypt';

const {
	PALETA_VERMELHO,
	PALETA_ROSA,
	PALETA_ROXO,
	PALETA_AZUL_MARINHO,
	PALETA_AZUL,
	PALETA_CIANO,
	PALETA_VERDE_MAR,
	PALETA_VERDE,
	PALETA_VERDE_IAGRO,
	PALETA_AMARELO,
	PALETA_LARANHA,
	PALETA_LARANGJA_AVERMELHADO,
	PALETA_CINZA_QUENTE,
	PALETA_CINZA,
	PALETA_CINZA_FRIO,
	PALETA_BRANCO,
	PALETA_GRAFITE,
} = cores;

const {
	FONTE_HIPER,
	FONTE_POWER,
	FONTE_ULTRA,
	FONTE_MEGA,
	FONTE_SUPER,
	FONTE_GRANDE,
	FONTE_MEDIA,
	FONTE_PEQUENA,
	FONTE_MINI,
	FONTE_MICRO,
	FONTE_NANO,
} = fontes;


declare global {
	interface Array<T> {
		first: <T>(filter?: (item: T) => boolean) => T;
		last: <T>(filter?: (item: T) => boolean) => T;
		any: (filter?: (item: T) => boolean) => boolean;
		count: (filter?: (item: T) => boolean) => number;
		distinct: <T>(filter?: string | ((item: T) => boolean)) => Array<T>;
		orderByDesc: <T>(filter?: string | ((a: T, b: T) => number)) => Array<T>;
		orderBy: <T>(filter?: string | ((a: T, b: T) => number)) => Array<T>;
	}
	interface ArrayConstructor {
		ehVazia: (valor: string | any) => boolean;
	}

	interface Date {
		formatar: (formato?: string) => string;
		toISO: () => string;
		toISOUTC: () => string;
		toUTC: () => Date;
		dateTimeSerializer: () => string;
		addHours: (qtdeHoras: number) => Date;
	}

	interface String {
		parseDateTime: () => Date | null;
		ehNuloOuVazio: () => boolean;
		formatarCPF: () => string;
		formatarCPFOuCNPJ: () => string;
		formatarTelefone: () => string;
		formatarCEP: () => string;
		opacidade: (porcentagem: number) => string;
		paleta: (indice: number) => string;
		toRGBA: (alpha: number) => string;
		toRGB: (alpha: number) => IRGBA | null;
		toAnimated: (alpha: number) => Array<number>;
		occurrences: (subString: string, allowOverlapping: boolean) => number;
		toCapitalizeCase: () => string;
		descripitografar: () => string;
		criptografar: () => string;
	}

	interface StringConstructor {
		ehNuloVazioOuZero: (texto: any) => boolean;
		ehNuloOuVazio: (texto: any) => boolean;
	}

	interface Number {
		escala: (escala: number) => number | undefined;
	}

	interface NumberConstructor {
		ehNuloVazioOuZero: (numero: any) => boolean;
	}
}

Array.prototype.first = function <T>(filter?: (item: T) => boolean): T {
	if (!filter) filter = item => true;
	return this.filter(filter).at(0);
};

Array.prototype.last = function <T>(filter?: (item: T) => boolean): T {
	if (!filter) filter = item => true;
	return this.filter(filter).at(-1);
};

Array.prototype.any = function <T>(filter?: (item: T) => boolean): boolean {
	if (!filter) filter = item => true;
	return !!this.filter(filter).length;
};

Array.prototype.count = function <T>(filter?: (item: T) => boolean): number {
	if (!filter) filter = item => true;
	return this.filter(filter).length;
};
Array.prototype.distinct = function <T>(filter?: string | ((item: T) => boolean)): Array<T> {
	if (!filter)
		return [...new Set(this.map(item => JSON.stringify(item)))].map(item => JSON.parse(item));

	if (typeof filter === 'string') {
		const seen = new Set();
		return this.filter(item => {
			const key = item[filter];
			if (seen.has(key)) {
				return false;
			}
			seen.add(key);
			return true;
		});
	}

	if (typeof filter === 'function')
		return this.filter(filter);

	return this;
};

// Array.prototype.distinct = function <T>(filter?: string | ((item: T) => boolean)): Array<T> {
// 	if (!filter)
// 		return this
// 			.map(item => JSON.stringify(item))
// 			.filter((item, posicao, array) => array.indexOf(item) === posicao)
// 			.map(item => JSON.parse(item));
// 	if (typeof filter == 'string')
// 		return this.filter((item, _, itens) => !itens.some(_item => _item[filter] === item[filter] && _item != item));
// 	if (typeof filter == 'function')
// 		return this.filter(filter);
// 	return this;
// };

Array.prototype.orderByDesc = function <T>(filter?: string | ((a: T, b: T) => number)): Array<T> {
	return _orderBy(this, filter, true);
};

Array.prototype.orderBy = function <T>(filter?: string | ((a: T, b: T) => number)): Array<T> {
	return _orderBy(this, filter);
};

const _orderBy = <T>(lista: Array<T>, filter?: string | ((a: T, b: T) => number), desc: boolean = false): Array<T> => {
	if (!filter)
		lista.sort();
	else if (typeof filter === 'string')
		lista.sort((a: T, b: T) => {
			const ordemA = (a as { [key: string]: any })[filter];
			const ordemB = (b as { [key: string]: any })[filter];
			if (ordemA > ordemB) return 1;
			if (ordemA < ordemB) return -1;
			return 0;
		});
	else if (typeof filter === 'function')
		lista.sort(filter);

	if (desc)
		return lista.reverse();
	return lista;
};

Array.ehVazia = function (valor: string | any): boolean {
	if (!valor) return false;

	const array = typeof valor === 'string' ? Array.from(valor) : valor;
	return !array.any();
};

const nomeMeses = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'];
const diasDaSemana = ['Domingo', 'Segunda-feira', 'Terça-feira', 'Quarta-feira', 'Quinta-feira', 'Sexta-feira', 'Sábado', 'Domingo'];

Date.prototype.formatar = function (formato: string = 'dd/MM/yyyy HH:mm:ss'): string {
	if (!this)
		return '';

	const dia = this.getUTCDate().toString().padStart(2, '0');
	const mes = (this.getUTCMonth() + 1).toString().padStart(2, '0');
	const anoFull = this.getUTCFullYear();
	const anoMin = anoFull.toString().substring(2);
	const nomeMes = nomeMeses[+mes - 1];
	const dayOfWeekName = diasDaSemana[this.getUTCDay()];
	const hora = this.getUTCHours().toString().padStart(2, '0');
	const minuto = this.getUTCMinutes().toString().padStart(2, '0');
	const segundos = this.getUTCSeconds().toString().padStart(2, '0');

	return formato
		.replace('yyyy', anoFull.toString()).replace('yy', anoMin)
		.replace('MMMM', nomeMes).replace('MM', mes)
		.replace('dd', dia).replace('EEEE', dayOfWeekName)
		.replace('HH', hora).replace('mm', minuto)
		.replace('ss', segundos);
};

Date.prototype.toISO = function (): string {
	return this.toISOString();
};

Date.prototype.toISOUTC = function (): string {
	return this.toUTC().toISOString();
};

Date.prototype.toUTC = function (): Date {
	const UTCZone = this.getTimezoneOffset() / 60;
	this.setHours(this.getHours() - UTCZone);
	return this;
};

Date.prototype.dateTimeSerializer = function (): string {
	const dia = this.getUTCDate().toString().padStart(2, '0');
	const mes = (this.getUTCMonth() + 1).toString().padStart(2, '0');
	const anoFull = this.getUTCFullYear();
	const hora = this.getUTCHours().toString().padStart(2, '0');
	const minuto = this.getUTCMinutes().toString().padStart(2, '0');
	const segundo = this.getUTCSeconds().toString().padStart(2, '0');
	return `${anoFull}/${mes}/${dia} ${hora}:${minuto}:${segundo}`;
};

Date.prototype.addHours = function (qtdeHoras: number): Date {
	this.setTime(this.getTime() + (qtdeHoras * 60 * 60 * 1000));
	return this;
};


const regexDataIso = /^([+-]?\d{4}(?!\d{2}\b))((-?)((0[1-9]|1[0-2])(\3([12]\d|0[1-9]|3[01]))?|W([0-4]\d|5[0-2])(-?[1-7])?|(00[1-9]|0[1-9]\d|[12]\d{2}|3([0-5]\d|6[1-6])))([T\s]((([01]\d|2[0-3])((:?)[0-5]\d)?|24:?00)([/.,]\d+(?!:))?)?(\17[0-5]\d([/.,]\d+)?)?([zZ]|([+-])([01]\d|2[0-3]):?([0-5]\d)?)?)?)?$/;
const _paraFormatoBr = (dataTexto: string): string => {
	const ehDataIso = regexDataIso.test(dataTexto);
	if (ehDataIso) {
		const aData = dataTexto.replace('Z', '').split('T');
		return aData.reduce((final, dataOuTempo) => {
			const regexData = /(\d{4})-(\d{2})-(\d{2})/;
			const regexTempo = /(\d{2}):(\d{2}):(\d{2})+(\d{2}):(\d{2})/;
			if (regexData.test(dataOuTempo))
				return `${final}${dataOuTempo.replace(regexData, '$3/$2/$1')}`;
			return `${final} ${dataOuTempo.replace(regexTempo, '$1:$2:$3')}`;
		}, '');
	} else
		return dataTexto.toString();
};

String.prototype.parseDateTime = function (): Date | null {
	const textoData: string = _paraFormatoBr(this.toString());
	if (textoData == undefined) return null;
	const dateTime: Array<string> = textoData.split(' ');
	const date: Array<string> = dateTime[0].replace(/[-]/g, '/').split('/');
	if (dateTime[1] == undefined) dateTime[1] = '00:00';
	const time = dateTime[1].split(':');
	if (date[2].length == 2) date[2] = `20${date[2]}`;
	const novaData = new Date(Date.UTC(
		parseInt(date[2], 10),
		parseInt(date[1], 10) - 1,
		parseInt(date[0], 10),
		parseInt(time[0], 10),
		parseInt(time[1], 10),
		time[2] == undefined ? 0 : parseInt(time[2], 10),
		0)
	);

	return novaData;
};

String.prototype.ehNuloOuVazio = function (): boolean {
	return this === undefined ||
		this === null ||
		this === '' ||
		this.replace(/\s/g, '').length < 1 ||
		this === '0';
};

String.prototype.formatarCPF = function (): string {
	const cpf = this.replace(/[^\d]/g, '');
	return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
};

String.prototype.formatarCPFOuCNPJ = function (): string {
	const cpfcnpj = this.replace(/[^\d]/g, '');
	if (cpfcnpj.length == 11)
		return cpfcnpj.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
	else if (cpfcnpj.length == 14)
		return cpfcnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{1,2})/g, '$1.$2.$3/$4-$5');
	return cpfcnpj;
};

String.prototype.formatarTelefone = function (): string {
	const telefone = this.replace(/[^\d]/g, '');
	if (telefone.length == 11)
		return telefone.replace(/(\d{2})(\d{2,5})(\d{1,4})/g, '($1) $2-$3');
	else if (telefone.length == 10)
		return telefone.replace(/(\d{2})(\d{2,4})(\d{1,4})/g, '($1) $2-$3');
	return telefone;
};

String.prototype.formatarCEP = function (): string {
	const cep = this.replace(/[^\d]/g, '');
	if (cep.length == 8)
		return cep.replace(/(\d{5})(\d{1,3})/g, '$1-$2');
	return cep;
};

String.prototype.opacidade = function (porcentagem: number): string {
	const aHexOpacity = [
		'00', '03', '05', '08', '0A', '0D', '0F', '12', '14', '17', '1A',
		'1C', '1F', '21', '24', '26', '29', '2B', '2E', '30', '33',
		'36', '38', '3B', '3D', '40', '42', '45', '47', '4A', '4D',
		'4F', '52', '54', '57', '59', '5C', '5E', '61', '63', '66',
		'69', '6B', '6E', '70', '73', '75', '78', '7A', '7D', '80',
		'82', '85', '87', '8A', '8C', '8F', '91', '94', '96', '99',
		'9C', '9E', 'A1', 'A3', 'A6', 'A8', 'AB', 'AD', 'B0', 'B3',
		'B5', 'B8', 'BA', 'BD', 'BF', 'C2', 'C4', 'C7', 'C9', 'CC',
		'CF', 'D1', 'D4', 'D6', 'D9', 'DB', 'DE', 'E0', 'E3', 'E6',
		'E8', 'EB', 'ED', 'F0', 'F2', 'F5', 'F7', 'FA', 'FC', 'FF',
	];
	return `${this}${aHexOpacity[porcentagem]}`;
};

String.prototype.paleta = function (indice: number): string {

	const PALETAS = [
		{ nome: 'VERMELHO', cores: PALETA_VERMELHO },
		{ nome: 'ROSA', cores: PALETA_ROSA },
		{ nome: 'ROXO', cores: PALETA_ROXO },
		{ nome: 'AZUL MARINHO', cores: PALETA_AZUL_MARINHO },
		{ nome: 'AZUL', cores: PALETA_AZUL },
		{ nome: 'CIANO', cores: PALETA_CIANO },
		{ nome: 'VERDE MAR', cores: PALETA_VERDE_MAR },
		{ nome: 'VERDE', cores: PALETA_VERDE },
		{ nome: 'PALETA VERDE IAGRO', cores: PALETA_VERDE_IAGRO },
		{ nome: 'AMARELO', cores: PALETA_AMARELO },
		{ nome: 'LARANJA', cores: PALETA_LARANHA },
		{ nome: 'LARANJA AVERMELHADO', cores: PALETA_LARANGJA_AVERMELHADO },
		{ nome: 'CINZA QUENTE', cores: PALETA_CINZA_QUENTE },
		{ nome: 'CINZA', cores: PALETA_CINZA },
		{ nome: 'CINZA FRIO', cores: PALETA_CINZA_FRIO },
		{ nome: 'BRANCO', cores: PALETA_BRANCO },
		{ nome: 'GRAFITE', cores: PALETA_GRAFITE },
	];
	const cor: string = this.toString();
	const paleta = PALETAS.find(p => p.cores.some(item => item === cor));
	if (!paleta)
		return cor;

	const maxCores: number = paleta.cores.length - 1;
	if (indice < 1 || indice > maxCores) {
		console.error(`[Indice inválido] Valor esperado vai de 1 a ${maxCores}.`);
		return cor;
	}

	return paleta.cores[indice];
};

export const paleta = function (cor: string, indice: number): string {

	const PALETAS = [
		{ nome: 'VERMELHO', cores: PALETA_VERMELHO },
		{ nome: 'ROSA', cores: PALETA_ROSA },
		{ nome: 'ROXO', cores: PALETA_ROXO },
		{ nome: 'AZUL MARINHO', cores: PALETA_AZUL_MARINHO },
		{ nome: 'AZUL', cores: PALETA_AZUL },
		{ nome: 'CIANO', cores: PALETA_CIANO },
		{ nome: 'VERDE MAR', cores: PALETA_VERDE_MAR },
		{ nome: 'VERDE', cores: PALETA_VERDE },
		{ nome: 'PALETA VERDE IAGRO', cores: PALETA_VERDE_IAGRO },
		{ nome: 'AMARELO', cores: PALETA_AMARELO },
		{ nome: 'LARANJA', cores: PALETA_LARANHA },
		{ nome: 'LARANJA AVERMELHADO', cores: PALETA_LARANGJA_AVERMELHADO },
		{ nome: 'CINZA QUENTE', cores: PALETA_CINZA_QUENTE },
		{ nome: 'CINZA', cores: PALETA_CINZA },
		{ nome: 'CINZA FRIO', cores: PALETA_CINZA_FRIO },
		{ nome: 'BRANCO', cores: PALETA_BRANCO },
		{ nome: 'GRAFITE', cores: PALETA_GRAFITE },
	];
	const paleta = PALETAS.find(p => p.cores.some(item => item === cor));
	if (!paleta)
		return cor;

	const maxCores: number = paleta.cores.length - 1;
	if (indice < 1 || indice > maxCores) {
		console.error(`[Indice inválido] Valor esperado vai de 1 a ${maxCores}.`);
		return cor;
	}

	return paleta.cores[indice];
};

String.prototype.toRGBA = function (alpha: number = 1): string {
	const hex: string = this.toString();
	let c: any;
	if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
		c = hex.substring(1).split('');
		if (c.length == 3) {
			c = [c[0], c[0], c[1], c[1], c[2], c[2]];
		}
		c = '0x' + c.join('');
		if (alpha != 1)
			return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ',' + alpha + ')';
		return 'rgb(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ')';
	}
	throw new Error('Bad Hex');
};

interface IRGBA {
	r: number;
	g: number;
	b: number;
	a: number;
}

String.prototype.toRGB = function (alpha: number = 1): IRGBA | null {
	let hex: string = this.toString();
	const shorthandRegex: RegExp = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
	hex = hex.replace(shorthandRegex, (m, r, g, b) => r + r + g + g + b + b);

	const resultado = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
	if (resultado)
		return {
			r: parseInt(resultado[1], 16),
			g: parseInt(resultado[2], 16),
			b: parseInt(resultado[3], 16),
			a: alpha
		};
	return null;
};

String.prototype.toAnimated = function (alpha: number = 1): Array<number> {
	const rgba: IRGBA | null = this.toRGB(alpha);
	if (!rgba)
		return [];
	const { r, g, b, a }: IRGBA = rgba;
	return [r, g, b, a];
};

String.prototype.occurrences = function (subString: string, allowOverlapping: boolean): number {
	const string = this + '';
	subString += '';
	if (subString.length <= 0) return (string.length + 1);

	let n = 0,
		pos = 0;
	const step = allowOverlapping ? 1 : subString.length;

	while (pos >= 0) {
		pos = string.indexOf(subString, pos);
		if (pos >= 0) {
			++n;
			pos += step;
		} else break;
	}
	return n;
};

String.prototype.toCapitalizeCase = function (): string {
	return `${this.charAt(0).toUpperCase()}${this.slice(1).toLowerCase()}`;
};

String.ehNuloOuVazio = function (texto: any): boolean {
	return texto === undefined ||
		texto === null ||
		texto === '';
};

String.ehNuloVazioOuZero = function (texto: any): boolean {
	return texto === undefined ||
		texto === null ||
		texto === '' ||
		texto.replace(/\s/g, '').length < 1 ||
		texto === '0';
};


String.prototype.descripitografar = function (): string {
	try {
		const text: string = this + '';
		const dencrypt = new JSEncrypt({ log: true, default_key_size: '4096' });
		dencrypt.setPrivateKey(process.env.REACT_APP_CHAVE_RSA_PRIVADA!);
		const decrypted = dencrypt.decrypt(text) as string;
		return decrypted;
	} catch (error) {
		return '';
	}
};

String.prototype.criptografar = function (): string {
	try {
		const text: string = this + '';
		const encrypt = new JSEncrypt({ log: true, default_key_size: '4096' });
		encrypt.setPublicKey(process.env.REACT_APP_CHAVE_RSA_PUBLICA!);
		const encrypted = encrypt.encrypt(text) as string;
		return encrypted;
	} catch (error) {
		return '';
	}
};

Number.ehNuloVazioOuZero = function (numero: any): boolean {
	return numero === undefined ||
		numero === null ||
		numero === '' ||
		numero === 0;
};

Number.prototype.escala = function (escala: number = 0): number | undefined {

	if (typeof escala !== 'number') {
		console.error('A escala da fonte deve ser um número do tipo inteiro.');
		return;
	}

	if (!Number.isInteger(escala)) {
		console.error('A escala da fonte deve ser um número do tipo inteiro.');
		return;
	}

	const fonteAtual: number = +this;
	const fontes = [FONTE_NANO, FONTE_MICRO, FONTE_MINI, FONTE_PEQUENA, FONTE_MEDIA,
		FONTE_GRANDE, FONTE_SUPER, FONTE_MEGA, FONTE_ULTRA, FONTE_POWER];
	const escalaMaxima = fontes.length - 1;

	const indice: number = fontes.indexOf(fonteAtual);
	const indiceNovaFonte: number = indice + escala;
	if (indiceNovaFonte > escalaMaxima)
		return fontes[escalaMaxima];
	if (indiceNovaFonte < 0)
		return fontes[0];
	return fontes[indiceNovaFonte];

};