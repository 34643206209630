import { Empresa, Setor, Tipologia } from 'models';
import { RegrasDeValidacao, Validacao, Validador, ValidacaoTipologia } from './Validador';
import { ESituacao } from 'enums';

export const validarCNPJ = (cnpj: string): boolean => {
	if (!cnpj) return false;

	const regex = /^\d{14}|(\d{2}(\.\d{3}){2}\/\d{4}-\d{2})$/;
	if (!regex.test(cnpj)) return false;

	const fmtCnpj = cnpj.replace(/\D/g, '');
	if (fmtCnpj === '00000000000000' || fmtCnpj.length !== 14) return false;

	let total = 0;

	total += parseInt(fmtCnpj[0]) * 5;
	total += parseInt(fmtCnpj[1]) * 4;
	total += parseInt(fmtCnpj[2]) * 3;
	total += parseInt(fmtCnpj[3]) * 2;
	total += parseInt(fmtCnpj[4]) * 9;
	total += parseInt(fmtCnpj[5]) * 8;
	total += parseInt(fmtCnpj[6]) * 7;
	total += parseInt(fmtCnpj[7]) * 6;
	total += parseInt(fmtCnpj[8]) * 5;
	total += parseInt(fmtCnpj[9]) * 4;
	total += parseInt(fmtCnpj[10]) * 3;
	total += parseInt(fmtCnpj[11]) * 2;

	let digitoVerificador = total % 11;
	digitoVerificador = digitoVerificador < 2 ? 0 : 11 - digitoVerificador;

	if (parseInt(fmtCnpj[12]) !== digitoVerificador) return false;

	total = 0;
	total += parseInt(fmtCnpj[0]) * 6;
	total += parseInt(fmtCnpj[1]) * 5;
	total += parseInt(fmtCnpj[2]) * 4;
	total += parseInt(fmtCnpj[3]) * 3;
	total += parseInt(fmtCnpj[4]) * 2;
	total += parseInt(fmtCnpj[5]) * 9;
	total += parseInt(fmtCnpj[6]) * 8;
	total += parseInt(fmtCnpj[7]) * 7;
	total += parseInt(fmtCnpj[8]) * 6;
	total += parseInt(fmtCnpj[9]) * 5;
	total += parseInt(fmtCnpj[10]) * 4;
	total += parseInt(fmtCnpj[11]) * 3;
	total += parseInt(fmtCnpj[12]) * 2;

	digitoVerificador = total % 11;
	digitoVerificador = digitoVerificador < 2 ? 0 : 11 - digitoVerificador;

	return parseInt(fmtCnpj[13]) === digitoVerificador;
};

export const validarCPF = (cpf: string): boolean => {
	if (!cpf) return false;

	const regex = /^\d{11}|((\d{3}\.){2}\d{3}-\d{2})$/;
	if (!regex.test(cpf)) return false;

	const fmtCpf = cpf.replace(/\D/g, '');
	if (fmtCpf === '00000000000' || fmtCpf.length !== 11) return false;

	const invalidCpfs = [
		'00000000000',
		'11111111111',
		'22222222222',
		'33333333333',
		'44444444444',
		'55555555555',
		'66666666666',
		'77777777777',
		'88888888888',
	];
	if (invalidCpfs.includes(fmtCpf)) return false;

	let total = 0;

	total += parseInt(fmtCpf[0]) * 10;
	total += parseInt(fmtCpf[1]) * 9;
	total += parseInt(fmtCpf[2]) * 8;
	total += parseInt(fmtCpf[3]) * 7;
	total += parseInt(fmtCpf[4]) * 6;
	total += parseInt(fmtCpf[5]) * 5;
	total += parseInt(fmtCpf[6]) * 4;
	total += parseInt(fmtCpf[7]) * 3;
	total += parseInt(fmtCpf[8]) * 2;

	let digitoVerificador = total % 11;
	digitoVerificador = digitoVerificador < 2 ? 0 : 11 - digitoVerificador;

	if (parseInt(fmtCpf[9]) !== digitoVerificador) return false;

	total = 0;
	total += parseInt(fmtCpf[0]) * 11;
	total += parseInt(fmtCpf[1]) * 10;
	total += parseInt(fmtCpf[2]) * 9;
	total += parseInt(fmtCpf[3]) * 8;
	total += parseInt(fmtCpf[4]) * 7;
	total += parseInt(fmtCpf[5]) * 6;
	total += parseInt(fmtCpf[6]) * 5;
	total += parseInt(fmtCpf[7]) * 4;
	total += parseInt(fmtCpf[8]) * 3;
	total += parseInt(fmtCpf[9]) * 2;

	digitoVerificador = total % 11;
	digitoVerificador = digitoVerificador < 2 ? 0 : 11 - digitoVerificador;

	return parseInt(fmtCpf[10]) === digitoVerificador;
};

export const validaEmail = (email: string): boolean => {
	try {
		const regex = /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i;
		return regex.test(email);
	} catch {
		return false;
	}
};

export type DadosFormulario = {
	cpfCnpj: string;
	razaoSocial: string;
	nomeFantasia: string;
	responsavel: string;
	cep: string;
	logradouro: string;
	numero: string;
	bairro: string;
	complemento?: string;
	estado: number | string;
	municipio: number | string;
	telefoneCelular?: string;
	telefoneFixo?: string;
	email: string;
	ehFilial: boolean;
	setor?: Setor;
	setores: Setor[];
	matriz?: Empresa;
}

const regrasDeValidacaoEmpresa: RegrasDeValidacao<DadosFormulario> = {
	cpfCnpj: (valor: string) => {
		if (!valor) {
			return { valido: false, mensagem: 'CPF/CNPJ é obrigatório' };
		}
		valor = valor.replace(/\D/g, '');
		if (valor.length <= 11) {
			if (!validarCPF(valor))
				return { valido: false, mensagem: 'CPF inválido' };
		}
		else if (!validarCNPJ(valor)) {
			return { valido: false, mensagem: 'CNPJ inválido' };
		}
		if (!/^\d{11}$|^\d{14}$/.test(valor)) {
			return { valido: false, mensagem: 'CPF/CNPJ inválido' };
		}
		return { valido: true, mensagem: null };
	},
	razaoSocial: (valor: string) => {
		if (!valor) {
			return { valido: false, mensagem: 'Razão Social é obrigatória' };
		}
		return { valido: true, mensagem: null };
	},
	nomeFantasia: (valor: string) => {
		if (!valor) {
			return { valido: false, mensagem: 'Nome Fantasia é obrigatória' };
		}
		return { valido: true, mensagem: null };
	},
	responsavel: (valor: string) => {
		if (!valor) {
			return { valido: false, mensagem: 'Responsável é obrigatório' };
		}
		return { valido: true, mensagem: null };
	},
	cep: (valor: string) => {
		if (!valor) {
			return { valido: false, mensagem: 'CEP é obrigatório' };
		}
		valor = valor.replace(/\D/g, '');
		return { valido: true, mensagem: null };
	},
	logradouro: (valor: string) => {
		if (!valor) {
			return { valido: false, mensagem: 'Logradouro é obrigatório' };
		}
		return { valido: true, mensagem: null };
	},
	numero: (valor: string) => {
		if (!valor) {
			return { valido: false, mensagem: 'Número é obrigatório' };
		}
		return { valido: true, mensagem: null };
	},
	bairro: (valor: string) => {
		if (!valor) {
			return { valido: false, mensagem: 'BAirro é obrigatório' };
		}
		return { valido: true, mensagem: null };
	},
	estado: (valor: number | string) => {
		if (!valor) {
			return { valido: false, mensagem: 'Estado é obrigatório' };
		}
		return { valido: true, mensagem: null };
	},
	municipio: (valor: number | string) => {
		if (!valor) {
			return { valido: false, mensagem: 'Município é obrigatório' };
		}
		return { valido: true, mensagem: null };
	},
	email: (valor: string) => {
		if (!valor) {
			return { valido: false, mensagem: 'Email é obrigatório' };
		}
		if (!validaEmail(valor)) {
			return { valido: false, mensagem: 'Email inválido' };
		}
		return { valido: true, mensagem: null };
	},
	matriz: ([valor, ehFilial]: any) => {
		if (ehFilial && !valor) {
			return { valido: false, mensagem: 'Matriz é obrigatória' };
		}
		return { valido: true, mensagem: null };
	},
	setores: (setores: Setor[]) => {
		return [];
	}
};

const regrasDeValidacaoSetores: RegrasDeValidacao<DadosFormulario> = {
	cpfCnpj: (valor: string) => ({ valido: true, mensagem: null }),
	razaoSocial: (valor: string) => ({ valido: true, mensagem: null }),
	nomeFantasia: (valor: string) => ({ valido: true, mensagem: null }),
	responsavel: (valor: string) => ({ valido: true, mensagem: null }),
	cep: (valor: string) => ({ valido: true, mensagem: null }),
	logradouro: (valor: string) => ({ valido: true, mensagem: null }),
	numero: (valor: string) => ({ valido: true, mensagem: null }),
	bairro: (valor: string) => ({ valido: true, mensagem: null }),
	estado: (valor: number | string) => ({ valido: true, mensagem: null }),
	municipio: (valor: number | string) => ({ valido: true, mensagem: null }),
	email: (valor: string) => ({ valido: true, mensagem: null }),
	setores: (setores: Setor[]) => {
		if (!setores.filter(setor => setor.situacaoID == ESituacao.ATIVO).any())
			return { valido: false, mensagem: 'Selecione um Setor' };
		return { valido: true, mensagem: null };
	},
};

export const ValidadorEmpresa = new Validador<DadosFormulario>(regrasDeValidacaoEmpresa);
export const ValidadorSetores = new Validador<DadosFormulario>(regrasDeValidacaoSetores);