import { Api } from '../Axios';

export interface IPermissao {
	permissaoID: number;
	nome: string;
}

const getAll = async (): Promise<IPermissao[] | Error> => {
	try {
		const urlRelativa = `/Permissao/ObterTodos`;
		const { data } = await Api.get(urlRelativa);

		if (data) {
			return data.data;
		}
		return new Error('Erro ao listar os registros.');
	} catch (error) {
		return new Error((error as { message: string }).message || 'Erro ao listar os registros.');
	}
};

const getPermissionsByModule = async (moduloId: number, perfilId: number): Promise<number[] | Error> => {
	try {
		const urlRelativa = `/Permissao/ObterPermissoesPorModulo?moduloID=${moduloId}&perfilID=${perfilId}`;
		const { data } = await Api.get(urlRelativa);

		if (data) {
			return data.data;
		}
		return new Error('Erro ao listar os registros.');
	} catch (error) {
		return new Error((error as { message: string }).message || 'Erro ao listar os registros.');
	}
};

export const PermissaoService = {
	getAll,
	getPermissionsByModule
};