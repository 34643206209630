import { ESituacao } from 'enums';
import { Empresa } from './Empresa';

export class Usuario {
	usuarioID: number;
	nome?: string;
	empresas?: Empresa[];
	email: string;
	situacaoID: ESituacao;
	senha: string;
	usuarioIDCadastro?: number;
	usuarioNomeCadastro?: string;
	dataHoraCadastro?: string;
	usuarioIDAlteracao?: number;
	usuarioNomeAlteracao?: string;
	dataHoraAlteracao?: string;

	constructor(dto: any) {
		this.usuarioID = dto?.usuarioID;
		this.nome = dto?.nome;
		this.email = dto?.email;
		this.senha = dto?.senha;
		this.situacaoID = dto?.situacaoID;
		this.usuarioIDCadastro = dto?.usuarioIDCadastro;
		this.usuarioNomeCadastro = dto?.usuarioNomeCadastro;
		this.dataHoraCadastro = dto?.dataHoraCadastro?.replace('Z', '');
		this.usuarioIDAlteracao = dto?.usuarioIDAlteracao;
		this.usuarioNomeAlteracao = dto?.usuarioNomeAlteracao;
		this.dataHoraAlteracao = dto?.dataHoraAlteracao?.replace('Z', '');
		this.empresas = dto?.empresas?.map((empresa: any) => new Empresa(empresa));
	}
}