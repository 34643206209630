import { ESituacao } from 'enums';
import { EmpresaRequest } from './EmpresaRequest';

export class UsuarioRequest {
	usuarioID: number;
	empresas: UsuarioEmpresaRequest[];

	constructor(dto?: any) {
		this.usuarioID = dto?.usuarioID;
		this.empresas = dto?.empresas?.map((empresa: any) => new UsuarioEmpresaRequest(empresa)) || [];
	}
}

export class UsuarioEmpresaRequest {
	empresaID: number;
	perfilID: number;
	situacaoID: ESituacao;

	constructor(dto?: any) {
		this.empresaID = dto?.empresaID;
		this.perfilID = dto?.perfilID;
		this.situacaoID = dto?.situacaoID;
	}
}