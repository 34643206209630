export class PerfilModuloPermissoesRequest {
	perfilID: number;
	modulos: PerfilModuloRequest[];

	constructor(dto?: any) {
		this.perfilID = dto?.perfilID;
		this.modulos = dto?.modulos.map((modulo: any) => new PerfilModuloRequest(modulo));
	}
}

export class PerfilModuloRequest {
	moduloID: number;
	permissoes: ModuloPermissoesRequest[];

	constructor(dto?: any) {
		this.moduloID = dto?.moduloID;
		this.permissoes = dto?.permissoes.map((permissao: any) => new ModuloPermissoesRequest(permissao));
	}
}

export class ModuloPermissoesRequest {
	permissaoID: number;

	constructor(dto?: any) {
		this.permissaoID = dto?.permissaoID;
	}
}