import { useCallback, useEffect, useState } from 'react';
import {
	Typography,
	TextField,
	IconButton,
	Icon,
	Box,
	Button,
	InputAdornment,
	Card,
	CardContent,
	CircularProgress,
} from '@mui/material';
import { catchError, from, switchMap, Observable, tap, throwError, of } from 'rxjs';
import * as yup from 'yup';
import { Empresa, Modulo, Perfil, Token, Usuario } from 'models';
import { AuthService } from 'shared/services/Auth/AuthService';
import { createContext, useContextSelector, useContext } from 'use-context-selector';
import { ToastErro, ToastSucesso } from 'utils/toast';
import { ModuloService } from 'shared/services/Modulos/ModulosService';
import { useEspera } from './EsperaContext';
import { EmpresaService } from 'shared/services/Empresas/EmpresasService';

interface IAuthContextData {
	handleLogout: () => void;
	handleLogin: (email: string, senha: string) => Observable<void>;
	handleChangeEmpresa: (empresa: Empresa) => void;
	handleChangePerfil: (perfil: Perfil) => void;
	getModuloesByEmterpriseAndUser: () => void;
	getEnterpriseByUserId: () => Observable<Empresa[]>;

	usuarioLogado: Usuario;
	empresaSelecionada?: Empresa;
	empresasUsuario: Empresa[];
	perfilSelecionado?: Perfil;
	perfilsUsuario: Perfil[];
	modulosUsuario: Modulo[];
}

const AuthContext = createContext({} as IAuthContextData);

const loginSchema = yup.object().shape({
	email: yup.string().email().required('O campo email é obrigatorio'),
	password: yup.string().min(5).required('O campo senha é obrigatoria')
});
interface IAuthProviderProps {
	children: React.ReactNode;
}

export const AuthProvider: React.FC<IAuthProviderProps> = ({ children }) => {
	const [accessToken, setAccessToken] = useState<string>();
	const [usuarioLogado, setUsuarioLogado] = useState<Usuario>();
	const [empresaSelecionada, setEmpresaSelecionada] = useState<Empresa>();
	const [empresasUsuario, setEmpresasUsuario] = useState<Empresa[]>([]);
	const [perfilSelecionado, setPerfilSelecionado] = useState<Perfil>();
	const [perfilsUsuario, setPerfilsUsuario] = useState<Perfil[]>([]);
	const [modulosUsuario, setModulosUsuario] = useState<Modulo[]>([]);

	const [mostrarSenha, setMostrarSenha] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');

	const [emailError, setEmailError] = useState('');
	const [passwordError, setPasswordError] = useState('');

	useEffect(() => {
		const jsonUsuarioLocalStorage: string | null = localStorage.getItem(process.env.REACT_APP_LOCAL_STORAGE_TOKEN!);
		if (!jsonUsuarioLocalStorage)
			return;
		const dadosTokenUsuario = JSON.parse(jsonUsuarioLocalStorage!);
		const { accessToken } = new Token(dadosTokenUsuario);

		setAccessToken(accessToken);
		_atualizar(new Usuario(dadosTokenUsuario));
	}, []);

	useEffect(() => {
		getModuloesByEmterpriseAndUser();
	}, [usuarioLogado, empresaSelecionada]);

	useEffect(() => {
		getEnterpriseByUserId()
			.subscribe({
				error: (erro) => ToastErro(erro)
			});
	}, [usuarioLogado]);

	const _atualizar = (usuario: Usuario) => {
		const { empresas = [] } = usuario;
		const [empresa] = empresas;
		const { perfils = [] } = empresa;
		const [perfil] = perfils;
		const { modulos = [] } = perfil;

		setUsuarioLogado(usuario);
		// setEmpresaSelecionada(empresa);
		// setEmpresasUsuario(empresas);
		setPerfilSelecionado(perfil);
		setPerfilsUsuario(perfils);
		setModulosUsuario(modulos);
	};

	const handleLogin = useCallback((email: string, senha: string) => {
		return from(AuthService.authorization(email, senha))
			.pipe(
				tap((tokenMaisUsuario: any) => {
					const { accessToken, tokenType, expires, ...usuario } = tokenMaisUsuario;
					const jsonUsuarioString = JSON.stringify({ ...usuario, email: email.criptografar(), senha: senha.criptografar() });
					localStorage.setItem(process.env.REACT_APP_LOCAL_STORAGE_TOKEN!, jsonUsuarioString);
					ToastSucesso(`Login realizado com sucesso`);
					setAccessToken(accessToken);
					_atualizar(new Usuario(usuario));
				}),
				catchError((error) => {
					return throwError(() => error);
				})
			);
	}, []);

	const handleLogout = useCallback(() => {
		localStorage.removeItem(process.env.REACT_APP_LOCAL_STORAGE_TOKEN!);
		setAccessToken(undefined);
	}, []);

	const handleChangeEmpresa = useCallback(setEmpresaSelecionada, []);

	const handleChangePerfil = useCallback(setPerfilSelecionado, []);

	const handleSubmit = () => {
		setIsLoading(true);
		from(loginSchema.validate({ email, password }, { abortEarly: false }))
			.pipe(
				switchMap(({ email, password }) => from(handleLogin(email, password)))
			)
			.subscribe({
				next: () => setIsLoading(false),
				error: (errors) => {
					if ('inner' in errors) {
						errors.inner.forEach((error: any) => {
							if (error.path === 'email') {
								setEmailError(error.message);
								setIsLoading(false);
							} else {
								setPasswordError(error.message);
								setIsLoading(false);
							}
						});
					} else {
						setIsLoading(false);
						ToastErro(errors?.message || 'Erro ao realizar login');

					}
				}

			});
	};

	const getModuloesByEmterpriseAndUser = () => {
		if (!usuarioLogado || !empresaSelecionada)
			return;
		from(ModuloService.getAllByUserId(usuarioLogado.usuarioID, empresaSelecionada.empresaID))
			.pipe(
				switchMap((resposta) => {
					if (resposta instanceof Error) return throwError(() => resposta.message);
					return of(resposta.map(modulo => new Modulo(modulo)));
				})
			).subscribe({
				next: (modulos: Modulo[]) => setModulosUsuario(modulos),
				error: (erro) => ToastErro(erro)
			});
	};

	const getEnterpriseByUserId = () => {
		if (!usuarioLogado)
			return of([]);

		return from(EmpresaService.getByUserId(usuarioLogado.usuarioID))
			.pipe(
				switchMap((resposta) => {
					if (resposta instanceof Error) return throwError(() => resposta.message);
					return of(resposta.map(empresa => new Empresa(empresa)));
				}),
				tap((empresas: Empresa[]) => {
					setEmpresasUsuario(empresas);
					if (empresas.length == 1 || !empresas.some(({ empresaID }) => empresaSelecionada?.empresaID == empresaID))
						setEmpresaSelecionada(empresas[0]);
				})
			);
	};

	if (!usuarioLogado)
		return (
			<Box width='100vw' height='100vh' display='flex' alignItems='center' justifyContent='center' bgcolor='#0A4F7F'>
				<Box
					position='absolute'
					display='flex'
					alignItems='center'
					justifyContent='center'
					height='100vh'
					width='100vw'
					marginBottom='2'
				>
					<img src='/Logo-arquivoteca-para-footer-min.png' style={{ backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundAttachment: 'fixed' }} />
				</Box>
				<Card sx={{ maxWidth: 400, width: '100%', borderRadius: 3 }} component='form'>
					<CardContent>
						<Box display='flex' flexDirection='column' gap={2}>
							<Typography variant='h6' align='center'>Identifique-se</Typography>
							<TextField
								fullWidth
								label='Email'
								type='email'
								value={email}
								disabled={isLoading}
								error={!!emailError}
								helperText={emailError}
								onKeyDown={() => setEmailError('')}
								onChange={e => setEmail(e.target.value)}
							/>
							<TextField
								fullWidth
								label='Senha'
								type={mostrarSenha ? 'text' : 'password'}
								value={password}
								disabled={isLoading}
								error={!!passwordError}
								helperText={passwordError}
								onKeyDown={() => setPasswordError('')}
								onChange={e => setPassword(e.target.value)}
								InputProps={{
									endAdornment: (
										<InputAdornment position='end'>
											<IconButton
												onClick={() => setMostrarSenha(!mostrarSenha)}
												edge='end'
												aria-label='toggle password visibility'
											>
												<Icon>
													{mostrarSenha ? 'visibility' : 'visibility_off'}
												</Icon>
											</IconButton>
										</InputAdornment>
									),
								}}
							/>
						</Box>
						<Button
							type='submit'
							disabled={isLoading}
							variant='contained'
							onClick={handleSubmit}
							endIcon={isLoading ? <CircularProgress variant='indeterminate' color='inherit' size={20} /> : undefined}
							fullWidth
							sx={{ mt: 2 }}
						>
							Login
						</Button>
						<Button
							variant='text'
							color='secondary'
							fullWidth
							onClick={() => {
								// handle forgot password logic
							}}
						>
							Esqueceu a senha?
						</Button>
					</CardContent>
				</Card>
			</Box>
		);

	return (
		<AuthContext.Provider value={{
			handleLogin,
			handleLogout,
			handleChangeEmpresa,
			handleChangePerfil,
			getModuloesByEmterpriseAndUser,
			getEnterpriseByUserId,

			usuarioLogado,
			empresasUsuario,
			empresaSelecionada,
			perfilSelecionado,
			perfilsUsuario,
			modulosUsuario,
		}}>
			{children}
		</AuthContext.Provider>
	);
};

export const useAuthContext = (): IAuthContextData => useContext<IAuthContextData>(AuthContext);

interface IAutenticacaoContext {
	handleLogout: () => void;
	handleLogin: (email: string, senha: string) => Observable<void>;
	usuarioLogado: Usuario;
}
export const useAutenticacaoContext = (): IAutenticacaoContext => useContextSelector<IAuthContextData, IAutenticacaoContext>(AuthContext, ({ handleLogout, handleLogin, usuarioLogado }) => ({ handleLogout, handleLogin, usuarioLogado }));

interface IEmpresaSelecionada {
	handleChangeEmpresa: (empresa: Empresa) => void;
	empresaSelecionada?: Empresa;
	perfilSelecionado?: Perfil;
}
export const useEmpresaSelecionadaContext = (): IEmpresaSelecionada => useContextSelector<IAuthContextData, IEmpresaSelecionada>(AuthContext, ({ handleChangeEmpresa, empresaSelecionada, perfilSelecionado }) => ({ handleChangeEmpresa, empresaSelecionada, perfilSelecionado }));

interface IEmpresasUsuario {
	empresasUsuario: Empresa[];
}
export const useEmpresasUsuarioContext = (): IEmpresasUsuario => useContextSelector<IAuthContextData, IEmpresasUsuario>(AuthContext, ({ empresasUsuario }) => ({ empresasUsuario }));

interface IPerfilSelecionado {
	handleChangePerfil: (empresa: Perfil) => void;
	perfilSelecionado?: Perfil;
	modulosUsuario: Modulo[];
}
export const usePerfilSelecionadoContext = (): IPerfilSelecionado => useContextSelector<IAuthContextData, IPerfilSelecionado>(AuthContext, ({ handleChangePerfil, perfilSelecionado, modulosUsuario }) => ({ handleChangePerfil, perfilSelecionado, modulosUsuario }));

interface IPerfilsUsuario {
	perfilsUsuario: Perfil[];
}
export const usePerfilsUsuarioContext = (): IPerfilsUsuario => useContextSelector<IAuthContextData, IPerfilsUsuario>(AuthContext, ({ perfilsUsuario }) => ({ perfilsUsuario }));

