export const SECONDARY: string = '#767676';
export const SUCESSO: string = '#48960C';
export const ATENCAO: string = '#FAC400';
export const ERRO: string = '#A32100';
export const INFO: string = '#1779ba';
export const GRAY_LIGHT: string = '#e6e6e6';
export const GRAY_MEDIUM: string = '#cacaca';
export const GRAY_DARK: string = '#8a8a8a';
export const ICE: string = '#fffafa';

export const VERMELHO: string = '#F52F22';
export const ROSA: string = '#F1428A';
export const ROXO: string = '#781DA0';
export const AZUL_MARINHO: string = '#343DAC';
export const AZUL_MARINHO_OPACO: string = '#283266';
export const AZUL: string = '#0065AB';
export const CIANO: string = '#00B7D6';
export const VERDE_MAR: string = '#00968B';
export const VERDE: string = '#48960C';
export const VERDE_IAGRO: string = '#00612F';
export const AMARELO: string = '#FFDC0B';
export const LARANJA: string = '#F57600';
export const LARANJA_AVERMELHADO: string = '#EE4A08';
export const CINZA_QUENTE: string = '#80746D';
export const CINZA: string = '#737373';
export const CINZA_FRIO: string = '#61717D';
export const CINZA_OPACO: string = '#383B40';
export const BRANCO: string = '#FFFFFF';
export const PRETO: string = '#000000';
export const GRAFITE: string = '#333333';

export const PALETA_VERMELHO: string[] = ['#F52F22', '#A32100', '#C92100', '#e12200', '#F52F22', '#F54F47', '#F76F6C', '#F89997', '#F8B7B6', '#F5DBD9', '#FFF0EE'];
export const PALETA_ROSA: string[] = ['#F1428A', '#9B0D54', '#B0105B', '#C41261', '#D91468', '#ED186F', '#F1428A', '#F46CA5', '#F897BF', '#FBC1DA', '#FFEBF5'];
export const PALETA_ROXO: string[] = ['#781DA0', '#4D007A', '#660092', '#781DA0', '#8939AD', '#9B56BB', '#AD73C8', '#BE90D6', '#D0ACE4', '#E1C9F1', '#F3E6FF'];
export const PALETA_AZUL_MARINHO: string[] = ['#343DAC', '#0F1E82', '#1A23A0', '#343DAC', '#4E56B8', '#6870C4', '#838ACF', '#9DA3DB', '#B7BDE7', '#D1D6F3', '#EBF0FF'];
export const PALETA_AZUL: string[] = ['#0065AB', '#003D79', '#004D8A', '#0065AB', '#0079B8', '#0095D3', '#49AFD9', '#89CBDF', '#A6D8E7', '#C5E5EF', '#E1F1F6'];
export const PALETA_CIANO: string[] = ['#00B7D6', '#004A70', '#005680', '#006690', '#0081A7', '#009CBF', '#00B7D6', '#36C9E1', '#6DDBEB', '#A3EDF6', '#CCFBFF'];
export const PALETA_VERDE_MAR: string[] = ['#00968B', '#006668', '#007E7A', '#00968B', '#00AB9A', '#00BFA9', '#00D4B8', '#38DFC8', '#6FEAD9', '#A7F4E9', '#DEFFF9'];
export const PALETA_VERDE: string[] = ['#48960C', '#1D5100', '#266900', '#318700', '#48960C', '#62A420', '#60B515', '#85C81A', '#AADB1E', '#C7E59C', '#DFF0D0'];
export const PALETA_VERDE_IAGRO: string[] = ['#00612F', '#004723', '#1A5A3A', '#346D50', '#4E8067', '#68937E', '#82A794', '#9CBAAB', '#B6CDC2', '#D0E0D8', '#EAF3EF'];
export const PALETA_AMARELO: string[] = ['#FFDC0B', '#C47D00', '#D28F00', '#DFA100', '#EDB200', '#FAC400', '#FDD006', '#FFDC0B', '#FFE860', '#FEF3B5', '#FFFCE8'];
export const PALETA_LARANHA: string[] = ['#F57600', '#AA4500', '#C25400', '#D36000', '#E46C00', '#F57600', '#FF8400', '#FF9C32', '#FFB565', '#FFCD97', '#FFE5C9'];
export const PALETA_LARANGJA_AVERMELHADO: string[] = ['#EE4A08', '#CD3517', '#DE400F', '#EE4A08', '#FF5500', '#FF681C', '#FF8142', '#FF9A69', '#FFB38F', '#FFCCB5', '#FFE5DC'];
export const PALETA_CINZA_QUENTE: string[] = ['#80746D', '#5B4D47', '#6C5F59', '#80746D', '#948981', '#A89E95', '#BBB3A9', '#CFC8BD', '#E3DDD1', '#F4F1E6', '#FAF9F5'];
export const PALETA_CINZA: string[] = ['#737373', '#313131', '#444444', '#565656', '#737373', '#9A9A9A', '#CCCCCC', '#DDDDDD', '#EEEEEE', '#F2F2F2', '#FAFAFA'];
export const PALETA_CINZA_FRIO: string[] = ['#61717D', '#25333D', '#314351', '#495A67', '#61717D', '#798893', '#919FA8', '#A9B6BE', '#C1CDD4', '#D9E4EA', '#F3F6FA'];
export const PALETA_BRANCO: string[] = ['#FFFFFF', '#E0E0E0', '#C0C0C0', '#A0A0A0', '#808080', '#606060', '#404040', '#303030', '#202020', '#101010', '#080808', '#000000'];
export const PALETA_GRAFITE: string[] = ['#F2F2F2', '#E5E5E5', '#D9D9D9', '#CCCCCC', '#BFBFBF', '#A6A6A6', '#808080', '#666666', '#4D4D4D', '#333333', '#1A1A1A', '#0D0D0D'];

//SISTEMA
// export const VERDE = '#00612F';
// export const VERDE_CLARO = '#6e9c84';
// export const VERDE_ESCURO = '#004723';

//GERAL
// export const PRIMARIA = VERDE;
// export const PRIMARIA_CLARO = VERDE_CLARO;
// export const PRIMARIA_ESCURO = VERDE_ESCURO;
//export const BRANCO = BRANCO;
