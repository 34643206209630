import { Button, Card, Stack, Typography, Icon } from '@mui/material';
import { Box } from '@mui/system';
import { ReactNode } from 'react';
import { useAutenticacaoContext, useEmpresaSelecionadaContext } from 'contexts';

interface ILayoutBaseDePaginaProps {
	children: React.ReactNode;
	ferramentasDaListagem?: ReactNode | undefined;
	barraDeFerramentas?: ReactNode | undefined;
}

export const LayoutBaseDePagina: React.FC<ILayoutBaseDePaginaProps> = ({ children, barraDeFerramentas: barraDeFerramentas, ferramentasDaListagem: ferramentasDaListagem }) => {

	const { handleLogout } = useAutenticacaoContext();
	const { empresaSelecionada } = useEmpresaSelecionadaContext();

	return (
		<Box
			display='flex'
			flexDirection='column'
			width='99vw'
			minHeight='100vh'>
			<Stack
				direction={'row'}
				spacing={50}
				bgcolor='#085784'
				display='flex'
				justifyContent='center'
				alignItems='center'
				height='5vh'>
				<Box position='absolute'>
					<img src='/Logo-arquivoteca-para-footer-min.png' height={28} style={{ backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }} />
				</Box>
				<Box flex={1} display='flex' justifyContent='end'>
					<Button
						style={{
							fontSize: '15px',
							color: 'white',
							display: 'flex'
						}}
						disableElevation
						variant='outlined'
						size='small'
						onClick={() => { handleLogout(); window.location.reload(); }}
						endIcon={<Icon>logout</Icon>}
					// onClick={() => { window.location.href = 'https://www.ecmhorus.com.br'; }}
					// >Ir para o Site</Button>
					>Sair</Button>
				</Box>
			</Stack>

			<Box paddingX={1} paddingTop={1} sx={{ backgroundColor: '#D3D3D3' }}>
				<Card variant='outlined' sx={{
					boxShadow: 1,
					borderRadius: 1,
					p: 1,
					minWidth: 150,
					maxWidth: 250
				}} >
					<Typography key={0} fontSize={10} align='left' color='#085784' fontWeight='bold'>
						Empresa: {empresaSelecionada?.nomeFantasia}
					</Typography>
				</Card>
			</Box>

			{
				ferramentasDaListagem && (
					<Typography>
						<Box>
							{ferramentasDaListagem}
						</Box>
					</Typography>
				)
			}

			{
				barraDeFerramentas && (
					<Typography>
						<Box>
							{barraDeFerramentas}
						</Box>
					</Typography>
				)
			}
			<Box>
				{children}
			</Box>
		</Box >
	);
};