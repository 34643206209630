import { ajustarEscala, FonteSize } from './mixins';

// FONT FAMILY
export const FONTE_FAMILY_SANS = 'sans-serif-light';
export const FONTE_FAMILY_REGULAR = 'OpenSans-Regular';
export const FONTE_FAMILY_BOLD = 'OpenSans-Bold';

// FONT WEIGHT
export const FONTE_WEIGHT_REGULAR = '400';
export const FONTE_WEIGHT_BOLD = '700';

// FONT SIZE
export const FONTE_SIZE_50 = FonteSize(50);
export const FONTE_SIZE_45 = FonteSize(45);
export const FONTE_SIZE_40 = FonteSize(40);
export const FONTE_SIZE_35 = FonteSize(35);
export const FONTE_SIZE_30 = FonteSize(30);
export const FONTE_SIZE_25 = FonteSize(25);
export const FONTE_SIZE_20 = FonteSize(20);
export const FONTE_SIZE_18 = FonteSize(18);
export const FONTE_SIZE_16 = FonteSize(16);
export const FONTE_SIZE_14 = FonteSize(14);
export const FONTE_SIZE_12 = FonteSize(12);
export const FONTE_SIZE_10 = FonteSize(10);

// export const FONTE_SIZE_45 = ajustarEscala(45);
// export const FONTE_SIZE_35 = ajustarEscala(35);
// export const FONTE_SIZE_30 = ajustarEscala(30);
// export const FONTE_SIZE_25 = ajustarEscala(25);
// export const FONTE_SIZE_20 = ajustarEscala(20);
// export const FONTE_SIZE_18 = ajustarEscala(18);
// export const FONTE_SIZE_16 = ajustarEscala(16);
// export const FONTE_SIZE_14 = ajustarEscala(14);
// export const FONTE_SIZE_12 = ajustarEscala(12);
// export const FONTE_SIZE_10 = ajustarEscala(10);

// LINE HEIGHT
export const LINE_HEIGHT_24 = ajustarEscala(24);
export const LINE_HEIGHT_20 = ajustarEscala(20);
export const LINE_HEIGHT_16 = ajustarEscala(16);

// FONT STYLE
export const FONTE_REGULAR = {
	fontFamily: FONTE_FAMILY_REGULAR,
	fontWeight: FONTE_WEIGHT_REGULAR,
};

export const FONTE_BOLD = {
	fontFamily: FONTE_FAMILY_BOLD,
	fontWeight: FONTE_WEIGHT_BOLD,
};

//PADRÃO
export const FONTE_HIPER = FONTE_SIZE_50;
export const FONTE_POWER = FONTE_SIZE_45;
export const FONTE_ULTRA = FONTE_SIZE_35;
export const FONTE_MEGA = FONTE_SIZE_30;
export const FONTE_SUPER = FONTE_SIZE_25;
export const FONTE_GRANDE = FONTE_SIZE_20;
export const FONTE_MEDIA = FONTE_SIZE_18;
export const FONTE_PEQUENA = FONTE_SIZE_16;
export const FONTE_MINI = FONTE_SIZE_14;
export const FONTE_MICRO = FONTE_SIZE_12;
export const FONTE_NANO = FONTE_SIZE_10;