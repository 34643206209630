import React, { FC, useCallback, useEffect, useState } from 'react';
import {
	Paper,
	Box,
	Typography,
	Icon,
	Button,
	Autocomplete,
	TextField,
	List,
	ListSubheader,
	ListItem,
	TextFieldVariants
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import { LayoutBaseDePagina } from 'shared/layouts';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDebounce } from 'shared/hooks';
import { Empresa, Perfil, Usuario } from 'models';
import { UsuarioService } from 'shared/services/Usuarios/UsuariosService';
import { useAuthContext, useEspera, useMensagem } from 'contexts';
import { combineLatest, from, of, switchMap, take, tap, throwError } from 'rxjs';

import { ESituacao } from 'enums';
import { ToastErro, ToastSucesso } from 'utils/toast';
import { FormGrid, LineGrid } from 'shared/components';
import { Validacao } from 'validators/Validador';
import { EmpresaService } from 'shared/services/Empresas/EmpresasService';
import { BRANCO, GRAFITE, INFO } from 'styles';
import { DefaultButtonOnlyIcon } from 'shared/components/buttons';
import { UsuarioRequest } from 'requests/UsuarioRequest';
import { PerfilService } from 'shared/services/Perfils/PerfilsService';

const variant: TextFieldVariants = 'standard';

const sxSelectFormText = {
	'.MuiFormLabel-root, .MuiSvgIcon-root, .MuiInputBase-root, .MuiInputBase-root::before': {
		color: BRANCO
	},
	'.css-5h82ro-MuiInputBase-root-MuiInput-root::before': {
		borderBottom: '1px solid white'
	},
	'.css-2mgyhq-MuiNativeSelect-select-MuiInputBase-input-MuiInput-input:not([multiple]) option': {
		backgroundColor: GRAFITE
	}
};

export const VincularEmpresaUsuario: React.FC = () => {
	const { state } = useLocation();
	const { usuarioID } = state;

	const { BloquearTela, DesbloquearTela } = useEspera();

	const [usuario, setUsuario] = useState<Usuario>();

	const [empresas, setEmpresas] = useState<Empresa[]>([]);
	const [validacao, setValidacao] = useState<Validacao>({ valido: true, mensagem: null });
	const { Informacao } = useMensagem();
	const { getEnterpriseByUserId } = useAuthContext();

	const navigate = useNavigate();

	useEffect(() => {
		BloquearTela();
		from(UsuarioService.getById(usuarioID))
			.pipe(
				switchMap((resposta) => {
					if (resposta instanceof Error)
						return throwError(() => resposta.message);
					return of(new Usuario(resposta));
				}),
				tap(() => DesbloquearTela()),
			).subscribe({
				next: (usuario: Usuario) => {
					setUsuario(usuario);
					setEmpresas(usuario?.empresas || []);
				},
				error: (erro) => ToastErro(erro)
			});
	}, []);

	const handleAddEmpresa = (empresas: Empresa[]) => {
		setEmpresas([...empresas]);
	};

	const AlterarUsuarioEmpresa = () => {
		if (!empresas.any()) {
			setValidacao({ valido: false, mensagem: 'Adicione ao menos uma empresa' });
			return;
		}

		Informacao(
			null,
			() => <Typography display='flex' justifyContent='center' alignItems='center' flexDirection='column'>
				Deseja prosseguir com o cadastro da empresa?
				<Typography fontWeight={600} fontSize={13} fontStyle='italic'>* Antes de confirmar, revise os dados informados.</Typography>
			</Typography>,
			[
				{
					label: 'Sim',
					onPress: () => {
						const alteracaoUsuario = new UsuarioRequest({
							usuarioID,
							empresas: empresas.map(({ empresaID, situacaoID, perfil, perfils }) => ({ empresaID, situacaoID, perfilID: perfil?.perfilID ?? perfils?.[0].perfilID }))
						});

						BloquearTela();
						from(UsuarioService.update(alteracaoUsuario))
							.pipe(
								switchMap((resposta) => {
									if (resposta instanceof Error)
										return throwError(() => resposta.message);
									return of(resposta);
								}),
								switchMap(() => getEnterpriseByUserId()),
								tap(() => DesbloquearTela())
							)
							.subscribe({
								next: () => ToastSucesso(`Empresa(s) do usuário alterado com sucesso`),
								error: (erro) => {
									ToastErro(erro);
									DesbloquearTela();
								},
								complete: () => navigate(-1)
							});
					}
				},
				{
					label: 'Não',
					fechar: true
				}
			]
		);
	};

	return (
		<LayoutBaseDePagina>
			<Box padding={1} display='flex' justifyContent='center' alignItems='center' sx={{ backgroundColor: '#D3D3D3' }}>
				<Grid
					container
					spacing={1}
					component={Paper}
					elevation={0}
					sx={{ flexGrow: 1 }}
					display='flex'
					flexDirection='column'
					justifyContent='center'
					alignItems='center'
					padding={2}
				>
					<Grid
						size={12}
						display='flex'
						flexDirection='row'
						justifyContent='center'
						alignItems='center'
						sx={{ mb: 3 }}
					>
						<Typography variant='h4'>
							Vincular Usuario/Empresa
						</Typography>
					</Grid>
					<Grid size={12} container spacing={3} component='form' sx={{ flexGrow: 1 }}>
						<FormGrid size={{ xs: 12, md: 4 }}>
							<Typography fontWeight={600}>Nome do Usuario</Typography>
							<Typography>{usuario?.nome}</Typography>
						</FormGrid>
						<FormGrid size={{ xs: 12, md: 4 }}>
							<Typography fontWeight={600}>E-mail</Typography>
							<Typography>{usuario?.email}</Typography>
						</FormGrid>
						<Empresas
							handleAddEmpresa={handleAddEmpresa}
							validacaoLista={validacao}
							empresaIncluidas={empresas}
						/>
					</Grid>
				</Grid>
			</Box>
			<Grid container sx={{ padding: '1rem', justifyContent: 'space-between', alignSelf: 'end' }}>
				<Button
					color='info'
					disableElevation
					variant='contained'
					onClick={() => navigate(-1)}
					startIcon={<Icon>arrow_back</Icon>} >
					<Typography variant='button' whiteSpace='nowrap' textOverflow='ellipsis' overflow='hidden'>
						Voltar
					</Typography>
				</Button>
				<Button
					color='success'
					disableElevation
					variant='contained'
					onClick={() => AlterarUsuarioEmpresa()}
					sx={{ mt: 1, mr: 1 }}
					startIcon={<Icon>save</Icon>} >
					<Typography variant='button' whiteSpace='nowrap' textOverflow='ellipsis' overflow='hidden'>
						salvar
					</Typography>
				</Button>
			</Grid>
		</LayoutBaseDePagina >
	);
};


interface IEmpresa {
	handleAddEmpresa: (empresas: Empresa[]) => void,
	validacaoLista: Validacao | null,
	empresaIncluidas: Empresa[]
}

const Empresas: FC<IEmpresa> = ({ handleAddEmpresa, validacaoLista, empresaIncluidas }) => {
	const [perfils, setPerfils] = useState<Perfil[]>([]);
	const [empresa, setEmpresa] = useState<Empresa | null>();
	const [empresas, setEmpresas] = useState<Empresa[]>([]);
	const [empresasSelecionadas, setEmpresasSelecionadas] = useState<Empresa[]>(empresaIncluidas);
	const [validacao, setValidacao] = useState<Validacao>({ valido: true, mensagem: null });
	const { Informacao } = useMensagem();

	useEffect(() => {
		combineLatest({
			empresas: from(EmpresaService.getAll())
				.pipe(
					switchMap((retorno: any | Error) => {
						if (retorno instanceof Error)
							return throwError(() => retorno);

						return of(retorno
							.map((empresa: any) => new Empresa(empresa))
							.filter((empresa: Empresa) => empresa.situacaoID == ESituacao.ATIVO));
					}),
				),
			perfils: from(PerfilService.getAll())
				.pipe(
					switchMap((resposta) => {
						if (resposta instanceof Error)
							return throwError(() => resposta.message);
						return of(resposta.data
							?.map(perfil => new Perfil(perfil))
							.filter((perfil: Perfil) => perfil.situacaoID == ESituacao.ATIVO));
					})
				)
		})
			.pipe(
				take(1),
			)
			.subscribe({
				next: ({ empresas, perfils }) => {
					setPerfils(perfils);
					setEmpresas(empresas);
				},
				error: (erro) => ToastErro(erro)
			});
	}, []);

	useEffect(() => {
		if (validacaoLista)
			setValidacao(validacaoLista);
	}, [validacaoLista]);

	useEffect(() => {
		if (empresaIncluidas != empresasSelecionadas)
			setEmpresasSelecionadas(empresaIncluidas);
	}, [validacaoLista, empresaIncluidas]);

	const handleValidarEmpresa = () => {
		if (!empresa)
			setValidacao({ valido: false, mensagem: 'Selecione uma empresa para ser adicionada' } as Validacao);
		setValidacao({ valido: true, mensagem: null });
	};

	const handle = useCallback(() => {
		if (!empresa) {
			setValidacao({ valido: false, mensagem: 'Selecione uma empresa para ser adicionada' } as Validacao);
			return;
		}

		const empresaJaAdicionada = empresasSelecionadas.find(({ empresaID }) => empresaID == empresa.empresaID);
		if (empresaJaAdicionada)
			empresaJaAdicionada.situacaoID = ESituacao.ATIVO;
		else
			empresasSelecionadas?.push(new Empresa({ ...empresa, perfil: perfils[0], ehNova: true }));

		const empresasRestantes = [...empresasSelecionadas];
		setEmpresasSelecionadas(empresasRestantes);
		handleAddEmpresa(empresasRestantes);
		setEmpresa(null);
	}, [empresasSelecionadas, perfils, empresa]);

	const handleRemoveEmpresa = (empresa: Empresa) => {
		Informacao(
			'Atenção',
			() =>
				<Typography display='flex' flexDirection='row' whiteSpace='nowrap'>
					Deseja remover o empresa <Typography fontWeight={700}>&nbsp;{empresa.razaoSocial}&nbsp;</Typography>?
				</Typography>,
			[
				{
					label: 'Sim',
					onPress: () => {
						if (!empresa.ehNova) {
							empresa.situacaoID = ESituacao.CANCELADO;
							setEmpresasSelecionadas([...empresasSelecionadas]);
						} else {
							const empresasRestantes = empresasSelecionadas.filter(({ empresaID }) => empresaID != empresa.empresaID);
							setEmpresasSelecionadas([...empresasRestantes]);
							handleAddEmpresa(empresasRestantes);
						}
					}
				},
				{
					label: 'Cancelar',
					fechar: true
				}
			]
		);
	};

	return (
		<Grid size={12} container>
			<LineGrid>
				<FormGrid size={{ xs: 10 }}>
					<Autocomplete
						clearOnEscape
						value={empresa}
						inputValue={empresa?.razaoSocial || ''}
						options={empresas}
						onChange={(event: any, novoValor: Empresa | null) => setEmpresa(novoValor)}
						getOptionLabel={(option: Empresa) => option.razaoSocial}
						getOptionDisabled={(option) => empresasSelecionadas?.some(empresa => empresa.empresaID == option.empresaID && empresa.situacaoID == ESituacao.ATIVO) || false}
						renderInput={(params) =>
							<TextField
								{...params}
								id='empresa'
								name='empresa'
								type='text'
								placeholder='Empresas'
								autoComplete='Empresas'
								required
								label='Empresas'
								defaultValue=''
								helperText={validacao.mensagem}
								error={!validacao.valido}
								variant={variant}
								onSelect={() => handleValidarEmpresa()}
								size='small' />
						}
					/>
				</FormGrid>
				<FormGrid size={{ xs: 2 }}>
					<Button
						color='success'
						disableElevation
						variant='contained'
						onClick={() => handle()}
						size='large'
						sx={{ mt: .5, ml: .5 }}
						startIcon={<Icon>add</Icon>}>
						<Typography variant='button' whiteSpace='nowrap' textOverflow='ellipsis' overflow='hidden'>
							Adicionar
						</Typography>
					</Button>
				</FormGrid>
			</LineGrid>
			<Grid size={12} container>
				<List
					sx={{ bgcolor: 'background.paper', borderRadius: 2, overflow: 'hidden', width: '100%' }}
					component='nav'
					aria-labelledby='lista-tipologia'
					subheader={
						<ListSubheader sx={{ px: 1, borderRadius: 2, width: '100%' }} id='lista-empresa'>
							Empresas
						</ListSubheader>
					}
					dense>
					{
						empresasSelecionadas
							?.filter(empresa => empresa.situacaoID == ESituacao.ATIVO)
							.orderBy<Empresa>('razaoSocial')
							.map((empresa: Empresa) => {
								const { empresaID, razaoSocial, cpfcnpj } = empresa;
								return (
									<ListItem
										key={empresaID}
										sx={{ px: 1 }}>
										<LineGrid
											display='flex'
											flexDirection='row'
											justifyContent='center'
											alignItems='center'
											boxShadow='2px 2px 2px 1px rgba(0, 0, 0, 0.2)'
											borderRadius={5}
											bgcolor={INFO}
											py={1}
										>
											<Grid size={2} display='flex' alignItems='center'>
												<Typography paddingLeft={2} color={BRANCO}>
													{cpfcnpj.formatarCPFOuCNPJ()}
												</Typography>
											</Grid>
											<Grid size={7} display='flex' alignItems='center'>
												<Typography paddingLeft={2} color={BRANCO}>
													{razaoSocial}
												</Typography>
											</Grid>
											<Grid size={3}>
												<SelecionarPerfil perfil={empresa.perfils?.first()} perfils={perfils} handlePerfilSelecionado={(perfil: Perfil) => empresa.perfil = perfil} />
											</Grid>
											<Grid size={.5} display='flex' alignSelf='center' justifyContent='center'>
												<DefaultButtonOnlyIcon icon='close' color={BRANCO} onClick={() => handleRemoveEmpresa(empresa)} />
											</Grid>
										</LineGrid>
									</ListItem>
								);
							})
					}
				</List>
			</Grid>
		</Grid>
	);
};

interface ISelecionarPerfil {
	handlePerfilSelecionado: (perfil: Perfil) => void;
	perfils: Perfil[];
	perfil: Perfil | undefined;
}

const SelecionarPerfil: FC<ISelecionarPerfil> = ({ handlePerfilSelecionado, perfils, perfil }) => {
	const [perfilSelecionado, setPerfilSelecionado] = useState<Perfil | undefined>(perfil);

	const handleSelected = (perfilId: any) => {
		const perfil = perfils.find(perfil => perfil.perfilID == perfilId);
		setPerfilSelecionado(perfil);
		handlePerfilSelecionado(perfil!);
	};

	useEffect(() => {
		if (perfil)
			setPerfilSelecionado(perfil);
	}, [perfil]);

	return (
		<TextField
			id='perfil'
			name='perfil'
			type='number'
			placeholder='Perfil'
			autoComplete='Perfil'
			required
			value={perfilSelecionado?.perfilID}
			onChange={(event) => handleSelected(event.target.value)}
			label='Perfil'
			select
			slotProps={{
				select: {
					native: true,
				},
			}}
			variant={variant}
			sx={sxSelectFormText}
			size='small'
		>
			{perfils.map(({ perfilID, nome }) =>
				<option key={perfilID} value={perfilID}>
					<Typography color={BRANCO} sx={{ marginX: 5 }}>{nome}</Typography>
				</option>
			)}
		</TextField>
	);
};