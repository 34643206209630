import React, { useCallback, useEffect, useState } from 'react';
import {
	Paper,
	Box,
	Typography,
	Icon,
	Button,
	TableContainer,
	Table,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
	FormControlLabel,
	Checkbox
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import { LayoutBaseDePagina } from 'shared/layouts';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDebounce } from 'shared/hooks';
import { Modulo, Perfil } from 'models';
import { PerfilService } from 'shared/services/Perfils/PerfilsService';
import { useAuthContext, useEspera, useMensagem } from 'contexts';
import { combineLatest, from, of, switchMap, take, tap, throwError } from 'rxjs';

import { ToastErro, ToastSucesso } from 'utils/toast';
import { FormGrid } from 'shared/components';
import { PermissaoService } from 'shared/services/Permissoes/PermissoesService';
import { Permissao } from 'models/Permissao';
import { DefaultButtonWithIcon } from 'shared/components/buttons';
import { ATENCAO, BRANCO, CINZA, ERRO, GRAFITE, INFO, SUCESSO, VERDE_IAGRO } from 'styles';
import { ModuloService } from 'shared/services/Modulos/ModulosService';
import { PerfilRequest } from 'requests';
import { PerfilModuloPermissoesRequest } from 'requests/PerfilModuloPermissoesRequest';

const permissoesIcones: any = {
	'TODAS': { Icon: 'check_circle', color: SUCESSO },
	'PESQUISAR': { Icon: 'search', color: GRAFITE },
	'CADASTRAR': { Icon: 'add', color: VERDE_IAGRO },
	'VISUALIZAR': { Icon: 'visibility', color: INFO },
	'ALTERAR': { Icon: 'edit', color: ATENCAO },
	'CANCELAR': { Icon: 'close', color: ERRO },
	'DOWNLOAD': { Icon: 'download', color: GRAFITE },
};

interface IModulo {
	[key: string]: IPermissao
}

interface IPermissao {
	[key: string]: boolean;
}

interface IModuloPermissao {
	permissaoID: number;
}

interface IPerfilModulo {
	moduloID: number;
	permissoes: IModuloPermissao[];
}

export const PermissoesPerfil: React.FC = () => {
	const { state } = useLocation();
	const { perfilID } = state;

	const { BloquearTela, DesbloquearTela } = useEspera();

	const [perfil, setPerfil] = useState<Partial<Perfil>>({ modulos: [] as Modulo[], situacaoID: 1 });
	const navigate = useNavigate();
	const [permissoes, setPermissoes] = useState<Permissao[]>([]);
	const [modulos, setModulos] = useState<Modulo[]>([]);
	const [permissionsState, setPermissionsState] = useState<any>({});
	const { Informacao } = useMensagem();
	const { getModuloesByEmterpriseAndUser } = useAuthContext();

	useEffect(() => {
		BloquearTela();
		combineLatest({
			permissoes: from(PermissaoService.getAll())
				.pipe(
					switchMap((resposta) => {
						if (resposta instanceof Error) return throwError(() => resposta.message);
						return of(resposta.map(permissao => new Permissao(permissao)));
					})
				),
			modulos: from(ModuloService.getAll())
				.pipe(
					switchMap((resposta) => {
						if (resposta instanceof Error) return throwError(() => resposta.message);
						return of(resposta.map(modulo => new Modulo(modulo)));
					})
				),
			perfil: from(PerfilService.getModulePermition(perfilID))
				.pipe(
					switchMap((resposta) => {
						if (resposta instanceof Error) return throwError(() => resposta.message);
						return of(resposta);
					})
				)
		}).pipe(
			take(1),
			tap(() => DesbloquearTela())
		)
			.subscribe({
				next: ({ permissoes, modulos, perfil }) => {
					setPermissoes(permissoes);
					setModulos(modulos);
					setPerfil(perfil);
					setPermissionsState(() =>
						modulos
							?.reduce((final, { nome, ...modulo }: Modulo) => {
								const moduloPermissao = perfil?.modulos?.find(({ moduloID }: Modulo) => moduloID == modulo.moduloID)?.permissoes || [];
								final[nome] = permissoes
									.reduce((finalPermissao, { nome }) => {
										finalPermissao[nome] = moduloPermissao.some((permissao) => permissao.nome === nome);
										return finalPermissao;
									}, {} as IPermissao);
								return final;
							}, {} as IModulo)
					);
				},
				error: (erro) => ToastErro(erro),
			});
	}, [perfilID]);

	const handlePermissionChange = (module: any, permission: string) => {
		setPermissionsState((prevState: any) => {
			const newState: any = { ...prevState };
			newState[module][permission] = !prevState[module][permission];

			if (permission === 'TODAS') {
				Object.keys(newState[module]).forEach((perm) => {
					newState[module][perm] = newState[module]['TODAS'];
				});
			} else {
				newState[module]['TODAS'] = Object.values(newState[module]).every((val) => val);
			}
			return { ...newState };
		});
	};

	const handleSave = () => {
		const finalModulos: IPerfilModulo[] = [];
		for (const modulo of modulos) {
			let command: IPerfilModulo | undefined = finalModulos.find(({ moduloID }) => moduloID == modulo.moduloID);
			if (!command) {
				command = { moduloID: modulo.moduloID, permissoes: [] };
				finalModulos.push(command);
			}
			for (const { permissaoID, nome } of permissoes) {
				if (permissionsState[modulo.nome]?.[nome])
					command.permissoes.push({ permissaoID: permissaoID });
			}
		}
		Informacao(
			null,
			() => <Typography display='flex' justifyContent='center' alignItems='center' flexDirection='column'>
				Deseja prosseguir com a alteração das permissões?
				<Typography fontWeight={600} fontSize={13} fontStyle='italic'>* Antes de confirmar, revise os dados informados.</Typography>
			</Typography>,
			[
				{
					label: 'Sim',
					onPress: () => {
						const novasPermissoes = new PerfilModuloPermissoesRequest({
							perfilID: perfilID,
							modulos: finalModulos
						});

						BloquearTela();
						from(PerfilService.updatePermissions(novasPermissoes))
							.pipe(
								switchMap((resposta) => {
									if (resposta instanceof Error)
										return throwError(() => resposta.message);
									return of(resposta);
								}),
								tap(() => getModuloesByEmterpriseAndUser()),
								tap(() => DesbloquearTela())
							)
							.subscribe({
								next: () => ToastSucesso(`Permissões do perfil alterada com sucesso`),
								error: (erro) => ToastErro(erro),
								complete: () => navigate(-1)
							});
					}
				},
				{
					label: 'Não',
					fechar: true
				}
			]
		);
	};

	return (
		<LayoutBaseDePagina>
			<Box padding={1} display='flex' justifyContent='center' alignItems='center' sx={{ backgroundColor: '#D3D3D3' }}>
				<Grid
					container
					spacing={1}
					component={Paper}
					elevation={0}
					sx={{ flexGrow: 1 }}
					display='flex'
					flexDirection='column'
					justifyContent='center'
					alignItems='center'
					padding={2}
				>
					<Grid
						size={12}
						display='flex'
						flexDirection='row'
						justifyContent='center'
						alignItems='center'
						sx={{ mb: 3 }}
					>
						<Typography variant='h4'>
							Gerenciamento de Permissões - {perfil?.nome || 'Carregando...'}
						</Typography>
					</Grid>
					<Grid size={9} container spacing={3} component='form' sx={{ flexGrow: 1 }}>
						<TableContainer component={Paper}>
							<Table>
								<TableHead>
									<TableRow>
										<TableCell>Módulo</TableCell>
										{permissoes.map(({ nome, permissaoID }) => (
											<TableCell key={permissaoID} align='center'>{nome}</TableCell>
										))}
									</TableRow>
								</TableHead>
								<TableBody>
									{modulos.map((modulo: Modulo) => (
										<TableRow key={modulo.nome}>
											<TableCell>{modulo.nome}</TableCell>
											{permissoes.map(({ nome, permissaoID }) => {
												const { Icon, color } = permissoesIcones[nome];
												return (
													<TableCell
														key={permissaoID}
														align='center'>
														<DefaultButtonWithIcon
															icon={Icon}
															color={BRANCO}
															tooltip={{ title: nome.toLowerCase() }}
															bgColor={permissionsState[modulo.nome]?.[nome] ? color : CINZA.opacidade(50)}
															onClick={() => handlePermissionChange(modulo.nome, nome)}
														/>
													</TableCell>
												);
											})}
										</TableRow>
									))}
								</TableBody>
							</Table>
						</TableContainer>
					</Grid>
				</Grid>
			</Box>
			<Grid container sx={{ padding: '1rem', justifyContent: 'space-between', alignSelf: 'end' }}>
				<Button
					color='info'
					disableElevation
					variant='contained'
					onClick={() => navigate(-1)}
					startIcon={<Icon>arrow_back</Icon>} >
					<Typography variant='button' whiteSpace='nowrap' textOverflow='ellipsis' overflow='hidden'>
						Voltar
					</Typography>
				</Button>
				<Button
					color='success'
					disableElevation
					variant='contained'
					onClick={() => handleSave()}
					startIcon={<Icon>save</Icon>} >
					<Typography variant='button' whiteSpace='nowrap' textOverflow='ellipsis' overflow='hidden'>
						salvar
					</Typography>
				</Button>
			</Grid>
		</LayoutBaseDePagina >
	);
};
