import { Routes, Route, Navigate } from 'react-router-dom';

import {
	DetalheDeDocumentos,
	ListagemDeDocumentos,
	DocumentosAvulsos,
	ListagemDeDocumentosProcessos,
	ListagemDeDocumentosAvulsosDetalhado,
	ChatIA,

	Empresas,
	CadastrarEmpresa,
	VisualizarEmpresa,
	AlterarEmpresa,
	Tipologias,
	CadastrarTipologia,
	VisualizarTipologia,
	AlterarTipologia,
	Setores,
	CadastrarSetor,
	VisualizarSetor,
	AlterarSetor,
	Usuarios,
	VincularEmpresaUsuario,
	VisualizarUsuario,
	Perfils,
	VisualizarPerfil,
	CadastrarPerfil,
	AlterarPerfil,
	PermissoesPerfil
} from 'pages';
import { SDEMenu } from 'shared/components/menu/Sidebar';

export const AppRoutes = () => {
	return (
		<>
			<SDEMenu>
				<Routes>
					<Route path='pesquisar' element={<ListagemDeDocumentos />} />
					<Route path='incluir/detalhe' element={<DetalheDeDocumentos />} />
					<Route path='documentosAvulsos' element={<DocumentosAvulsos />} />
					<Route path='listardocumentosprocessos' element={<ListagemDeDocumentosProcessos />} />
					<Route path='listardocumentosavulsosDetalhado/:id' element={<ListagemDeDocumentosAvulsosDetalhado />} />
					<Route path='chatia' element={<ChatIA />} />

					<Route path='empresa' element={<Empresas />} />
					<Route path='cadastrarEmpresa' element={<CadastrarEmpresa />} />
					<Route path='visualizarEmpresa' element={<VisualizarEmpresa />} />
					<Route path='alterarEmpresa' element={<AlterarEmpresa />} />

					<Route path='tipologia' element={<Tipologias />} />
					<Route path='cadastrarTipologia' element={<CadastrarTipologia />} />
					<Route path='visualizarTipologia' element={<VisualizarTipologia />} />
					<Route path='alterarTipologia' element={<AlterarTipologia />} />

					<Route path='setor' element={<Setores />} />
					<Route path='cadastrarSetor' element={<CadastrarSetor />} />
					<Route path='visualizarSetor' element={<VisualizarSetor />} />
					<Route path='alterarSetor' element={<AlterarSetor />} />

					<Route path='usuario' element={<Usuarios />} />
					<Route path='vincularEmpresa' element={<VincularEmpresaUsuario />} />
					<Route path='visualizarUsuario' element={<VisualizarUsuario />} />

					<Route path='perfil' element={<Perfils />} />
					<Route path='cadastrarPerfil' element={<CadastrarPerfil />} />
					<Route path='alterarPerfil' element={<AlterarPerfil />} />
					<Route path='visualizarPerfil' element={<VisualizarPerfil />} />
					<Route path='permissoesPerfil' element={<PermissoesPerfil />} />

					<Route path='*' element={<Navigate to='pesquisar' />} />
				</Routes>
			</SDEMenu>
		</>
	);
};
