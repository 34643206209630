import { forwardRef, useEffect, useState } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { Box, Button, css, Fade, FormControl, Icon, InputLabel, LinearProgress, MenuItem, Modal, Paper, Select, Stack, styled, Typography } from '@mui/material';
import { Form, useForm } from 'shared/forms';
import { useDebounce } from 'shared/hooks';
import { IListagemTipologia, TipologiaService } from 'shared/services/Tipologias/TipologiasService';
import { useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Grid2';

import FileUpload from './FileUpload';
import { useEmpresaSelecionadaContext } from 'contexts';
import clsx from 'clsx';

interface IFormData {
	tipologiaId: string;
	documentoNome: FileList; // File or FileList
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		paper: {
			position: 'absolute',
			width: 600,
			backgroundColor: theme.palette.background.paper,
			border: '2px solid #000',
			boxShadow: theme.shadows[5],
			padding: theme.spacing(2, 4, 3),
		},
	}),
);

export const ModalIncluirDocumentoAvulso: React.FC = () => {
	const classes = useStyles();
	const [open, setOpen] = useState(false);
	const { formRef, save } = useForm();
	const { debounce } = useDebounce(50, false);
	const [isLoadingTipologias, setIsLoadingTipologias] = useState(true);
	const [isUpdatingFiles, setIsUpdatingFiles] = useState(true);
	const [rowsTipologias, setRowsTipologias] = useState<IListagemTipologia[]>([]);
	const [tipologiaId, setTipologiaId] = useState<string | undefined>('');
	const [carregaFilePond, setCarregaFilePond] = useState(false);
	const [somenteleitura, setSomenteLeitura] = useState(false);
	const [aoMenosUmDocumentoCarregado, setAoMenosUmDocumentoCarregado] = useState(false);
	const navigate = useNavigate();
	const { empresaSelecionada } = useEmpresaSelecionadaContext();

	const handleTipologiaChange = (dado: number | unknown) => {
		setCarregaFilePond(false);
		setSomenteLeitura(false);
		if (!dado)
			return;
		setTipologiaId(dado.toString());
		setSomenteLeitura(true);
		setCarregaFilePond(true);
	};

	const handleOpen = () => {
		setOpen(true);
		setIsUpdatingFiles(false);
		setAoMenosUmDocumentoCarregado(false);
	};

	const handleClose = () => {
		setSomenteLeitura(false);
		setCarregaFilePond(false);
		setOpen(false);
	};

	useEffect(() => {
		if (!empresaSelecionada)
			return;
		if (isLoadingTipologias) {
			TipologiaService.getAll(empresaSelecionada.empresaID)
				.then((result) => {
					setIsLoadingTipologias(false);
					if (result instanceof Error) {
						alert(result.message);
						return;
					} else {
						setRowsTipologias(result.data);
					}
				});
		}
	}, [empresaSelecionada]);

	const handleSave = () => {
		setSomenteLeitura(false);
		setCarregaFilePond(false);

		navigate(`/documentosAvulsos`, { state: { pagina: 1, tipologiaId } });

		setOpen(false);
	};

	return (
		<>
			<Box flex={1}>
				<Button
					color='info'
					disableElevation
					variant='contained'
					onClick={handleOpen}
					startIcon={<Icon>add</Icon>}
				>Novo</Button>
			</Box>
			<Modal
				aria-labelledby='keep-mounted-modal-title'
				aria-describedby='keep-mounted-modal-description'
				open={open}
				onClose={handleClose}
				slots={{ backdrop: StyledBackdrop }}
				sx={{
					position: 'fixed',
					zIndex: 1300,
					right: 0,
					bottom: 0,
					top: 0,
					left: 0,
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					'&.base-Modal-hidden': {
						visibility: 'hidden'
					}
				}}
				keepMounted>
				<Fade in={open}>
					<ModalContent sx={{ width: 500 }}>
						<Box margin={1} display='flex' flexDirection='column' component={Paper} variant='outlined'>
							<Grid container direction='column' padding={2} spacing={2}>

								{isLoadingTipologias && (
									<Grid>
										<LinearProgress variant='indeterminate' />
									</Grid>
								)}

								{!isLoadingTipologias && (
									<FormControl sx={{ xs: 12 }}>
										<InputLabel id='labelId_id_tipologia2'>Tipologia</InputLabel>
										<Select
											labelId='labelId_id_tipologia2'
											id='id_id_tipologia'
											name='tipologiaId'
											onChange={e => handleTipologiaChange(e.target.value)}
											required
											value={tipologiaId}
											readOnly={somenteleitura}
											label='Tipologia'>
											{rowsTipologias.map(row => (
												<MenuItem
													value={row.tipologiaID}
													key={row.tipologiaID}
												>{row.nome}</MenuItem>
											))}
										</Select>
									</FormControl>
								)}
								{carregaFilePond && (
									<FormControl fullWidth>
										<FileUpload
											tipologiaId={tipologiaId!}
											documentoEnviadoSucesso={(files: any[]) => {
												setAoMenosUmDocumentoCarregado(!!files.length);
												setIsUpdatingFiles(false);
											}}
											documentoEnviadoErro={() => setIsUpdatingFiles(false)}
											enviandoAquivo={() => setIsUpdatingFiles(true)}
										/>
									</FormControl>
								)}
							</Grid>
						</Box>

						{!isUpdatingFiles && aoMenosUmDocumentoCarregado &&
							<Stack direction={'row'} spacing={15}>
								<Box flex={1} display='flex' justifyContent='end'>
									<Button
										color='info'
										disableElevation
										variant='contained'
										onClick={handleClose}
										startIcon={<Icon>close</Icon>} >
										<Typography variant='button' whiteSpace='nowrap' textOverflow='ellipsis' overflow='hidden'>
											Fechar
										</Typography>
									</Button>
								</Box>
								<Box flex={1} display='flex' justifyContent='start'>
									<Button
										color='success'
										disableElevation
										variant='contained'
										onClick={() => handleSave()}
										startIcon={<Icon>check</Icon>} >
										<Typography variant='button' whiteSpace='nowrap' textOverflow='ellipsis' overflow='hidden'>
											OK
										</Typography>
									</Button>
								</Box>
							</Stack>
						}
					</ModalContent>
				</Fade>
			</Modal>
		</>
	);
};

const Backdrop = forwardRef<
	HTMLDivElement,
	{ open?: boolean; className: string }
>((props, ref) => {
	const { open, className, ...other } = props;
	return (
		<div
			className={clsx({ 'base-Backdrop-open': open }, className)}
			ref={ref}
			{...other}
		/>
	);
});

Backdrop.displayName = 'Backdrop';

const grey = {
	50: '#F3F6F9',
	100: '#E5EAF2',
	200: '#DAE2ED',
	300: '#C7D0DD',
	400: '#B0B8C4',
	500: '#9DA8B7',
	600: '#6B7A90',
	700: '#434D5B',
	800: '#303740',
	900: '#1C2025',
};

const StyledBackdrop = styled(Backdrop)`
	z-index: -1;
	position: fixed;
	inset: 0;
	background-color: rgb(0 0 0 / 0.5);
	-webkit-tap-highlight-color: transparent;
`;

const ModalContent = styled(Box)(
	({ theme }) => css`
    font-family: 'IBM Plex Sans', sans-serif;
    font-weight: 500;
    text-align: start;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 8px;
    overflow: hidden;
    background-color: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
    border-radius: 8px;
    border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
    box-shadow: 0 4px 12px ${theme.palette.mode === 'dark' ? 'rgb(0 0 0 / 0.5)' : 'rgb(0 0 0 / 0.2)'};
    padding: 24px;
    color: ${theme.palette.mode === 'dark' ? grey[50] : grey[900]};

    & .modal-title {
		margin: 0;
		line-height: 1.5rem;
		margin-bottom: 8px;
    }

    & .modal-description {
		margin: 0;
		line-height: 1.5rem;
		font-weight: 400;
		color: ${theme.palette.mode === 'dark' ? grey[400] : grey[800]};
		margin-bottom: 4px;
    }`
);