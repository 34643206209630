import React, { FC, useState } from 'react';
import {
	Dialog,
	DialogTitle,
	DialogContent,
	IconButton,
	Box,
	Grid2 as Grid,
	TextField,
	Button,
	Toolbar,
	Typography,
} from '@mui/material';
import { Document, Page, Outline, Thumbnail } from 'react-pdf';
import { pdfjs } from 'react-pdf';
import { DocumentCallback } from 'react-pdf/dist/cjs/shared/types';
import CloseIcon from '@mui/icons-material/Close';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import SearchOffIcon from '@mui/icons-material/SearchOff';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css'; // Estilos opcionais
import 'react-pdf/dist/esm/Page/TextLayer.css';
import { BRANCO, CINZA_FRIO, PRETO } from 'styles';

pdfjs.GlobalWorkerOptions.workerSrc = '/pdf.worker.min.js';

interface IPDFModal {
	base64PDF: string;
	open: boolean;
	onClose: () => void;
}

export const PDFModal: FC<IPDFModal> = ({ base64PDF, open, onClose }) => {
	const [numPages, setNumPages] = useState<number>(0);
	const [pageNumber, setPageNumber] = useState(1);
	const [scale, setScale] = useState(1.1);
	const [inputPage, setInputPage] = useState('');

	const onDocumentLoadSuccess = ({ numPages }: DocumentCallback) => {
		setNumPages(numPages);
	};

	const handlePageChange = (newPageNumber: number) => {
		if (newPageNumber >= 1 && newPageNumber <= numPages) {
			setPageNumber(newPageNumber);
			scrollToThumbnail(newPageNumber);
		}
	};

	const handleZoomIn = () => {
		setScale((prevScale) => Math.min(prevScale + 0.25, 3));
	};

	const handleZoomOut = () => {
		setScale((prevScale) => Math.max(prevScale - 0.25, 0.5));
	};

	const handleZoomReset = () => {
		setScale(1.1);
	};

	const scrollToThumbnail = (pageNumber: number) => {
		const thumbnailElement = document.getElementById(`thumbnail_${pageNumber}`);
		if (thumbnailElement) {
			thumbnailElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
		}
	};

	if (!base64PDF)
		return null;

	return (
		<Dialog open={open} onClose={onClose} maxWidth='lg' fullWidth>
			<DialogTitle>
				<Toolbar
					sx={{
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'space-around'
					}}
				>
					<Box>
						<Typography variant='h6' sx={{ flexGrow: 1 }}>
							Visualizar PDF
						</Typography>
					</Box>
					<Box
						flex={1}
						display='flex'
						flexDirection='row'
						justifyContent='center'
						alignItems='center'
					>

						<Typography variant='body1' fontWeight={500}>
							Página
						</Typography>
						<TextField
							type='number'
							value={inputPage}
							onChange={(e) => {
								let valor = +e.target.value;
								if (valor < 1)
									valor = 1;
								if (valor > numPages)
									valor = numPages;
								setInputPage(valor.toString());
								handlePageChange(valor);
							}}
							size='small'
							label='Página'
							variant='outlined'
							sx={{ width: '75px', marginX: 1, textAlign: 'center' }}
						/>
						<Typography variant='body1' fontWeight={500}>
							de {numPages}
						</Typography>
						<IconButton onClick={handleZoomOut} aria-label='zoom out'>
							<ZoomOutIcon />
						</IconButton>
						<IconButton onClick={handleZoomIn} aria-label='zoom in'>
							<ZoomInIcon />
						</IconButton>
						<IconButton onClick={handleZoomReset} aria-label='zoom reset'>
							<SearchOffIcon />
						</IconButton>
					</Box>
					<IconButton aria-label='close' onClick={onClose}>
						<CloseIcon />
					</IconButton>
				</Toolbar>
			</DialogTitle>
			<DialogContent>
				<Grid size={12} display='flex' flexDirection='row'>
					<Box flex={.18}>
						<Box sx={{ overflowY: 'auto', height: '80vh' }}>
							<Document
								file={`data:application/pdf;base64,${base64PDF}`}
								onLoadSuccess={onDocumentLoadSuccess}
							>
								<Outline onItemClick={({ pageNumber }) => handlePageChange(pageNumber)} />
							</Document>

							<Document
								file={`data:application/pdf;base64,${base64PDF}`}
								onLoadSuccess={onDocumentLoadSuccess}
							>
								{Array.from(new Array(numPages), (el, index) => (
									<Box
										key={`thumbnail_${index + 1}`}
										id={`thumbnail_${index + 1}`} // ID para rolagem
										sx={{ marginBottom: 1 }}
										display='flex'
										flexDirection='row'
										alignItems='center'
									>
										<Box
											display='flex'
											flexDirection='column'
											alignItems='center'
											width={105}
											marginLeft={3}
										>
											<Typography
												fontWeight={600}
												color={BRANCO}
												bgcolor={PRETO}
												fontSize={10}
												width={102}
												textAlign='center'
												sx={{ cursor: 'pointer' }}
											>
												Página {index + 1}
											</Typography>
											<Box
												sx={{ border: `1px solid ${PRETO}` }}>
												<Thumbnail
													pageNumber={index + 1}
													onClick={() => handlePageChange(index + 1)}
													width={100}
												/>
											</Box>
										</Box>
										{pageNumber == (index + 1) && <ArrowRightIcon fontSize='large' sx={{ position: 'relative', left: -14 }} />}
									</Box>
								))}
							</Document>
						</Box>
					</Box>

					<Box flex={1}
						height='auto'
						bgcolor={CINZA_FRIO.opacidade(40)}
						display='flex'
						flexDirection='column'
						justifyContent='center'
						alignItems='center'>
						<Document
							file={`data:application/pdf;base64,${base64PDF}`}
							onLoadSuccess={onDocumentLoadSuccess}
						>
							<Box
								display='flex'
								justifyContent='center'
								alignItems='center'
								sx={{ boxShadow: 3 }}
							>
								<Page pageNumber={pageNumber} scale={scale} />
							</Box>
						</Document>
					</Box>
				</Grid>
			</DialogContent>
		</Dialog>
	);
};