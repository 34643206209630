import { FC, ReactNode } from 'react';

import { Icon, IconButton, SxProps, Theme, Tooltip } from '@mui/material';


type TTooltip = {
	title?: string;
	placement?: | 'bottom-end' | 'bottom-start' | 'bottom' | 'left-end' | 'left-start' | 'left' | 'right-end' | 'right-start' | 'right' | 'top-end' | 'top-start' | 'top'
}

interface IIconWithButton {
	children: ReactNode;
	bgColor?: string;
	color?: any;
	sx?: SxProps<Theme>;
	hide?: boolean;
	tooltip?: TTooltip;
	onlyIcon?: boolean;
	onClick?: () => void;
}

const IconWithButton: FC<IIconWithButton> = ({ children, sx, color, bgColor, hide, tooltip, onlyIcon, onClick }) => {
	if (hide)
		return null;

	if (onlyIcon)
		return (
			<Tooltip title={tooltip?.title || ''} placement={tooltip?.placement || 'top'} arrow>
				<IconButton
					onClick={() => onClick?.()}
					color={color || '#ffffff'}
					sx={{
						opacity: .8,
						'&:hover': {
							opacity: 1,
						},
						borderRadius: 3,
						color: (color || '#ffffff'),
						marginX: .15,
						...(sx || {})
					}}>
					{children}
				</IconButton>
			</Tooltip>
		);

	return (
		<Tooltip title={tooltip?.title || ''} placement={tooltip?.placement || 'top'} arrow>
			<IconButton
				onClick={() => onClick?.()}
				color={color || '#ffffff'}
				sx={{
					opacity: .8,
					'&:hover': {
						opacity: 1,
						backgroundColor: (bgColor || '#c3c3c3'),
					},
					backgroundColor: (bgColor || '#c3c3c3'),
					borderRadius: 2,
					color: (color || '#ffffff'),
					marginX: .15,
					padding: .7,
					'.material-icons.notranslate.MuiIcon-root.MuiIcon-fontSizeMedium': {
						fontSize: 18,
					},
					...(sx || {})
				}}>
				{children}
			</IconButton>
		</Tooltip>
	);
};

interface IButtonWithIcon {
	bgColor?: string;
	color?: any;
	sx?: SxProps<Theme>;
	icon?: string;
	hide?: boolean;
	tooltip?: TTooltip;
	sizeIcon?: number;
	onClick: () => void;
}

export const DeleteButtonWithIcon: FC<IButtonWithIcon> =
	({ bgColor, color, sx, icon, sizeIcon, hide, tooltip, onClick }) =>
		<IconWithButton
			bgColor={bgColor || '#d32f2f'}
			onClick={onClick}
			color={(color || '#ffffff')}
			hide={hide}
			tooltip={{ title: 'Deletar', ...(tooltip || {}) }}
			sx={sx}>
			<Icon sx={{ fontSize: (sizeIcon || '1.5rem') }}>{icon || 'delete'}</Icon>
		</IconWithButton>;

export const AlterButtonWithIcon: FC<IButtonWithIcon> =
	({ bgColor, color, sx, icon, sizeIcon, hide, tooltip, onClick }) =>
		<IconWithButton
			bgColor={bgColor || '#d9a40d'}
			onClick={onClick}
			color={(color || '#ffffff')}
			hide={hide}
			tooltip={{ title: 'Alterar', ...(tooltip || {}) }}
			sx={sx}>
			<Icon sx={{ fontSize: (sizeIcon || '1.5rem') }}>{icon || 'edit'}</Icon>
		</IconWithButton>;

export const ViewButtonWithIcon: FC<IButtonWithIcon> =
	({ bgColor, color, sx, icon, sizeIcon, hide, tooltip, onClick }) =>
		<IconWithButton
			bgColor={bgColor || '#0288d1'}
			onClick={onClick}
			color={(color || '#ffffff')}
			hide={hide}
			tooltip={{ title: 'Visualizar', ...(tooltip || {}) }}
			sx={sx}>
			<Icon sx={{ fontSize: (sizeIcon || '1.5rem') }}>{icon || 'visibility'}</Icon>
		</IconWithButton>;

export const CancelButtonWithIcon: FC<IButtonWithIcon> =
	({ bgColor, color, sx, icon, sizeIcon, hide, tooltip, onClick }) =>
		<IconWithButton
			bgColor={bgColor || '#d32f2f'}
			onClick={onClick}
			color={(color || '#ffffff')}
			hide={hide}
			tooltip={{ title: 'Cancelar', ...(tooltip || {}) }}
			sx={sx}>
			<Icon sx={{ fontSize: (sizeIcon || '1.5rem') }}>{icon || 'close'}</Icon>
		</IconWithButton>;

export const DefaultButtonWithIcon: FC<IButtonWithIcon> =
	({ bgColor, color, sx, icon, sizeIcon, hide, tooltip, onClick }) =>
		<IconWithButton
			bgColor={bgColor || '#D3D3D3'}
			onClick={onClick}
			color={(color || '#000')}
			hide={hide}
			tooltip={tooltip}
			sx={sx}>
			<Icon sx={{ fontSize: (sizeIcon || '1.5rem') }}>{icon || ''}</Icon>
		</IconWithButton>;

export const DefaultButtonOnlyIcon: FC<IButtonWithIcon> =
	({ color, sx, icon, sizeIcon, hide, tooltip, onClick }) =>
		<IconWithButton
			onClick={onClick}
			color={(color || '#000')}
			hide={hide}
			tooltip={tooltip}
			onlyIcon
			sx={sx}>
			<Icon sx={{ fontSize: (sizeIcon || '1.5rem') }}>{icon || ''}</Icon>
		</IconWithButton>;