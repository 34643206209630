import { Box, Button, Icon, Autocomplete, CircularProgress, Skeleton, TextField, Typography, useTheme } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import { ModalIncluirDocumentoAvulso } from 'pages/modal-incluir/ModalIncluirDocumentoAvulso';
import { useDebounce } from '../../hooks';
import { useEffect, useMemo, useState } from 'react';
import { TipologiaService } from '../../services/Tipologias/TipologiasService';
import { from, of, switchMap } from 'rxjs';
import { DateRangePicker } from '../dateRangePicker';
import { Moment } from 'moment';

interface IFerramentasDaListagemProps {
	textoDaBusca?: string;
	mostrarInputBusca?: boolean;
	mostrarInputBuscaPorData?: boolean;
	aoMudarTextoDeBusca?: (novoTexto: string) => void;
	aoMudarDatas?: (dataStart: Moment | null, dataEnd: Moment | null) => void;
	mostrarBotaoVoltar?: boolean;
	mostrarBotaoVoltarCarregando?: boolean;
	aoClicarEmVoltar?: () => void;
	textoBotaoNovo?: string;
	mostrarBotaoNovo?: boolean;
	mostrarBotaoNovoModal?: boolean;
	mostrarBotaoPagina?: boolean;
	qtdePaginas?: number;
	onSelectPagina?: (pagina: any) => void;
	aoClicarEmNovo?: () => void;
	textoDaBuscaIndexado?: string;
	mostrarInputBuscaIndexado?: boolean;
	aoMudarTextoDeBuscaIndexado?: (novoTextoIndexado: string) => void;
	isExternalLoading?: boolean;
	tipologiaBuscaIndexado?: string;
	padding?: number;
	marginX?: number;
}

type TAutoCompleteOption = {
	id: number;
	label: string;
}

export const FerramentasDaListagem: React.FC<IFerramentasDaListagemProps> = ({
	textoDaBusca = '',
	mostrarInputBusca = false,
	mostrarInputBuscaPorData = false,
	aoMudarTextoDeBusca,
	aoMudarDatas = (dataStart: Moment | null, dataEnd: Moment | null) => { },
	aoClicarEmNovo,
	textoBotaoNovo = 'Novo',
	mostrarBotaoNovo = true,
	mostrarBotaoVoltar = true,
	mostrarBotaoVoltarCarregando = false,
	aoClicarEmVoltar,
	mostrarBotaoNovoModal = false,
	mostrarBotaoPagina = false,
	qtdePaginas = 1,
	onSelectPagina,
	textoDaBuscaIndexado = '',
	mostrarInputBuscaIndexado = false,
	aoMudarTextoDeBuscaIndexado,
	isExternalLoading = false,
	tipologiaBuscaIndexado = '',
	padding = 2,
	marginX = 1
}) => {
	const theme = useTheme();
	const [selectedId, setSelectedId] = useState<number | undefined>(1);

	const [opcoes, setOpcoes] = useState<TAutoCompleteOption[]>([]);
	const [isLoading, setIsLoading] = useState(false);
	const [busca, setBusca] = useState('');

	useEffect(() => {
		setIsLoading(true);

		of(true)
			.pipe(
				switchMap(() => {
					if (!tipologiaBuscaIndexado)
						return from(TipologiaService.getAllFiels())
							.pipe(
								switchMap((tipologiasCampos) => {
									setIsLoading(false);
									if (tipologiasCampos instanceof Error)
										throw tipologiasCampos;
									return of(tipologiasCampos.map(campos => ({ id: Number(campos.tipologiaID), label: campos.titulo })));
								})
							);
					else
						return from(TipologiaService.getFields(tipologiaBuscaIndexado))
							.pipe(
								switchMap((tipologiasCampos) => {
									setIsLoading(false);
									if (tipologiasCampos instanceof Error)
										throw tipologiasCampos;
									return of(tipologiasCampos.map(campos => ({ id: Number(campos.tipologiaID), label: campos.titulo })));
								})
							);
				})
			).subscribe({
				next: (tipologiasCampos) => setOpcoes(tipologiasCampos),
				error: (error) => alert(error.message)
			});
		/*
		registerField({
			name: fieldName,
			getValue: () => selectedId,
			setValue: (_, newSelectedId) => setSelectedId(newSelectedId),
		});
		*/
	}, [busca, tipologiaBuscaIndexado]);

	const autoCompleteSelectedOption = useMemo(() => {
		if (!selectedId) return null;

		const selectedOption = opcoes.find(opcao => opcao.id === selectedId);
		if (!selectedOption) return null;

		return selectedOption;
	}, [selectedId, opcoes]);


	const paginas = useMemo(() => {
		return Array.from({ length: qtdePaginas }, (pagina, index) => ({ value: index + 1, text: `Pagina ${index + 1}` }));
	}, [qtdePaginas]);

	return (
		<Box
			gap={1}
			marginX={marginX}
			padding={padding}
			display='flex'
			justifyContent='center'
			alignItems='center'
			height={theme.spacing(5)}
		/* component={Paper} */
		>
			{(mostrarBotaoVoltar && !mostrarBotaoVoltarCarregando) && (
				<Button
					color='info'
					disableElevation
					variant='outlined'
					onClick={aoClicarEmVoltar}
					startIcon={<Icon>arrow_back</Icon>}
				>
					<Typography variant='button' whiteSpace='nowrap' textOverflow='ellipsis' overflow='hidden'>
						Voltar
					</Typography>
				</Button>
			)}

			{mostrarBotaoVoltarCarregando && (
				<Skeleton width={110} height={60} />
			)}
			{mostrarInputBusca && (
				<TextField
					size='small'
					id='searchfield'
					name='searchfield'
					// value={textoDaBusca}
					// onChange={(e) => aoMudarTextoDeBusca?.(e.target.value)}
					onKeyDown={(e) => (e.keyCode == 13 ? aoMudarTextoDeBusca?.((document.getElementById('searchfield') as HTMLInputElement).value) : null)}
					placeholder={process.env.REACT_APP_INPUT_DE_BUSCA}
					sx={{ width: 600 }}
					InputProps={{
						endAdornment: (
							<InputAdornment position='start'>
								<IconButton onClick={() => aoMudarTextoDeBusca?.((document.getElementById('searchfield') as HTMLInputElement).value)}>
									<SearchIcon />
								</IconButton>
							</InputAdornment>
						)
					}} />
			)}
			{
				mostrarInputBuscaPorData &&
				<DateRangePicker value={null} onChange={(dataStart, dataEnd) => aoMudarDatas(dataStart, dataEnd)} />
			}
			{mostrarInputBuscaIndexado && (
				<Autocomplete
					openText='Abrir'
					closeText='Fechar'
					noOptionsText='Sem opções'
					loadingText='Carregando...'
					sx={{ width: 250 }}

					disablePortal

					options={opcoes}
					loading={isLoading}
					disabled={isExternalLoading}
					value={autoCompleteSelectedOption}
					onInputChange={(_, newValue) => setBusca(newValue)}
					onChange={(_, newValue) => { setSelectedId(newValue?.id); setBusca(''); }}
					popupIcon={(isExternalLoading || isLoading) ? <CircularProgress size={28} /> : undefined}
					renderInput={(params) => (
						<TextField
							{...params}

							label='Campo Indexador'
						/>
					)}
				/>
			)}
			{mostrarInputBuscaIndexado && (
				<TextField
					size='small'
					id='searchfield'
					name='searchfield'
					// value={textoDaBuscaIndexado}
					// onChange={(e) => aoMudarTextoDeBuscaIndexado?.(e.target.value)}
					onKeyDown={(e) => (e.keyCode == 13 ? aoMudarTextoDeBuscaIndexado?.((document.getElementById('searchfield') as HTMLInputElement).value) : null)}
					placeholder='Procurar no campo indexado...'
					sx={{ width: 400 }}
					InputProps={{
						endAdornment: (
							<InputAdornment position='start'>
								<IconButton onClick={() => aoMudarTextoDeBuscaIndexado?.((document.getElementById('searchfield') as HTMLInputElement).value)}>
									<SearchIcon />
								</IconButton>
							</InputAdornment>
						)
					}} />
			)}
			{mostrarBotaoNovo && (
				<Box flex={1}>
					<Button
						color='info'
						disableElevation
						variant='contained'
						onClick={aoClicarEmNovo}
						startIcon={<Icon>add</Icon>}
					>{textoBotaoNovo}</Button>
				</Box>
			)}
			{mostrarBotaoNovoModal && (
				<ModalIncluirDocumentoAvulso />
			)}
		</Box >
	);
};
