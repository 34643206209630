import { Perfil } from 'models';
import { ObjectToQuery } from 'utils';
import { Api } from '../Axios';
import { EOrdenacao } from 'enums';
import { PerfilRequest } from 'requests';
import { PerfilModuloPermissoesRequest } from 'requests/PerfilModuloPermissoesRequest';

export interface IListagemPerfil {
	perfilID: number;
	nome: string;
}

type TPerfilsComTotalCount = {
	data: Perfil[];
	totalCount: number;
}

type TPerfilsSomenteTotalCount = {
	totalCount: number;
}

export interface IListagemPerfilCampos {
	perfilID: string;
	fieldName: string;
	ordem: string;
	tipoID: string;
	titulo: string;
}

type TPerfilsComTotal = {
	perfils: Perfil[];
	total: number;
}

const getTotal = async (page = 1, filter = ''): Promise<TPerfilsSomenteTotalCount | Error> => {
	try {
		const LOCAL_STORAGE_KEY__ACCESS_TOKEN = process.env.REACT_APP_LOCAL_STORAGE_TOKEN;
		const accessToken = localStorage.getItem(LOCAL_STORAGE_KEY__ACCESS_TOKEN!) || '';

		const urlRelativa = '/Perfil/ObterQtdeTotal';
		// const urlRelativa = `/perfils/getTotal.php?accessToken=${accessToken}&_page=${page}&_limit=${parseInt(process.env.REACT_APP_LIMITE_DE_LINHAS!)}&perfil_nome_like=${filter}`;
		const { data } = await Api.get(urlRelativa);
		return {
			totalCount: Number(data.data),
		};
	} catch (error) {
		return new Error((error as { message: string }).message || 'Erro ao listar os registros.');
	}
};

const getAll = async (empresaID?: number): Promise<TPerfilsComTotalCount | Error> => {
	try {
		const params = ObjectToQuery({ empresaID });
		const urlRelativa = `/Perfil/ObterTodos?${params}`;
		const { data, headers } = await Api.get(urlRelativa);

		if (data) {
			return {
				data: data.data,
				totalCount: Number(headers['x-total-count'] || parseInt(process.env.REACT_APP_LIMITE_DE_LINHAS!)),
			};
		}
		return new Error('Erro ao listar os registros.');
	} catch (error) {
		return new Error((error as { message: string }).message || 'Erro ao listar os registros.');
	}
};

const getAllFiels = async (): Promise<IListagemPerfilCampos[] | Error> => {
	try {
		const LOCAL_STORAGE_KEY__ACCESS_TOKEN = process.env.REACT_APP_LOCAL_STORAGE_TOKEN;
		const accessToken = localStorage.getItem(LOCAL_STORAGE_KEY__ACCESS_TOKEN!) || '';

		const urlRelativa = '/PerfilCampos/ObterTodos';
		// const urlRelativa = `/perfils/getAll.php?accessToken=${accessToken}&_page=${page}&_limit=${parseInt(process.env.REACT_APP_LIMITE_DE_LINHAS!)}&perfil_nome_like=${filter}`;
		const { data, headers } = await Api.get(urlRelativa);

		if (data)
			return data.data;

		return new Error('Erro ao listar os registros.');
	} catch (error) {
		return new Error((error as { message: string }).message || 'Erro ao listar os registros.');
	}
};

const getFields = async (perfilId = '', processoId = ''): Promise<IListagemPerfilCampos[] | Error> => {
	try {
		const urlRelativa = `/PerfilCampos/ObterCamposPorPerfilId?perfilId=${perfilId}&processoId=${processoId}`;
		const { data, headers } = await Api.get(urlRelativa);

		if (data) {
			return data.data;
		}
		return new Error('Erro ao listar os registros.');
	} catch (error) {
		return new Error((error as { message: string }).message || 'Erro ao listar os registros.');
	}
};

const getWithPagination = async (pagina: number = 1, qtdLinha: number = 10, campoOrdenacao: string, ordenacaoPor: EOrdenacao = EOrdenacao.ASC, filtros: any = {}): Promise<TPerfilsComTotal | Error> => {
	try {
		const queryParams = ObjectToQuery({
			pagina: pagina,
			qtdLinha,
			ordenacaoPor,
			campoOrdenacao,
			...filtros
		});
		const urlRelativa = `/Perfil/ObterPaginado?${queryParams}`;
		const { data } = await Api.get(urlRelativa);

		if (data && data.data)
			return {
				perfils: data.data.lista?.map((perfil: any) => new Perfil(perfil)),
				total: data.data.total
			};

		return new Error('Erro ao listar os registros.');
	} catch (error) {
		return new Error((error as { message: string }).message || 'Erro ao listar os registros.');
	}
};

const getById = async (id: number): Promise<Perfil | Error> => {
	try {
		const { data } = await Api.get(`/Perfil/ObterPorId?perfilID=${id}`);

		if (data) {
			return data.data;
		}
		return new Error('Erro ao obter o registro.');
	} catch (error) {
		return new Error((error as { message: string }).message || 'Erro ao obter o registro.');
	}
};

const getByEnterpriseId = async (id: number): Promise<Perfil[] | Error> => {
	try {
		const { data } = await Api.get(`/Perfil/ObterPorId?empresaID=${id}`);

		if (data) {
			return data.data;
		}
		return new Error('Erro ao obter o registro.');
	} catch (error) {
		return new Error((error as { message: string }).message || 'Erro ao obter o registro.');
	}
};

const getModulePermition = async (id: number): Promise<Perfil | Error> => {
	try {
		const { data } = await Api.get(`/Perfil/ObterModuloPermissoes?perfilID=${id}`);

		if (data) {
			return data.data;
		}
		return new Error('Erro ao obter o registro.');
	} catch (error) {
		return new Error((error as { message: string }).message || 'Erro ao obter o registro.');
	}
};

const save = async (perfil: PerfilRequest): Promise<Perfil | Error> => {
	try {
		const urlRelativa = `/Perfil`;
		const { data } = await Api.post(urlRelativa, perfil);

		if (data) {
			if (data.data)
				return data.data;

			return new Error(data.errors.join('<br/>'));
		} else
			return new Error('Erro ao cadastrar perfil.');
	} catch (error) {
		return new Error((error as { message: string }).message || 'Erro ao autenticar!!');
	}
};

const update = async (perfil: PerfilRequest): Promise<Perfil | Error> => {
	try {
		const urlRelativa = `/Perfil/Alterar`;
		const { data } = await Api.post(urlRelativa, perfil);

		if (data) {
			if (data.data)
				return data.data;

			return new Error(data.errors.join('<br/>'));
		} else
			return new Error('Erro ao alterar perfil.');
	} catch (error) {
		return new Error((error as { message: string }).message || 'Erro ao autenticar!!');
	}
};

const inative = async (perfilID: number): Promise<boolean | Error> => {
	try {
		const urlRelativa = `/Perfil/Inativar`;
		const { data } = await Api.post(urlRelativa, { perfilID });

		if (data) {
			if (data.data)
				return data.data;

			return new Error(data.errors.join('<br/>'));
		} else
			return new Error('Erro ao inativar perfil.');
	} catch (error) {
		return new Error((error as { message: string }).message || 'Erro ao autenticar!!');
	}
};

const updatePermissions = async (novasPermissoes: PerfilModuloPermissoesRequest): Promise<boolean | Error> => {
	try {
		const urlRelativa = `/Perfil/SalvarGerenciamentoPermissoes`;
		const { data } = await Api.post(urlRelativa, novasPermissoes);

		if (data) {
			if (data.data)
				return data.data;

			return new Error(data.errors.join('<br/>'));
		} else
			return new Error('Erro ao alterar permissões do perfil.');
	} catch (error) {
		return new Error((error as { message: string }).message || 'Erro ao alterar permissões do perfil!!');
	}
};

export const PerfilService = {
	getTotal,
	getAll,
	getFields,
	getById,
	getByEnterpriseId,
	getAllFiels,
	getWithPagination,
	getModulePermition,
	save,
	update,
	inative,
	updatePermissions
};