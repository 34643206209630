import React, { useCallback, useEffect, useState } from 'react';
import {
	Paper,
	Box,
	Typography,
	Icon,
	Button
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import { LayoutBaseDePagina } from 'shared/layouts';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDebounce } from 'shared/hooks';
import { Setor } from 'models';
import { SetorService } from 'shared/services/Setores/SetoresService';
import { useEspera } from 'contexts';
import { from, of, switchMap, tap, throwError } from 'rxjs';

import { ToastErro } from 'utils/toast';
import { FormGrid } from 'shared/components';

export const VisualizarSetor: React.FC = () => {
	const { state } = useLocation();
	const { setorID } = state;

	const { BloquearTela, DesbloquearTela } = useEspera();

	const [isLoading, setIsLoading] = useState(true);
	const [setor, setSetor] = useState<Setor>();
	const { debounce } = useDebounce(300, false);
	const navigate = useNavigate();

	useEffect(() => {
		debounce(() => {
			setIsLoading(true);
			BloquearTela();
			from(SetorService.getById(setorID))
				.pipe(
					tap(() => setIsLoading(false)),
					switchMap((resposta) => {
						setIsLoading(false);
						if (resposta instanceof Error)
							return throwError(() => resposta.message);
						return of(new Setor(resposta));
					}),
					tap(() => DesbloquearTela()),
				).subscribe({
					next: (formulario) => setSetor(formulario),
					error: (erro) => ToastErro(erro)
				});
		});
	}, []);

	const DadosCadastro = useCallback(() => (
		<>
			<Grid
				size={12}
				display='flex'
				flexDirection='row'
				justifyContent='center'
				alignItems='center'
				sx={{ mb: 3 }}
			>
				<Typography variant='h4'>
					Dados Cadastro
				</Typography>
			</Grid>
			<Grid size={12} container spacing={3} component='form' sx={{ flexGrow: 1 }}>
				<FormGrid size={{ xs: 12, md: 6 }}>
					<Typography fontWeight={600}>Usuario Cadastro</Typography>
					<Typography>{setor?.usuarioNomeCadastro}</Typography>
				</FormGrid>
				<FormGrid size={{ xs: 12, md: 6 }}>
					<Typography fontWeight={600}>Data e Hora Cadastro</Typography>
					<Typography>{setor?.dataHoraCadastro
						? new Date(`${setor.dataHoraCadastro}+0000`).formatar()
						: 'Data não disponível'}</Typography>
				</FormGrid>
			</Grid>
		</>
	), [setor]);

	const DadosAlteracao = useCallback(() => {
		if (setor?.usuarioIDAlteracao)
			return (
				<>
					<Grid
						size={12}
						display='flex'
						flexDirection='row'
						justifyContent='center'
						alignItems='center'
						sx={{ mb: 3 }}
					>
						<Typography variant='h4'>
							Dados Alteração
						</Typography>
					</Grid>
					<Grid size={12} container spacing={3} component='form' sx={{ flexGrow: 1 }}>
						<FormGrid size={{ xs: 12, md: 6 }}>
							<Typography fontWeight={600}>Usuario Alteracao</Typography>
							<Typography>{setor?.usuarioNomeAlteracao}</Typography>
						</FormGrid>
						<FormGrid size={{ xs: 12, md: 6 }}>
							<Typography fontWeight={600}>Data e Hora Alteracao</Typography>
							<Typography>{setor?.dataHoraCadastro
								? new Date(`${setor.dataHoraAlteracao}+0000`).formatar()
								: 'Data não disponível'}</Typography>
						</FormGrid>
					</Grid>
				</>
			);
		return null;
	}, [setor]);

	return (
		<LayoutBaseDePagina>
			<Box padding={1} display='flex' justifyContent='center' alignItems='center' sx={{ backgroundColor: '#D3D3D3' }}>
				<Grid
					container
					spacing={1}
					component={Paper}
					elevation={0}
					sx={{ flexGrow: 1 }}
					display='flex'
					flexDirection='column'
					justifyContent='center'
					alignItems='center'
					padding={2}
				>
					<Grid
						size={12}
						display='flex'
						flexDirection='row'
						justifyContent='center'
						alignItems='center'
						sx={{ mb: 3 }}
					>
						<Typography variant='h4'>
							Visualizar Setor
						</Typography>
					</Grid>
					<Grid size={12} container spacing={3} component='form' sx={{ flexGrow: 1 }}>
						<FormGrid size={{ xs: 12, md: 4 }}>
							<Typography fontWeight={600}>Nome da Setor</Typography>
							<Typography>{setor?.nome}</Typography>
						</FormGrid>
						<DadosCadastro />
						<DadosAlteracao />
					</Grid>
				</Grid>
			</Box>
			<Grid container sx={{ padding: '1rem', justifyContent: 'space-between', alignSelf: 'end' }}>
				<Button
					color='info'
					disableElevation
					variant='contained'
					onClick={() => navigate(-1)}
					startIcon={<Icon>arrow_back</Icon>} >
					<Typography variant='button' whiteSpace='nowrap' textOverflow='ellipsis' overflow='hidden'>
						Voltar
					</Typography>
				</Button>
			</Grid>
		</LayoutBaseDePagina >
	);
};
