export type Validacao = {
	valido: boolean;
	mensagem: string | null;
};

export type ValidacaoTipologia = {
	tipologiaID?: number;
	unidadeTempoID?: Validacao;
	tempo?: Validacao;
}

export type RegrasDeValidacao<T> = {
	[K in keyof T]?: (valor: T[K]) => Validacao | ValidacaoTipologia[];
};

export class Validador<T> {
	private regras: RegrasDeValidacao<T>;

	constructor(regras: RegrasDeValidacao<T>) {
		this.regras = regras;
	}

	public validar(dados: T): Record<keyof T, Validacao> {
		let resultado: Partial<Record<keyof T, Validacao>> = {};

		for (const campo in this.regras) {
			const key = campo as Extract<keyof T, string>;
			let valor: any = dados[key];
			if (campo == 'descricao')
				valor = [valor, dados['semDescricao' as keyof T]];
			if (campo == 'matriz')
				valor = [valor, dados['ehFilial' as keyof T]];
			resultado = {
				...resultado,
				...this.validarCampo(valor, campo)
			};
		}

		return resultado as Record<keyof T, Validacao>;
	}

	public validarCampo(valor: T[Extract<keyof T, string>], campo: string): any {
		const key = campo as Extract<keyof T, string>;
		const regra = this.regras[key];

		if (regra)
			return { [campo]: regra(valor) };

		return { [campo]: { valido: true, mensagem: null } as Validacao };
	}

	public ehValido(resultado: Record<keyof T, Validacao>): boolean {
		return Object.values(resultado)
			.map(validacao => {
				if (Array.isArray(validacao))
					return { valido: validacao.reduce((final, { tempo, unidadeTempoID }) => (tempo as Validacao).valido && (unidadeTempoID as Validacao).valido && final, [true]) } as Validacao;
				return validacao;
			})
			.every((validacao) => (validacao as Validacao).valido);
	}
}