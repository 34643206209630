import { RegrasDeValidacao, Validador } from './Validador';

export type DadosFormulario = {
	nome: string;
}

const regrasDeValidacao: RegrasDeValidacao<DadosFormulario> = {
	nome: (valor: string) => {
		if (!valor) {
			return { valido: false, mensagem: 'Nome é obrigatório' };
		}
		return { valido: true, mensagem: null };
	}
};

export const ValidadorPerfil = new Validador<DadosFormulario>(regrasDeValidacao);