import React, { useCallback, useState } from 'react';
import { Autocomplete, Box, CircularProgress, FormControl, Icon, InputLabel, MenuItem, Paper, Select, SelectChangeEvent, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow, TextField, TextFieldVariants, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { useAutenticacaoContext, useAuthContext, useEmpresaSelecionadaContext, useEmpresasUsuarioContext } from 'contexts';
import { Empresa } from 'models';
import { FormGrid } from 'shared/components/grid';


interface SidebarFooterProps extends React.HTMLAttributes<HTMLDivElement> {
	children?: React.ReactNode;
	collapsed?: boolean;
}

const variant: TextFieldVariants = 'standard';

export const SidebarFooter: React.FC<SidebarFooterProps> = ({ children, collapsed, ...rest }) => {
	const { usuarioLogado, handleChangeEmpresa, empresaSelecionada, empresasUsuario } = useAuthContext();

	const primeiraEmpresa: Empresa = empresasUsuario.first();

	const EmpresaSelect = useCallback(() => {
		if (empresasUsuario?.length == 1) {
			const [empresa] = empresasUsuario;
			handleChangeEmpresa(empresa);
			return null;
		}

		return (
			<FormGrid size={{ xs: 10 }}>
				<Autocomplete
					clearOnEscape
					multiple={false}
					value={empresaSelecionada}
					inputValue={empresaSelecionada?.razaoSocial || ''}
					options={empresasUsuario}
					onChange={(event: any, empresa: Empresa | null) => handleChangeEmpresa(empresa || primeiraEmpresa)}
					getOptionLabel={(option: Empresa) => option?.razaoSocial || ''}
					getOptionDisabled={(option) => empresaSelecionada?.empresaID == option.empresaID}
					clearIcon={false}
					renderInput={(params) =>
						<TextField
							{...params}
							id='empresa'
							name='empresa'
							type='text'
							placeholder='Empresas'
							autoComplete='Empresas'
							required
							label='Empresas'
							defaultValue=''
							variant={variant}
							size='small' />
					}
				/>
			</FormGrid>
		);
	}, [empresasUsuario, empresaSelecionada]);

	return (
		<Box
			style={{
				display: 'flex',
				justifyContent: 'center',
				marginBottom: '40px',
			}}
		>
			<TableContainer component={Paper} variant='outlined' sx={{ border: 0 }}>
				<Table sx={{ border: 0 }}>
					<TableHead>
						<TableRow>
							<TableCell colSpan={2} sx={{ border: 0 }}>

							</TableCell>
						</TableRow>
					</TableHead>
					{
						!collapsed &&
						<TableBody sx={{ border: 0 }}>
							<TableRow>
								<TableCell sx={{ border: 0 }}>
									<FormControl sx={{ width: '100%' }} size='small'>
										<EmpresaSelect />
									</FormControl>
								</TableCell>
							</TableRow>
						</TableBody>
					}
					<TableFooter sx={{ border: 0 }}>
						<TableRow>
							<TableCell sx={{ border: 0 }}>
								<Box>
									<Link
										className='sidebar-btn'
										style={{
											cursor: 'pointer',
											textDecoration: 'none',
											color: 'rgba(255, 255, 255, 0.9)',
											flex: 1,
											display: 'flex',
											justifyContent: 'center',
											alignItems: 'center',
											background: '#0098e5',
											padding: !collapsed ? '5px 20px 5px 20px' : '5px',
											borderRadius: '50px',
										}}
										to='/profile'
									>
										<Icon fontSize={collapsed ? 'large' : 'medium'}>account_circle</Icon>
										{!collapsed && <Typography paddingLeft={1} variant='h6'>{usuarioLogado?.nome}</Typography>}
									</Link>
								</Box>
							</TableCell>
						</TableRow>
					</TableFooter>
				</Table>
			</TableContainer>
		</Box>
	);
};
