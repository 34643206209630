import React, { FC, forwardRef, ForwardRefExoticComponent, RefAttributes, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import {
	Paper,
	Box,
	Typography,
	Icon,
	Button,
	TextField,
	TextFieldVariants,
	Autocomplete,
	List,
	ListSubheader,
	ListItem,
	Stepper,
	Step,
	StepLabel,
	StepContent,
	styled,
	FormControlLabel,
	Checkbox
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import { LayoutBaseDePagina } from 'shared/layouts';
import { useNavigate } from 'react-router-dom';
import { DadosFormulario, ValidadorEmpresa, ValidadorSetores } from 'validators/Empresa';
import { Validacao } from 'validators/Validador';
import { Empresa, Municipio, Setor, Tipologia, UF } from 'models';
import { from, of, switchMap, tap, throwError } from 'rxjs';
import { ObterEnderecoPorCEP } from 'shared/services/Diversos';
import { MUNICIPIOS_JSON, UFS_JSON } from 'json';
import { useEspera, useMensagem } from 'contexts';
import { EmpresaService } from 'shared/services/Empresas/EmpresasService';
import { EmpresaRequest } from 'requests/EmpresaRequest';
import { ToastErro, ToastSucesso } from 'utils/toast';
import { TipologiaService } from 'shared/services/Tipologias/TipologiasService';
import { ESituacao, EUnidadeTempo } from 'enums';
import { EnumToArray } from 'utils';
import { DefaultButtonOnlyIcon } from 'shared/components/buttons';
import { LineGrid, FormGrid } from 'shared/components';
import { SetorService } from 'shared/services/Setores/SetoresService';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, { AccordionSummaryProps, accordionSummaryClasses } from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { BRANCO, ERRO, GRAFITE, INFO } from 'styles';

enum EEtapaEmpresa {
	EMPRESA = 0,
	SETOR = 1,
	TIPOLOGIA = 2
}

const sxSelectFormText = {
	'.MuiFormLabel-root, .MuiSvgIcon-root, .MuiInputBase-root, .MuiInputBase-root::before': {
		color: BRANCO
	},
	'.css-5h82ro-MuiInputBase-root-MuiInput-root::before': {
		borderBottom: '1px solid white'
	},
	'.css-2mgyhq-MuiNativeSelect-select-MuiInputBase-input-MuiInput-input:not([multiple]) option': {
		backgroundColor: GRAFITE
	}
};


const Accordion = styled((props: AccordionProps) => (
	<MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
	border: `1px solid ${theme.palette.divider}`,
	borderTopLeftRadius: 5,
	borderTopRightRadius: 5,
	marginTop: 2,
	marginBottom: 2,
	'&:not(:last-child)': {
		// borderBottom: 0,
	},
	'&::before': {
		display: 'none',
	},
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
	<MuiAccordionSummary
		expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
		{...props}
	/>
))(({ theme }) => ({
	backgroundColor: INFO,
	borderTopLeftRadius: 5,
	borderTopRightRadius: 5,
	color: BRANCO,
	flexDirection: 'row-reverse',
	[`.${accordionSummaryClasses.expandIconWrapper}`]: {
		color: BRANCO
	},
	[`& .${accordionSummaryClasses.expandIconWrapper}.${accordionSummaryClasses.expanded}`]:
	{
		transform: 'rotate(90deg)'
	},
	[`& .${accordionSummaryClasses.content}`]: {
		marginLeft: theme.spacing(1),
	},
	...theme.applyStyles('dark', {
		backgroundColor: INFO,
	}),
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
	padding: theme.spacing(2)
}));

const variant: TextFieldVariants = 'standard';

export const CadastrarEmpresa: React.FC = () => {
	const UFs = useMemo(() => UFS_JSON.map(uf => UF.create(uf)), []);

	const Municipios = useMemo(() => MUNICIPIOS_JSON.map(municipio => {
		const municipioModel = Municipio.create(municipio);
		municipioModel.uf = UFs.find(({ sigla }) => sigla == municipioModel.ufSigla)!;
		return municipioModel;
	}), []);

	const [formulario, setFormulario] = useState<DadosFormulario>({
		cpfCnpj: '',
		razaoSocial: '',
		nomeFantasia: '',
		responsavel: '',
		cep: '',
		logradouro: '',
		complemento: '',
		numero: '',
		bairro: '',
		estado: '',
		municipio: '',
		telefoneCelular: '',
		telefoneFixo: '',
		email: '',
		ehFilial: false,
		setor: undefined,
		setores: [],
		matriz: undefined
	});

	const [validacao, setValidacao] = useState<any>({
		cpfCnpj: { valido: true, mensagem: null } as Validacao,
		razaoSocial: { valido: true, mensagem: null } as Validacao,
		nomeFantasia: { valido: true, mensagem: null } as Validacao,
		responsavel: { valido: true, mensagem: null } as Validacao,
		cep: { valido: true, mensagem: null } as Validacao,
		logradouro: { valido: true, mensagem: null } as Validacao,
		numero: { valido: true, mensagem: null } as Validacao,
		bairro: { valido: true, mensagem: null } as Validacao,
		estado: { valido: true, mensagem: 'Selecione o Estado' } as Validacao,
		municipio: { valido: true, mensagem: 'Selecione o Município' } as Validacao,
		email: { valido: true, mensagem: null } as Validacao,
		setores: { valido: true, mensagem: '' } as Validacao,
		matriz: { valido: true, mensagem: '' } as Validacao
	});

	const [EnderecoPesquisado, setEnderecoPesquisado] = useState<boolean>(false);
	const [EnderecoNaoEncontrado, setEnderecoNaoEncontrado] = useState<boolean>(false);
	const [municipios, setMunicipios] = useState<Municipio[]>([]);
	const [empresas, setEmpresas] = useState<Empresa[]>([]);
	const [etapaAtiva, setEtapaAtiva] = React.useState(EEtapaEmpresa.EMPRESA);

	const { Padrao, Informacao } = useMensagem();
	const { BloquearTela, DesbloquearTela } = useEspera();

	const refSetoresTipologia = useRef<Partial<IRetornoSetoresTipologias>>();

	const enabled = !EnderecoPesquisado || (EnderecoPesquisado && !EnderecoNaoEncontrado);

	const ehArraySetoresValidacao = Array.isArray(validacao.setores);

	const PesquisarPorCEP = () => {
		const cepSemFormatacao = formulario.cep.replace(/\D/gim, '');
		if (!cepSemFormatacao) {
			Padrao(
				'Atenção',
				`Informe um CEP para pesquisar.`,
				[
					{
						label: 'fechar',
						fechar: true
					}
				]
			);
			return;
		}

		BloquearTela();
		ObterEnderecoPorCEP(cepSemFormatacao)
			.pipe(
				tap((dados: DadosFormulario) => {
					setEnderecoNaoEncontrado(!!dados?.cep);
					setEnderecoPesquisado(true);
				}),
				switchMap((dados: DadosFormulario) => {
					if (!dados?.cep)
						throw new Error('CEP não encontrado');
					return of(dados);
				}),
			).subscribe({
				next: (dados: DadosFormulario) => {
					_set({
						cep: dados?.cep,
						logradouro: dados?.logradouro,
						bairro: dados?.bairro,
						complemento: dados?.complemento,
						estado: UF.ufId(dados?.estado.toString()),
						municipio: dados?.municipio,
					}, true);
				},
				error: (error: Error) => Padrao(
					'Busca pelo CEP',
					error.message, [{
						label: 'Ok',
						fechar: true,
						onPress: () => DesbloquearTela()
					}]),
				complete: () => DesbloquearTela()
			});
	};

	const LimparPesquisarCep = () => {
		setEnderecoNaoEncontrado(false);
		setEnderecoPesquisado(false);
		_set({
			cep: '',
			logradouro: '',
			bairro: '',
			complemento: '',
			estado: '',
			municipio: ''
		});
	};

	const _set = (obj: any, validar: boolean = false) => {
		setFormulario((form: DadosFormulario) => ({ ...form, ...obj } as DadosFormulario));

		if (validar)
			for (const [campo, valor] of Object.entries(obj)) {
				let valorTestado = valor;
				if (campo == 'matriz')
					valorTestado = [valor, formulario.ehFilial];

				handleValidarValorCampo(campo as keyof DadosFormulario, valorTestado);
			}
	};

	const PopularSelectMunicipios = (selectedValue?: number | string) => {
		if (!formulario.estado) {
			setMunicipios([]);
			return;
		}
		const municipios = Municipios?.filter(({ uf }) => uf?.ufID == formulario.estado);
		setMunicipios(municipios);
		if (selectedValue) _set({ municipio: +selectedValue });
	};

	useEffect(() => PopularSelectMunicipios(), [formulario.estado]);

	const handleRazaoSocial = (event: { target: { value: string }; }) => _set({ razaoSocial: event.target.value });
	const handleNomeFantasia = (event: { target: { value: string }; }) => _set({ nomeFantasia: event.target.value });
	const handleResponsavel = (event: { target: { value: string }; }) => _set({ responsavel: event.target.value });
	const handleCpfCnpj = (event: { target: { value: string }; }) => {
		const value = event.target.value.replace(/\D/g, '');
		if (value.length <= 11) {
			let formattedValue = value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, '$1.$2.$3-$4');
			if (value.length <= 6)
				formattedValue = value.replace(/(\d{3})(\d{1,3})/g, '$1.$2');
			else if (value.length <= 9)
				formattedValue = value.replace(/(\d{3})(\d{3})(\d{1,3})/g, '$1.$2.$3');
			else if (value.length <= 10)
				formattedValue = value.replace(/(\d{3})(\d{3})(\d{3})(\d{1,2})/g, '$1.$2.$3-$4');
			_set({ cpfCnpj: formattedValue });
		} else if (value.length <= 14) {
			let formattedValue = value.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{1,2})/g, '$1.$2.$3/$4-$5');
			if (value.length <= 12)
				formattedValue = value.replace(/(\d{2})(\d{3})(\d{3})(\d{1,4})/g, '$1.$2.$3/$4');
			_set({ cpfCnpj: formattedValue });
		}
	};
	const handleLogradouro = (event: { target: { value: string }; }) => _set({ logradouro: event.target.value });
	const handleCep = (event: { target: { value: string }; }) => {
		const value = event.target.value.replace(/\D/g, '');
		if (value.length <= 8) {
			const formattedValue = value.replace(/(\d{5})(\d{1,3})/g, '$1-$2');
			_set({ cep: formattedValue });
		}
	};
	const handleNumero = (event: { target: { value: string }; }) => _set({ numero: event.target.value });
	const handleBairro = (event: { target: { value: string }; }) => _set({ bairro: event.target.value });
	const handleComplemento = (event: { target: { value: string }; }) => _set({ complemento: event.target.value });
	const handleMunicipio = (event: { target: { value: string }; }) => _set({ municipio: event.target.value });
	const handleEstado = (event: { target: { value: string }; }) => _set({ estado: event.target.value });
	const handleTelefoneCelular = (event: { target: { value: string }; }) => {
		const value = event.target.value.replace(/\D/g, '');
		if (value.length <= 11) {
			let formattedValue = value.replace(/(\d{2})(\d{2,5})(\d{1,4})/g, '($1) $2-$3');
			if (value.length <= 10)
				formattedValue = value.replace(/(\d{2})(\d{2,4})(\d{1,4})/g, '($1) $2-$3');
			_set({ telefoneCelular: formattedValue });
		}
	};
	const handleTelefoneFixo = (event: { target: { value: string }; }) => {
		const value = event.target.value.replace(/\D/g, '');
		if (value.length <= 11) {
			let formattedValue = value.replace(/(\d{2})(\d{2,5})(\d{1,4})/g, '($1) $2-$3');
			if (value.length <= 10)
				formattedValue = value.replace(/(\d{2})(\d{2,4})(\d{1,4})/g, '($1) $2-$3');
			_set({ telefoneFixo: formattedValue });
		}
	};
	const handleEmail = (event: { target: { value: string }; }) => _set({ email: event.target.value });
	const handleEhFilial = (valor: boolean) => _set({ ehFilial: valor, matriz: undefined });
	const handleMatriz = (valor: Empresa | null) => _set({ matriz: valor }, true);

	const handleValidarCampo = (campo: keyof DadosFormulario, campoComplementar?: keyof DadosFormulario) => {
		let valor: any = formulario[campo];
		if (campoComplementar)
			valor = [formulario[campo], formulario[campoComplementar]];

		setValidacao((validados: any) => ({ ...validados, ...ValidadorEmpresa.validarCampo(valor, campo) }));
	};

	const handleValidarValorCampo = (campo: keyof DadosFormulario, valor: any): Validacao => {
		const campoValidado = ValidadorEmpresa.validarCampo(valor, campo);
		setValidacao((validados: any) => ({ ...validados, ...campoValidado }));
		return campoValidado;
	};

	const handleAddSetor = (setores: Setor[]) => {
		_set({ setores: [...setores] }, false);
	};

	const handleNext = () => {
		let validador = ValidadorEmpresa;
		if (etapaAtiva == EEtapaEmpresa.SETOR)
			validador = ValidadorSetores;
		const camposValidados = validador.validar(formulario);
		setValidacao((validacaoAnterior: any) => ({ ...validacaoAnterior, ...camposValidados }));
		if (validador.ehValido(camposValidados))
			setEtapaAtiva((prevActiveStep) => prevActiveStep + 1);
	};

	const handleBack = () => {
		setEtapaAtiva((prevActiveStep) => prevActiveStep - 1);
	};

	const CadastrarEmpresa = () => {
		if (refSetoresTipologia.current?.ehValido?.() || false) {
			Informacao(
				null,
				() => <Typography display='flex' justifyContent='center' alignItems='center' flexDirection='column'>
					Deseja prosseguir com o cadastro da empresa?
					<Typography fontWeight={600} fontSize={13} fontStyle='italic'>* Antes de confirmar, revise os dados informados.</Typography>
				</Typography>,
				[
					{
						label: 'Sim',
						onPress: () => {
							const novaEmpresa = new EmpresaRequest({
								nomeFantasia: formulario.nomeFantasia,
								razaoSocial: formulario.razaoSocial,
								responsavel: formulario.responsavel,
								logradouro: formulario.logradouro,
								numero: formulario.numero,
								complemento: formulario.complemento,
								cpfcnpj: formulario.cpfCnpj?.replace(/\D/g, ''),
								cep: formulario.cep?.replace(/\D/g, ''),
								bairro: formulario.bairro,
								municipioID: formulario.municipio,
								ufid: formulario.estado,
								telefoneCelular: formulario.telefoneCelular?.replace(/\D/g, ''),
								telefoneFixo: formulario.telefoneFixo?.replace(/\D/g, ''),
								email: formulario.email,
								matrizID: formulario.matriz?.empresaID,
								empresasSetores: formulario.setores
							});

							BloquearTela();
							from(EmpresaService.save(novaEmpresa))
								.pipe(
									tap(() => DesbloquearTela()),
									switchMap((resposta) => {
										if (resposta instanceof Error)
											return throwError(() => resposta.message);
										return of(resposta);
									})
								)
								.subscribe({
									next: () => ToastSucesso(`Empresa cadastrada com sucesso`),
									error: (erro) => ToastErro(erro),
									complete: () => navigate(-1)
								});
						}
					},
					{
						label: 'Não',
						fechar: true
					}
				]
			);
		}
	};

	const navigate = useNavigate();

	useEffect(() => {
		from(EmpresaService.getAll())
			.pipe(
				switchMap((retorno: any | Error) => {
					if (retorno instanceof Error)
						return throwError(() => retorno);

					return of(retorno
						.map((empresa: any) => new Empresa(empresa))
						.filter((empresa: Empresa) => empresa.situacaoID == ESituacao.ATIVO));
				}),
			)
			.subscribe({
				next: (empresas: Empresa[]) => setEmpresas(empresas),
				error: (error) => ToastErro(error || 'Erro ao obter as empresas')
			});
	}, []);

	return (
		<LayoutBaseDePagina>
			<Box padding={1} display='flex' justifyContent='center' alignItems='center' sx={{ backgroundColor: '#D3D3D3' }}>
				<Grid
					container
					spacing={1}
					component={Paper}
					elevation={0}
					sx={{ flexGrow: 1 }}
					display='flex'
					flexDirection='column'
					justifyContent='center'
					alignItems='center'
					padding={2}
				>
					<Stepper activeStep={etapaAtiva} orientation='vertical' sx={{ width: '100%' }}>
						<Step>
							<StepLabel optional={<Typography variant='caption'>Dados da Empresa</Typography>}>
								<Typography variant='h4'>
									Empresa
								</Typography>
							</StepLabel>
							<StepContent>
								<Grid size={12} container spacing={3} component='form' sx={{ flexGrow: 1 }}>
									<LineGrid>
										<FormGrid size={{ xs: 12, md: 4 }}>
											<TextField
												id='cpfcnpj'
												name='cpfcnpj'
												type='text'
												placeholder='CPF/CNPJ'
												autoComplete='CPF/CNPJ'
												required
												value={formulario.cpfCnpj}
												onChange={handleCpfCnpj}
												label='CPF/CNPJ'
												helperText={validacao.cpfCnpj.mensagem}
												error={!validacao.cpfCnpj.valido}
												variant={variant}
												onBlur={() => handleValidarCampo('cpfCnpj')}
												size='small'
											/>
										</FormGrid>
									</LineGrid>
									<FormGrid size={{ xs: 12, md: 6 }}>
										<TextField
											id='razao-social'
											name='razao-social'
											type='text'
											placeholder='Razão Social'
											autoComplete='Razão Social'
											required
											value={formulario.razaoSocial}
											onChange={handleRazaoSocial}
											label='Razão Social'
											helperText={validacao.razaoSocial.mensagem}
											error={!validacao.razaoSocial.valido}
											variant={variant}
											onBlur={() => handleValidarCampo('razaoSocial')}
											size='small'
										/>
									</FormGrid>
									<FormGrid size={{ xs: 12, md: 6 }}>
										<TextField
											id='nome-fantasia'
											name='nome-fantasia'
											type='text'
											placeholder='Nome Fantasia'
											autoComplete='Nome Fantasia'
											required
											value={formulario.nomeFantasia}
											onChange={handleNomeFantasia}
											label='Nome Fantasia'
											helperText={validacao.nomeFantasia.mensagem}
											error={!validacao.nomeFantasia.valido}
											variant={variant}
											onBlur={() => handleValidarCampo('nomeFantasia')}
											size='small'
										/>
									</FormGrid>
									<FormGrid size={{ xs: 12, md: 6 }}>
										<TextField
											id='responsavel'
											name='responsavel'
											type='text'
											placeholder='Responsável'
											autoComplete='Responsável'
											required
											value={formulario.responsavel}
											onChange={handleResponsavel}
											label='Responsável'
											helperText={validacao.responsavel.mensagem}
											error={!validacao.responsavel.valido}
											variant={variant}
											onBlur={() => handleValidarCampo('responsavel')}
											size='small'
										/>
									</FormGrid>
									<LineGrid>
										<FormGrid size={{ xs: 6, md: 2 }}>
											<TextField
												id='cep'
												name='cep'
												type='text'
												placeholder='00000-000'
												autoComplete='CEP'
												required
												value={formulario.cep}
												onChange={handleCep}
												label='CEP'
												disabled={!enabled}
												helperText={validacao.cep.mensagem}
												error={!validacao.cep.valido}
												variant={variant}
												onBlur={() => handleValidarCampo('cep')}
												size='small'
											/>
										</FormGrid>
										<FormGrid size={{ xs: 6, md: 3 }}>
											{
												enabled &&
												<Button
													color='info'
													disableElevation
													variant='contained'
													onClick={() => PesquisarPorCEP()}
													size='large'
													sx={{ mt: .5, ml: .5 }}
													startIcon={<Icon>search</Icon>}>
													<Typography variant='button' whiteSpace='nowrap' textOverflow='ellipsis' overflow='hidden'>
														Pesquisar
													</Typography>
												</Button>
											}
											{
												!enabled &&
												<Button
													color='inherit'
													disableElevation
													variant='contained'
													onClick={() => LimparPesquisarCep()}
													size='large'
													sx={{ mt: .5, ml: .5 }}
													startIcon={<Icon>close</Icon>}>
													<Typography variant='button' whiteSpace='nowrap' textOverflow='ellipsis' overflow='hidden'>
														Limpar Pesquisa
													</Typography>
												</Button>
											}
										</FormGrid>
									</LineGrid>
									<FormGrid size={{ xs: 12, md: 6 }}>
										<TextField
											id='logradouro'
											name='logradouro'
											type='text'
											placeholder='Logradouro'
											autoComplete='Logaradouro'
											required
											value={formulario.logradouro}
											onChange={handleLogradouro}
											label='Logradouro'
											disabled={!enabled}
											helperText={validacao.logradouro.mensagem}
											error={!validacao.logradouro.valido}
											variant={variant}
											onBlur={() => handleValidarCampo('logradouro')}
											size='small'
										/>
									</FormGrid>
									<FormGrid size={{ xs: 6, md: 2 }}>
										<TextField
											id='numero'
											name='numero'
											type='text'
											placeholder='Número'
											autoComplete='Número'
											required
											value={formulario.numero}
											onChange={handleNumero}
											label='Número'
											helperText={validacao.numero.mensagem}
											error={!validacao.numero.valido}
											variant={variant}
											onBlur={() => handleValidarCampo('numero')}
											size='small'
										/>
									</FormGrid>
									<FormGrid size={{ xs: 6, md: 4 }}>
										<TextField
											id='bairro'
											name='bairro'
											type='text'
											placeholder='Bairro'
											autoComplete='Bairro'
											required
											value={formulario.bairro}
											onChange={handleBairro}
											label='Bairro'
											disabled={!enabled}
											helperText={validacao.bairro.mensagem}
											error={!validacao.bairro.valido}
											variant={variant}
											onBlur={() => handleValidarCampo('bairro')}
											size='small'
										/>
									</FormGrid>
									<FormGrid size={{ xs: 12, md: 6 }}>
										<TextField
											id='complemento'
											name='complemento'
											type='text'
											placeholder='Complemento'
											autoComplete='Complemento'
											value={formulario.complemento}
											onChange={handleComplemento}
											label='Complemento'
											variant={variant}
											size='small'
										/>
									</FormGrid>
									<FormGrid size={{ xs: 6, md: 2 }}>
										<TextField
											id='estado'
											name='estado'
											type='number'
											placeholder='Estado'
											autoComplete='Estado'
											required
											value={formulario.estado}
											onChange={handleEstado}
											label='Estado'
											disabled={!enabled}
											select
											defaultValue=''
											slotProps={{
												select: {
													native: true,
												},
											}}
											helperText={validacao.estado.mensagem}
											error={!validacao.estado.valido}
											variant={variant}
											onBlur={() => handleValidarCampo('estado')}
											size='small'
										>
											<option key={9999} value=''>&nbsp;</option>
											{UFs.map(({ ufID, nome }) =>
												<option key={ufID} value={ufID}>
													<Typography sx={{ marginX: 5 }}>{nome}</Typography>
												</option>
											)}
										</TextField>
									</FormGrid>
									<FormGrid size={{ xs: 6, md: 3 }}>
										<TextField
											id='municipio'
											name='municipio'
											type='number'
											placeholder='Município'
											autoComplete='Município'
											required
											value={formulario.municipio}
											onChange={handleMunicipio}
											label='Município'
											disabled={!municipios?.length || !enabled}
											select
											defaultValue=''
											slotProps={{
												select: {
													native: true,
												},
											}}
											helperText={validacao.municipio.mensagem}
											error={!validacao.municipio.valido}
											variant={variant}
											onBlur={() => handleValidarCampo('municipio')}
											size='small'
										>
											<option key={9999} value=''>&nbsp;</option>
											{municipios.map(({ municipioID, nome }) =>
												<option key={municipioID} value={municipioID}>
													<Typography mx={2}>{nome}</Typography>
												</option>
											)}
										</TextField>
									</FormGrid>
									<FormGrid size={{ xs: 4, md: 3 }}>
										<TextField
											id='telefone-celular'
											name='telefone-celular'
											type='text'
											placeholder='Telefone Celular'
											autoComplete='Telefone Celular'
											value={formulario.telefoneCelular}
											onChange={handleTelefoneCelular}
											label='Telefone Celular'
											variant={variant}
											size='small'
										/>
									</FormGrid>
									<FormGrid size={{ xs: 4, md: 3 }}>
										<TextField
											id='telefone-fixo'
											name='telefone-fixo'
											type='text'
											placeholder='Telefone fixo'
											autoComplete='Telefone fixo'
											value={formulario.telefoneFixo}
											onChange={handleTelefoneFixo}
											label='Telefone Fixo'
											variant={variant}
											size='small'
										/>
									</FormGrid>
									<FormGrid size={{ xs: 4, md: 6 }}>
										<TextField
											id='email'
											name='email'
											type='email'
											placeholder='E-mail'
											autoComplete='E-mail'
											required
											value={formulario.email}
											onChange={handleEmail}
											label='E-mail'
											helperText={validacao.email.mensagem}
											error={!validacao.email.valido}
											variant={variant}
											onBlur={() => handleValidarCampo('email')}
											size='small'
										/>
									</FormGrid>
									<FormGrid size={{ xs: 12, md: 6 }}>
										<FormControlLabel
											label='Filial'
											checked={formulario.ehFilial}
											control={
												<Checkbox
													onChange={() => handleEhFilial(!formulario.ehFilial)}
												/>
											}
										/>
									</FormGrid>
									{
										formulario.ehFilial &&
										<FormGrid size={{ xs: 12, md: 6 }}>
											<Autocomplete
												clearOnEscape
												value={formulario.matriz}
												inputValue={formulario.matriz?.razaoSocial || ''}
												options={empresas}
												onChange={(event: any, novoValor: Empresa | null) => handleMatriz(novoValor)}
												getOptionLabel={(option: Empresa) => option.razaoSocial}
												getOptionDisabled={(option: Empresa) => formulario.matriz?.empresaID == option.empresaID}
												renderInput={(params) =>
													<TextField
														{...params}
														id='matriz'
														name='matriz'
														type='text'
														placeholder='Matriz'
														autoComplete='Matriz'
														label='Matriz'
														defaultValue=''
														helperText={validacao.matriz?.mensagem}
														error={!validacao.matriz?.valido}
														variant={variant}
														onSelect={() => handleValidarCampo('matriz', 'ehFilial')}
														size='small' />
												}
											/>
										</FormGrid>
									}
									<Grid container sx={{ padding: '1rem', justifyContent: 'end', alignSelf: 'end', width: '100%' }}>
										<Button
											color='success'
											disableElevation
											variant='contained'
											onClick={handleNext}
											sx={{ mt: 1, mr: 1 }}>
											<Typography variant='button' whiteSpace='nowrap' textOverflow='ellipsis' overflow='hidden'>
												Continuar
											</Typography>
										</Button>
									</Grid>
								</Grid>
							</StepContent>
						</Step>
						<Step>
							<PassoSetor
								handleAddSetor={handleAddSetor}
								handleNext={handleNext}
								handleBack={handleBack}
								validacaoLista={!ehArraySetoresValidacao ? validacao.setores : null}
							/>
						</Step>
						<Step>
							<StepLabel optional={<Typography variant='caption'>Tipologias da Empresa</Typography>}>
								<Typography variant='h4'>
									Tipologias
								</Typography>
							</StepLabel>
							<StepContent>
								<SetoresTipologias
									ref={ref => refSetoresTipologia.current = ref || undefined}
									setores={formulario.setores}
								/>
								<Grid container sx={{ padding: '1rem', justifyContent: 'space-between', alignSelf: 'end' }}>
									<Button
										onClick={handleBack}
										sx={{ mt: 1, mr: 1 }}
									>
										Voltar
									</Button>
									<Button
										color='success'
										disableElevation
										variant='contained'
										onClick={() => CadastrarEmpresa()}
										sx={{ mt: 1, mr: 1 }}
										startIcon={<Icon>save</Icon>} >
										<Typography variant='button' whiteSpace='nowrap' textOverflow='ellipsis' overflow='hidden'>
											salvar
										</Typography>
									</Button>
								</Grid>
							</StepContent>
						</Step>
					</Stepper>
				</Grid>
			</Box>
			<Grid container sx={{ padding: '1rem', justifyContent: 'space-between', alignSelf: 'end' }}>
				<Button
					color='info'
					disableElevation
					variant='contained'
					onClick={() => navigate(-1)}
					startIcon={<Icon>arrow_back</Icon>} >
					<Typography variant='button' whiteSpace='nowrap' textOverflow='ellipsis' overflow='hidden'>
						Voltar
					</Typography>
				</Button>
			</Grid>
		</LayoutBaseDePagina>
	);
};

interface IPassoSetor {
	handleAddSetor: (setores: Setor[]) => void,
	handleNext: () => void,
	handleBack: () => void,
	validacaoLista: Validacao | null
}

const PassoSetor: FC<IPassoSetor> = ({ handleAddSetor, handleNext, handleBack, validacaoLista }) => {
	const [setor, setSetor] = useState<Setor | null>();
	const [setores, setSetores] = useState<Setor[]>([]);
	const [setoresSelecionados, setSetoresSelecionados] = useState<Setor[]>([]);
	const [validacao, setValidacao] = useState<Validacao>({ valido: true, mensagem: null });
	const { Informacao } = useMensagem();

	useEffect(() => {
		from(SetorService.getAll())
			.pipe(
				switchMap((retorno: any | Error) => {
					if (retorno instanceof Error)
						return throwError(() => retorno);

					return of(retorno.data
						.map((setor: any) => new Setor(setor))
						.filter((setor: Setor) => setor.situacaoID == ESituacao.ATIVO));
				}),
			)
			.subscribe({
				next: (setores: Setor[]) => setSetores(setores),
				error: (error) => ToastErro(error || 'Erro ao obter os setores')
			});
	}, []);

	useEffect(() => {
		if (validacaoLista)
			setValidacao(validacaoLista);
	}, [validacaoLista]);

	const handleValidarSetor = () => {
		if (!setor)
			setValidacao({ valido: false, mensagem: 'Selecione um setor para ser adicionado' } as Validacao);
		setValidacao({ valido: true, mensagem: null });
	};

	const handle = () => {
		if (!setor) {
			setValidacao({ valido: false, mensagem: 'Selecione um setor para ser adicionado' } as Validacao);
			return;
		}
		const setoresRestantes = [...setoresSelecionados, setor];
		setSetoresSelecionados(setoresRestantes);
		handleAddSetor(setoresRestantes);
		setSetor(null);
	};

	const handleRemoveSetor = (setor: Setor) => {
		Informacao(
			'Atenção',
			() =>
				<Typography display='flex' flexDirection='row' whiteSpace='nowrap'>
					Deseja remover o setor <Typography fontWeight={700}>&nbsp;{setor.nome}&nbsp;</Typography>?
				</Typography>,
			[
				{
					label: 'Sim',
					onPress: () => {
						const setoresRestantes = setoresSelecionados.filter((_setor) => _setor != setor);
						setSetoresSelecionados([...setoresRestantes]);
						handleAddSetor(setoresRestantes);
					}
				},
				{
					label: 'Cancelar',
					fechar: true
				}
			]
		);
	};

	return (
		<>
			<StepLabel optional={<Typography variant='caption'>Setores da Empresa</Typography>}>
				<Typography variant='h4'>
					Setor
				</Typography>
			</StepLabel>
			<StepContent>
				<Grid size={12} container>
					<LineGrid>
						<FormGrid size={{ xs: 10 }}>
							<Autocomplete
								clearOnEscape
								value={setor}
								inputValue={setor?.nome || ''}
								options={setores}
								onChange={(event: any, novoValor: Setor | null) => setSetor(novoValor)}
								getOptionLabel={(option: Setor) => option.nome}
								getOptionDisabled={(option) => setoresSelecionados?.some(setor => setor.setorID == option.setorID) || false}
								renderInput={(params) =>
									<TextField
										{...params}
										id='setor'
										name='setor'
										type='text'
										placeholder='Setores'
										autoComplete='Setores'
										required
										label='Setores'
										defaultValue=''
										helperText={validacao.mensagem}
										error={!validacao.valido}
										variant={variant}
										onSelect={() => handleValidarSetor()}
										size='small' />
								}
							/>
						</FormGrid>
						<FormGrid size={{ xs: 2 }}>
							<Button
								color='success'
								disableElevation
								variant='contained'
								onClick={() => handle()}
								size='large'
								sx={{ mt: .5, ml: .5 }}
								startIcon={<Icon>add</Icon>}>
								<Typography variant='button' whiteSpace='nowrap' textOverflow='ellipsis' overflow='hidden'>
									Adicionar
								</Typography>
							</Button>
						</FormGrid>
					</LineGrid>
					<Grid size={12} container>
						<List
							sx={{ bgcolor: 'background.paper', borderRadius: 2, overflow: 'hidden', width: '100%' }}
							component='nav'
							aria-labelledby='lista-tipologia'
							subheader={
								<ListSubheader sx={{ px: 1, borderRadius: 2, width: '100%' }} id='lista-setor'>
									Setores
								</ListSubheader>
							}
							dense>
							{
								setoresSelecionados?.map((setor: Setor) => {
									const { setorID, nome } = setor;
									return (
										<ListItem
											key={setorID}
											sx={{ px: 1 }}>
											<LineGrid
												display='flex'
												flexDirection='row'
												justifyContent='center'
												alignItems='center'
												boxShadow='2px 2px 2px 1px rgba(0, 0, 0, 0.2)'
												borderRadius={5}
												bgcolor={INFO}
												py={1}
											>
												<Grid size={12} display='flex' alignItems='center'>
													<Typography paddingLeft={2} color={BRANCO}>
														{nome}
													</Typography>
												</Grid>
												<Grid size={.5} display='flex' alignSelf='center' justifyContent='center'>
													<DefaultButtonOnlyIcon icon='close' color={BRANCO} onClick={() => handleRemoveSetor(setor)} />
												</Grid>
											</LineGrid>
										</ListItem>
									);
								})
							}
						</List>
					</Grid>
				</Grid>
				<Grid container sx={{ padding: '1rem', justifyContent: 'space-between', alignSelf: 'end' }}>
					<Button
						onClick={handleBack}
						sx={{ mt: 1, mr: 1 }}
					>
						Voltar
					</Button>
					<Button
						color='success'
						disableElevation
						variant='contained'
						onClick={handleNext}
						sx={{ mt: 1, mr: 1 }}>
						<Typography variant='button' whiteSpace='nowrap' textOverflow='ellipsis' overflow='hidden'>
							Continuar
						</Typography>
					</Button>
				</Grid>
			</StepContent>
		</>
	);
};

interface IRetornoSetoresTipologias {
	ehValido: () => boolean;
}

interface ISetoresTipologias {
	setores: (Setor & Partial<IRetornoSetoresTipologias>)[]
}

const SetoresTipologias: ForwardRefExoticComponent<Omit<ISetoresTipologias, 'ref'> & RefAttributes<IRetornoSetoresTipologias>> =
	forwardRef(({ setores }, ref) => {
		const [aberto, setAberto] = useState<number | false>(false);
		const [tipologias, setTipologias] = useState<Tipologia[]>([]);

		useImperativeHandle(ref, () => ({
			ehValido: () => setores.every(({ ehValido }) => (ehValido?.() ?? false))
		}));

		const handleChangeCollapse = (setorID: number) => (event: React.SyntheticEvent, newExpanded: boolean) => {
			setAberto(newExpanded ? setorID : false);
		};

		useEffect(() => {
			from(TipologiaService.getAll())
				.pipe(
					switchMap((retorno: any | Error) => {
						if (retorno instanceof Error)
							return throwError(() => retorno);

						return of(retorno.data
							.map((tipologia: any) => new Tipologia(tipologia))
							.filter(({ situacaoID }: Tipologia) => situacaoID == ESituacao.ATIVO));
					}),
				)
				.subscribe({
					next: (tipologias: Tipologia[]) => setTipologias(tipologias),
					error: (error) => ToastErro(error || 'Erro ao obter as tipologias')
				});
		}, []);

		return (
			<Grid>
				{
					setores.map((setor: Setor & Partial<IRetornoSetoresTipologias>) =>
						<AccordionSetor
							ref={ref => setor.ehValido = ref?.ehValido}
							key={setor.setorID}
							setor={setor}
							tipologias={tipologias}
							aberto={aberto}
							onChange={handleChangeCollapse} />)
				}
			</Grid>
		);
	});

SetoresTipologias.displayName = 'SetoresTipologias';
interface IAccordionSetor {
	setor: Setor,
	tipologias: Tipologia[],
	aberto: number | false,
	onChange: (setorID: number) => (event: React.SyntheticEvent, newExpanded: boolean) => void
}

interface IRetornoAccordionSetor {
	ehValido: () => boolean;
}

const AccordionSetor: ForwardRefExoticComponent<Omit<IAccordionSetor, 'ref'> & RefAttributes<IRetornoAccordionSetor>> =
	forwardRef(({ setor, tipologias, aberto, onChange }, ref) => {
		const [tipologiasSelecionadas, setTipologiasSelecionadas] = useState<(Tipologia & Partial<IRetornoAccordionSetor>)[]>([]);
		const [validacao, setValidacao] = useState<Validacao>({ valido: true, mensagem: null });
		const [iconeErro, setIconeErro] = useState<boolean>();
		const { Informacao } = useMensagem();
		const { setorID, nome } = setor;

		useImperativeHandle(ref, () => ({
			ehValido: () => validadeTipologiasSelecionadas()
		}));

		useEffect(() => {
			setor.tipologias = tipologiasSelecionadas;
		}, [tipologiasSelecionadas]);

		const validadeTipologiasSelecionadas = () => {
			let erroIcone = false;
			let validacaoTipologias = { valido: true, mensagem: null } as Validacao;
			if (!tipologiasSelecionadas.any())
				validacaoTipologias = { valido: false, mensagem: 'Adicione ao menos uma Tipologia' };
			else if (!tipologiasSelecionadas.every(({ ehValido }) => (ehValido?.() ?? false)))
				erroIcone = true;
			setIconeErro(erroIcone);
			setValidacao(validacaoTipologias);
			return validacaoTipologias.valido && !erroIcone;
		};

		const handleAddTipologia = (tipologia: Tipologia) => {
			tipologiasSelecionadas.push({ ...tipologia });
			setTipologiasSelecionadas([...tipologiasSelecionadas]);
		};

		const handleRemoveTipologia = (tipologia: Tipologia) => {
			Informacao(
				'Atenção',
				() =>
					<Typography display='flex' flexDirection='row' whiteSpace='nowrap'>
						Deseja remover a tipologia <Typography fontWeight={700}>&nbsp;{tipologia.nome}&nbsp;</Typography>?
					</Typography>,
				[
					{
						label: 'Sim',
						onPress: () => {
							const tipologiasRestantes = tipologiasSelecionadas.filter(({ tipologiaID }) => tipologiaID != tipologia.tipologiaID);
							setTipologiasSelecionadas([...tipologiasRestantes]);
						}
					},
					{
						label: 'Cancelar',
						fechar: true
					}
				]
			);
		};

		return (
			<Accordion expanded={aberto === setor.setorID} onChange={onChange(setor.setorID)}>
				<AccordionSummary
					aria-controls={`painel${setorID}-content`}
					id={`painel${setorID}-header`}
				>
					<Grid size={3}>
						<Typography sx={{ flexShrink: 0 }}>
							{nome}
						</Typography>
					</Grid>
					<Grid size={7}>
						<Typography sx={{ opacity: .9 }}>
							[{tipologiasSelecionadas.length}] Tipologia(s)
						</Typography>
					</Grid>
					<Grid size={2} display='flex' justifyContent='end'>
						{!validacao.valido || iconeErro && <Icon color='error' sx={{ opacity: .8, marginLeft: 1 }}>warning</Icon>}
					</Grid>
				</AccordionSummary>
				<AccordionDetails>
					<SelecionarTipologia
						tipologias={tipologias}
						validacaoTipologia={validacao}
						tipologiasSelecionadas={tipologiasSelecionadas}
						handleAddTipologia={handleAddTipologia}
					/>

					<Grid size={12} container>
						<List
							sx={{ bgcolor: 'background.paper', borderRadius: 2, overflow: 'hidden', width: '100%' }}
							component='nav'
							aria-labelledby='lista-tipologia'
							subheader={
								<ListSubheader sx={{ px: 1, borderRadius: 2, width: '100%' }} id='lista-tipologia'>
									Tipologias
								</ListSubheader>
							}
							dense>
							{
								tipologiasSelecionadas?.map((tipologia) =>
									<TipologiaItem
										ref={ref => tipologia.ehValido = ref?.ehValido}
										key={tipologia.tipologiaID}
										tipologia={tipologia}
										handleRemoveTipologia={handleRemoveTipologia} />)
							}
						</List>
					</Grid>
				</AccordionDetails>
			</Accordion>
		);
	});

AccordionSetor.displayName = 'AccordionSetor';

interface ISelecionarTipologia {
	tipologias: Tipologia[];
	tipologiasSelecionadas: Tipologia[];
	validacaoTipologia: Validacao;
	handleAddTipologia: (tipologia: Tipologia) => void;
}

const SelecionarTipologia: FC<ISelecionarTipologia> = ({ tipologias, tipologiasSelecionadas, validacaoTipologia, handleAddTipologia }) => {
	const [tipologia, setTipologia] = useState<Tipologia | null>();
	const [validacao, setValidacao] = useState<Validacao>(validacaoTipologia);

	const handleValidarTipologia = () => {
		if (!tipologia)
			setValidacao({ valido: false, mensagem: 'Selecione uma tipologia para ser adicionada' } as Validacao);
		setValidacao({ valido: true, mensagem: null });
	};

	const handle = () => {
		if (!tipologia) {
			setValidacao({ valido: false, mensagem: 'Selecione uma tipologia para ser adicionada' } as Validacao);
			return;
		}
		handleAddTipologia(tipologia);
		setTipologia(null);
	};

	useEffect(() => {
		if (validacaoTipologia)
			setValidacao(validacaoTipologia);
	}, [validacaoTipologia]);

	return (
		<LineGrid size={12}>
			<FormGrid size={{ xs: 10 }}>
				<Autocomplete
					clearOnEscape
					value={tipologia}
					inputValue={tipologia?.nome || ''}
					options={tipologias}
					onChange={(event: any, novoValor: Tipologia | null) => setTipologia(novoValor)}
					getOptionLabel={(option: Tipologia) => option.nome}
					getOptionDisabled={(option) => tipologiasSelecionadas?.some(tipologia => tipologia.tipologiaID == option.tipologiaID) || false}
					renderInput={(params) =>
						<TextField
							{...params}
							id='tipologia'
							name='tipologia'
							type='text'
							placeholder='Tipologias'
							autoComplete='Tipologias'
							required
							label='Tipologias'
							defaultValue=''
							helperText={validacao?.mensagem}
							error={!validacao?.valido}
							variant={variant}
							onSelect={() => handleValidarTipologia()}
							size='small' />
					}
				/>
			</FormGrid>
			<FormGrid size={{ xs: 2 }}>
				<Button
					color='success'
					disableElevation
					variant='contained'
					onClick={() => handle()}
					size='large'
					sx={{ mt: .5, ml: .5 }}
					startIcon={<Icon>add</Icon>}>
					<Typography variant='button' whiteSpace='nowrap' textOverflow='ellipsis' overflow='hidden'>
						Adicionar
					</Typography>
				</Button>
			</FormGrid>
		</LineGrid>
	);
};

interface ITipologiaItem {
	tipologia: Tipologia,
	handleRemoveTipologia: (tipologia: Tipologia) => void

}

interface IRetornoTipologiaItem {
	ehValido: () => boolean;
}

const TipologiaItem: ForwardRefExoticComponent<Omit<ITipologiaItem, 'ref'> & RefAttributes<IRetornoTipologiaItem>> =
	forwardRef(({ tipologia, handleRemoveTipologia }, ref) => {
		const { tipologiaID, nome } = tipologia;

		const unidadesTempo = useMemo(() => EnumToArray(EUnidadeTempo).map(({ id, name }) => ({ unidadeTempoID: id, nome: name })), []);

		const [formulario, setFormulario] = useState({
			unidadeTempoID: tipologia.unidadeTempoID,
			tempo: tipologia.tempo
		});

		const [validacao, setValidacao] = useState<any>({
			unidadeTempoID: { valido: true, mensagem: '' } as Validacao,
			tempo: { valido: true, mensagem: '' } as Validacao
		});

		useImperativeHandle(ref, () => ({
			ehValido: () => ValidadorEmpresa.ehValido(validacao)
		}));

		const handleUnidadeTempo = (valor: EUnidadeTempo) => {
			setFormulario((form: any) => ({ ...form, unidadeTempoID: valor }));
			tipologia.unidadeTempoID = valor;

			if (!valor)
				setValidacao((validados: any) => ({ ...validados, unidadeTempoID: { valido: false, mensagem: 'Unidade Tempo é obrigatoria' } }));
			else
				setValidacao((validados: any) => ({ ...validados, unidadeTempoID: { valido: true, mensagem: null } }));
		};

		const handleTempo = (valor: number | string) => {
			setFormulario((form: any) => ({ ...form, tempo: valor }));
			tipologia.tempo = valor;

			if (!valor)
				setValidacao((validados: any) => ({ ...validados, tempo: { valido: false, mensagem: 'Tempo é obrigatorio' } }));
			else if (!+valor)
				setValidacao((validados: any) => ({ ...validados, tempo: { valido: false, mensagem: 'Tempo deve ser maior que 0 (zero)' } }));
			else
				setValidacao((validados: any) => ({ ...validados, tempo: { valido: true, mensagem: null } }));
		};


		return (
			<ListItem
				key={tipologiaID}
				sx={{ px: 1 }}>
				<LineGrid
					display='flex'
					flexDirection='row'
					justifyContent='center'
					alignItems='center'
					boxShadow='2px 2px 2px 1px rgba(0, 0, 0, 0.2)'
					borderRadius={5}
					bgcolor={GRAFITE}
					py={1}
				>
					<Grid size={8} display='flex' alignItems='center'>
						<Typography paddingLeft={2} color={BRANCO}>
							{nome}
						</Typography>
					</Grid>
					<Grid size={2}>
						<TextField
							id='unidadeTempo'
							name='unidadeTempo'
							type='number'
							placeholder='Unidade Tempo'
							autoComplete='Unidade Tempo'
							required
							value={formulario.unidadeTempoID}
							onChange={(event) => handleUnidadeTempo(+event.target.value)}
							label='Unidade Tempo'
							select
							slotProps={{
								select: {
									native: true,
								},
							}}
							helperText={validacao.unidadeTempoID.mensagem}
							error={!validacao.unidadeTempoID.valido}
							variant={variant}
							sx={sxSelectFormText}
							size='small'
						>
							<option key={9999} value=''>&nbsp;</option>
							{unidadesTempo.map(({ unidadeTempoID, nome }) =>
								<option key={unidadeTempoID} value={unidadeTempoID}>
									<Typography color={BRANCO} sx={{ marginX: 5 }}>{nome}</Typography>
								</option>
							)}
						</TextField>
					</Grid>
					<Grid size={2}>
						<TextField
							id='tempo'
							name='tempo'
							type='text'
							placeholder='Tempo'
							autoComplete='Tempo'
							required
							value={formulario.tempo}
							onChange={(event) => handleTempo(event.target.value?.replace(/\D/g, ''))}
							label='Tempo'
							helperText={validacao.tempo.mensagem}
							error={!validacao.tempo.valido}
							variant={variant}
							sx={sxSelectFormText}
							size='small'
						/>
					</Grid>
					<Grid size={.5} display='flex' alignSelf='center' justifyContent='center'>
						<DefaultButtonOnlyIcon icon='close' color={BRANCO} onClick={() => handleRemoveTipologia(tipologia)} />
					</Grid>
				</LineGrid>
			</ListItem>
		);
	});

TipologiaItem.displayName = 'TipologiaItem';