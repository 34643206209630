import { Api } from '../Axios';

interface IPermissao {
	permissaoID: number;
	nome: string;
}

export interface IModulo {
	moduloID: number;
	nome: string;
	icone: string;
	ordem: number;
	rota: string;
	permissoes: IPermissao[];
}

const getAll = async (): Promise<IModulo[] | Error> => {
	try {
		const urlRelativa = `/Modulo/ObterTodos`;
		const { data } = await Api.get(urlRelativa);

		if (data) {
			return data.data;
		}
		return new Error('Erro ao listar os registros.');
	} catch (error) {
		return new Error((error as { message: string }).message || 'Erro ao listar os registros.');
	}
};

const getAllByUserId = async (usuarioId: number, empresaId: number): Promise<IModulo[] | Error> => {
	try {
		const urlRelativa = `/Modulo/ObterModulosPorUsuarioEEmpresa?usuarioID=${usuarioId}&empresaID=${empresaId}`;
		const { data } = await Api.get(urlRelativa);

		if (data) {
			return data.data;
		}
		return new Error('Erro ao listar os registros.');
	} catch (error) {
		return new Error((error as { message: string }).message || 'Erro ao listar os registros.');
	}
};

export const ModuloService = {
	getAll,
	getAllByUserId
};