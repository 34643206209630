import { paleta } from 'utils';
import * as CORES_GERAIS from './colors';

const {
	VERMELHO,
	ROSA,
	ROXO,
	AZUL_MARINHO,
	AZUL_MARINHO_OPACO,
	AZUL,
	CIANO,
	VERDE_MAR,
	VERDE,
	VERDE_IAGRO,
	AMARELO,
	LARANJA,
	LARANJA_AVERMELHADO,
	CINZA_QUENTE,
	CINZA,
	CINZA_OPACO,
	CINZA_FRIO,
	BRANCO,
	PRETO,
	GRAFITE
} = CORES_GERAIS;

import {
	FONTE_HIPER,
	FONTE_POWER,
	FONTE_ULTRA,
	FONTE_MEGA,
	FONTE_SUPER,
	FONTE_GRANDE,
	FONTE_MEDIA,
	FONTE_PEQUENA,
	FONTE_MINI,
	FONTE_MICRO,
	FONTE_NANO,
} from './typography';

export const Tamanhosfontes = {
	FONTE_HIPER,
	FONTE_POWER,
	FONTE_ULTRA,
	FONTE_MEGA,
	FONTE_SUPER,
	FONTE_GRANDE,
	FONTE_MEDIA,
	FONTE_PEQUENA,
	FONTE_MINI,
	FONTE_MICRO,
	FONTE_NANO,
};

export interface ICores {
	PRIMARIA: string,
	SECUNDARIA: string,
	FONTE_CLARA: string,
	FONTE_ESCURA: string,
	FUNDO: string,
	CONTRASTE: string,
	SUCESSO: string,
	ATENCAO: string,
	ERRO: string,
	INFO: string,
	BOTAO_GERAL: string,
	FONTE_MENU_SELECIONADA: string,
	FONTE_MENU: string,
	VERMELHO: string,
	ROSA: string,
	ROXO: string,
	AZUL_MARINHO: string,
	AZUL_MARINHO_OPACO: string,
	AZUL: string,
	CIANO: string,
	VERDE_MAR: string,
	VERDE: string,
	VERDE_IAGRO: string,
	AMARELO: string,
	LARANJA: string,
	LARANJA_AVERMELHADO: string,
	CINZA_QUENTE: string,
	CINZA: string,
	CINZA_FRIO: string,
	CINZA_OPACO: string,
	BRANCO: string,
	PRETO: string,
	GRAFITE: string
}

export interface ITema {
	nome: 'light' | 'dark',
	cores: ICores,

}

export const TemaClaro: ITema = {
	nome: 'light',
	cores: {
		//SISTEMA
		PRIMARIA: AZUL_MARINHO,
		SECUNDARIA: GRAFITE,
		FONTE_CLARA: BRANCO,
		FONTE_ESCURA: GRAFITE,
		FUNDO: BRANCO,
		CONTRASTE: GRAFITE,

		...CORES_GERAIS,
		// ACTIONS
		SUCESSO: VERDE_IAGRO,
		ATENCAO: paleta(AMARELO, 1),
		// ERRO: paleta(VERMELHO, 1),
		// INFO: AZUL,

		BOTAO_GERAL: AZUL_MARINHO,
		FONTE_MENU_SELECIONADA: AZUL_MARINHO_OPACO,
		FONTE_MENU: CINZA_OPACO,
	}
};

export const TemaEscuro: ITema = {
	nome: 'dark',
	cores: {
		//SISTEMA
		PRIMARIA: paleta(AZUL_MARINHO, 4),
		SECUNDARIA: BRANCO,
		FONTE_CLARA: GRAFITE,
		FONTE_ESCURA: BRANCO,
		FUNDO: GRAFITE,
		CONTRASTE: BRANCO,

		...CORES_GERAIS,

		// ACTIONS
		SUCESSO: paleta(VERDE_IAGRO, 9),
		ATENCAO: paleta(AMARELO, 9),
		ERRO: paleta(VERMELHO, 7),
		INFO: paleta(AZUL_MARINHO, 5),

		BOTAO_GERAL: BRANCO,
		FONTE_MENU_SELECIONADA: paleta(AZUL_MARINHO, 5),
		FONTE_MENU: paleta(CINZA_FRIO, 6),
	}
};