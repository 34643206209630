import React, { FC, MouseEvent, useCallback, useEffect, useMemo, useState } from 'react';
import {
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableRow,
	Box,
	Typography,
	Stack,
	TableHead,
	Icon,
	Button,
	TablePagination,
	TableSortLabel,
	ThemeProvider,
	IconButton,
	Skeleton,
	Pagination,
	InputLabel,
	Select,
	MenuItem,
	SelectChangeEvent,
	FormControl
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import { LayoutBaseDePagina } from 'shared/layouts';
import { Usuario } from 'models';
import { EOrdenacao, EPermissao, ESituacao } from 'enums';
import { combineLatest, from, of, switchMap, take, tap, throwError } from 'rxjs';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDebounce } from 'shared/hooks';
import { UsuarioService } from 'shared/services/Usuarios/UsuariosService';
import { visuallyHidden } from '@mui/utils';
import { createTheme, useTheme } from '@mui/material/styles';
import * as locales from '@mui/material/locale';

import { useAutenticacaoContext, useAuthContext, useEmpresaSelecionadaContext, useEspera, useMensagem, useTema } from 'contexts';
import { ToastErro, ToastSucesso } from 'utils/toast';
import { AlterButtonWithIcon, CancelButtonWithIcon, DefaultButtonWithIcon, DeleteButtonWithIcon, ViewButtonWithIcon } from 'shared/components/buttons';
import { PermissaoService } from 'shared/services/Permissoes/PermissoesService';

interface IUsuarioTable {
	usuarioID: number;
	nome: string;
	email: string;
	situacao: string;
}

interface HeadCell {
	disablePadding: boolean;
	id: keyof IUsuarioTable;
	label: string;
	left: boolean;
}

const headCells: readonly HeadCell[] = [
	{
		id: 'usuarioID',
		left: true,
		disablePadding: true,
		label: 'ID',
	},
	{
		id: 'nome',
		left: true,
		disablePadding: false,
		label: 'Nome',
	},
	{
		id: 'email',
		left: false,
		disablePadding: false,
		label: 'E-mail',
	},
	{
		id: 'situacao',
		left: false,
		disablePadding: false,
		label: 'Situação',
	},
];

interface EnhancedTableProps {
	onRequestSort: (event: React.MouseEvent<unknown>, property: keyof IUsuarioTable) => void;
	order: EOrdenacao;
	orderBy: string;
}

function EnhancedTableHead(props: EnhancedTableProps) {
	const { order, orderBy, onRequestSort } = props;
	const createSortHandler =
		(property: keyof IUsuarioTable) => (event: React.MouseEvent<unknown>) => {
			onRequestSort(event, property);
		};

	return (
		<TableHead>
			<TableRow>
				{headCells.map((headCell) => {

					const orderDirection = EOrdenacao[order].toLowerCase() as 'asc' | 'desc';
					const sortDirection = orderBy === headCell.id ? orderDirection : false;
					const direction = orderBy === headCell.id ? orderDirection : 'asc';
					const iconName = order == EOrdenacao.DESC ? 'sorted descending' : 'sorted ascending';

					return (
						<TableCell
							key={headCell.id}
							align={headCell.left ? 'left' : 'center'}
							padding='normal'
							sortDirection={sortDirection}
						>
							<TableSortLabel
								active={orderBy === headCell.id}
								direction={direction}
								onClick={createSortHandler(headCell.id)}
							>
								{headCell.label}
								{orderBy === headCell.id ? (
									<Box component='span' sx={visuallyHidden}>
										{iconName}
									</Box>
								) : null}
							</TableSortLabel>
						</TableCell>
					);
				})}

				<TableCell
					key={9999}
					align='center'
					padding='normal'
				>
					&nbsp;
				</TableCell>
			</TableRow>
		</TableHead>
	);
}

export const Usuarios: React.FC = () => {
	const { debounce } = useDebounce(300, false);
	const [usuarios, setUsuarios] = useState<IUsuarioTable[]>([]);
	const [permissoes, setPermissoes] = useState<EPermissao[]>([]);

	const { state } = useLocation();
	const { moduloID } = state;
	const navigate = useNavigate();

	const [ordenarPor, setOrdenarPor] = useState<EOrdenacao>(EOrdenacao.ASC);
	const [campoOrdenacao, setCampoOrdenacao] = useState<keyof IUsuarioTable>('usuarioID');
	const [pagina, setPagina] = useState(1);
	const [total, setTotal] = useState(0);
	const [qtdePorPagina, setQtdePorPagina] = useState(parseInt(process.env.REACT_APP_LIMITE_DE_LINHAS!));
	const theme = useTheme();
	const {
		cores: {
			ATENCAO
		}
	} = useTema();
	const { Informacao } = useMensagem();
	const { BloquearTela, DesbloquearTela } = useEspera();
	const { empresaSelecionada, perfilSelecionado } = useAuthContext();

	const handleRequestSort = (
		event: MouseEvent<unknown>,
		property: keyof IUsuarioTable,
	) => {
		const isAsc = campoOrdenacao === property && ordenarPor === EOrdenacao.ASC;
		setOrdenarPor(isAsc ? EOrdenacao.DESC : EOrdenacao.ASC);
		setCampoOrdenacao(property);
	};

	const handleChangePage = (event: unknown, newPage: number) => {
		if (newPage == pagina)
			return;
		BloquearTela();
		setPagina(newPage);
	};

	const handleChangeRowsPerPage = (event: SelectChangeEvent) => {
		setQtdePorPagina(parseInt(event.target.value));
		setPagina(1);
	};

	const emptyRows = useMemo(() => {
		const totalPaginas = Math.ceil(total / qtdePorPagina);
		const ehUltimaPagina = totalPaginas == pagina;
		if (ehUltimaPagina)
			return qtdePorPagina - usuarios.length;
		return 0;
	}, [qtdePorPagina, usuarios.length]);

	const textoTotalRegistros = useMemo(() => {
		const numRegistroInicial = (pagina - 1) * qtdePorPagina;
		let numRegistroFinal = numRegistroInicial + qtdePorPagina;
		if (numRegistroFinal > total)
			numRegistroFinal = total;
		return `${numRegistroInicial + 1} - ${numRegistroFinal} de ${total}`;
	}, [pagina, total, qtdePorPagina]);

	const themeWithLocale = React.useMemo(
		() => createTheme(theme, locales['ptBR']),
		[theme],
	);

	const getUsuarios = useCallback(() => {
		BloquearTela();
		combineLatest({
			usuario: from(UsuarioService.getWithPagination(pagina, qtdePorPagina, campoOrdenacao, ordenarPor, { empresaID: empresaSelecionada?.empresaID }))
				.pipe(
					switchMap((retorno: any | Error) => {
						if (retorno instanceof Error)
							return throwError(() => retorno);

						const { usuarios, total } = retorno;

						return of({
							usuarios: usuarios
								.map(({
									usuarioID,
									nome,
									email,
									situacaoID
								}: Usuario) => ({
									usuarioID,
									nome,
									email,
									situacao: ESituacao[situacaoID]
								} as IUsuarioTable)),
							total
						});
					}),
					tap(() => DesbloquearTela()),
				),
			permissoes: from(PermissaoService.getPermissionsByModule(moduloID, perfilSelecionado?.perfilID || 1))
				.pipe(
					switchMap((resposta) => {
						if (resposta instanceof Error) return throwError(() => resposta.message);
						return of(resposta.map(permissaoId => permissaoId as EPermissao));
					})
				),
		})
			.pipe(
				take(1),
				tap(() => DesbloquearTela())
			)
			.subscribe({
				next: ({ usuario: { usuarios, total }, permissoes }: any) => {
					setPermissoes(permissoes);
					setUsuarios(usuarios);
					setTotal(total);
				},
				error: (error) => ToastErro(error)
			});
	}, [pagina, qtdePorPagina, campoOrdenacao, ordenarPor, perfilSelecionado]);

	const InativarUsuario = useCallback((usuarioID: number, nomeFantasia: string) => {
		Informacao(
			'Atenção',
			() =>
				<Typography display='flex' flexDirection='row' whiteSpace='nowrap'>
					Deseja cancelar a Usuario <Typography fontWeight={700}>&nbsp;{nomeFantasia}&nbsp;</Typography>?
				</Typography>,
			[
				{
					label: 'Sim',
					onPress: () => {
						BloquearTela();
						from(UsuarioService.inative(usuarioID))
							.pipe(
								switchMap((retorno: boolean | Error) => {
									if (retorno instanceof Error)
										return throwError(() => retorno.message);
									return of(retorno);
								}),
								tap(() => {
									getUsuarios();
									DesbloquearTela();
								})
							)
							.subscribe({
								next: () => ToastSucesso(`Usuario inativada com sucesso`),
								error: (erro) => ToastErro(erro),
							});
					}
				},
				{
					label: 'Cancelar',
					fechar: true
				}
			]
		);
	}, []);

	useEffect(() => {
		if (perfilSelecionado)
			getUsuarios();
	}, [pagina, qtdePorPagina, campoOrdenacao, ordenarPor, perfilSelecionado]);

	return (
		<LayoutBaseDePagina>
			<Box padding={1} display='flex' justifyContent='center' alignItems='center' sx={{ backgroundColor: '#D3D3D3' }}>
				<Grid
					container
					spacing={1}
					component={Paper}
					elevation={0}
					sx={{ flexGrow: 1 }}
					display='flex'
					flexDirection='column'
					justifyContent='center'
					alignItems='center'
					padding={2}
				>
					<Grid
						display='flex'
						flexDirection='row'
						size={12}
					>
						<Grid size={3} />
						<Grid
							size={8}
							display='flex'
							flexDirection='column'
							justifyContent='center'
							alignItems='center'
						>
							<Typography variant='h4'>
								Usuario
							</Typography>
						</Grid>
						<Grid
							size={3}
							container
							sx={{
								padding: '1rem',
								justifyContent: 'end',
							}}>

						</Grid>
					</Grid>
					<Grid size={12}>
						<ThemeProvider theme={themeWithLocale}>
							<TableContainer>
								<Table
									sx={{ minWidth: 750 }}
									aria-labelledby='tableTitle'
									size='small'
								>
									<EnhancedTableHead
										order={ordenarPor}
										orderBy={campoOrdenacao}
										onRequestSort={handleRequestSort}
									/>
									<TableBody>
										{
											!usuarios.any() &&
											Array.from({ length: qtdePorPagina }).map((_, index) =>
												<TableRow key={index}>
													<TableCell colSpan={6} padding='none' sx={{ borderBottom: 0 }}>
														<Skeleton animation='wave' height={42} sx={{ transform: 'scale(1, .9)' }} />
													</TableCell>
												</TableRow>
											)
										}
										{usuarios.map(({
											usuarioID,
											nome,
											email,
											situacao
										}) => {
											return (
												<TableRow hover key={usuarioID}>
													<TableCell align='left'>{usuarioID}</TableCell>
													<TableCell align='left'>{nome}</TableCell>
													<TableCell align='center'>{email}</TableCell>
													<TableCell align='center'>{situacao}</TableCell>
													<TableCell align='right' width='15%'>
														<DefaultButtonWithIcon icon='domain_add' bgColor={ATENCAO} tooltip={{ title: 'Vincular Empresas' }} onClick={() => navigate('/vincularEmpresa', { state: { usuarioID } })} />
														<ViewButtonWithIcon hide={!permissoes.some(permissaoId => permissaoId == EPermissao.VISUALIZAR)} onClick={() => navigate('/visualizarUsuario', { state: { usuarioID } })} />
														<CancelButtonWithIcon hide={!permissoes.some(permissaoId => permissaoId == EPermissao.CANCELAR)} onClick={() => InativarUsuario(usuarioID, nome)} />
													</TableCell>
												</TableRow>
											);
										})}
										{
											Array.from({ length: emptyRows }).map((_, index) =>
												<TableRow key={index} sx={{ opacity: .3 }}>
													<TableCell colSpan={6} padding='none' sx={{ borderBottom: 0 }}>
														<Skeleton height={42} sx={{ transform: 'scale(1, .9)' }} />
													</TableCell>
												</TableRow>
											)
										}
									</TableBody>
								</Table>
							</TableContainer>
							<Grid display='flex' justifyContent='space-between' alignItems='center' marginTop={2}>
								<FormControl sx={{ m: 1, minWidth: 120 }} size='small'>
									<InputLabel id='demo-select-small-label'>Qtde Linhas</InputLabel>
									<Select
										labelId='demo-select-small-label'
										id='demo-select-small'
										value={qtdePorPagina.toString()}
										label='Total Linhas'
										onChange={handleChangeRowsPerPage}
									>
										<MenuItem value={10}>10</MenuItem>
										<MenuItem value={20}>20</MenuItem>
										<MenuItem value={30}>30</MenuItem>
									</Select>
								</FormControl>
								<Pagination
									count={Math.ceil(total / qtdePorPagina)}
									variant='outlined'
									shape='rounded'
									onChange={handleChangePage}
									page={pagina}
								/>
								<Typography marginRight={2} fontWeight={600}>{textoTotalRegistros}</Typography>
							</Grid>
						</ThemeProvider>
					</Grid>
				</Grid>
			</Box>
			<Grid container sx={{ padding: '1rem', justifyContent: 'start' }}>
				<Button
					color='info'
					disableElevation
					variant='contained'
					onClick={() => navigate(-1)}
					startIcon={<Icon>arrow_back</Icon>} >
					<Typography variant='button' whiteSpace='nowrap' textOverflow='ellipsis' overflow='hidden'>
						Voltar
					</Typography>
				</Button>
			</Grid>
		</LayoutBaseDePagina >
	);
};
