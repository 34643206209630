import { RegrasDeValidacao, Validador } from './Validador';

export type DadosFormulario = {
	nome: string;
	descricao: string;
	semDescricao: boolean;
	baseLegal: string;
	unidadeTempo: string | undefined;
	tempo: string | number | undefined;
}

const regrasDeValidacao: RegrasDeValidacao<DadosFormulario> = {
	nome: (valor: string) => {
		if (!valor) {
			return { valido: false, mensagem: 'Nome é obrigatório' };
		}
		return { valido: true, mensagem: null };
	},
	baseLegal: (valor: string) => {
		if (!valor) {
			return { valido: false, mensagem: 'Base Legal é obrigatória' };
		}
		return { valido: true, mensagem: null };
	},
	descricao: ([valor, semDescricao]: any) => {
		if (!semDescricao && !valor) {
			return { valido: false, mensagem: 'Descrição é obrigatória' };
		}
		return { valido: true, mensagem: null };
	},
	unidadeTempo: (valor: string | undefined) => {
		if (!valor) {
			return { valido: false, mensagem: 'Unidade Tempo é obrigatório' };
		}
		return { valido: true, mensagem: null };
	},
	tempo: (valor: string | number | undefined) => {
		if (!valor) {
			return { valido: false, mensagem: 'Tempo é obrigatório' };
		}
		return { valido: true, mensagem: null };
	},
};

export const ValidadorTipologia = new Validador<DadosFormulario>(regrasDeValidacao);