import { Setor } from 'models';
import { ObjectToQuery } from 'utils';
import { Api } from '../Axios';
import { EOrdenacao } from 'enums';
import { SetorRequest } from 'requests';

export interface IListagemSetor {
	setorID: number;
	nome: string;
}

type TSetoresComTotalCount = {
	data: Setor[];
	totalCount: number;
}

type TSetoresSomenteTotalCount = {
	totalCount: number;
}

export interface IListagemSetorCampos {
	setorID: string;
	fieldName: string;
	ordem: string;
	tipoID: string;
	titulo: string;
}

type TSetoresComTotal = {
	setores: Setor[];
	total: number;
}

const getTotal = async (page = 1, filter = ''): Promise<TSetoresSomenteTotalCount | Error> => {
	try {
		const urlRelativa = '/Setor/ObterQtdeTotal';
		const { data } = await Api.get(urlRelativa);
		return {
			totalCount: Number(data.data),
		};
	} catch (error) {
		return new Error((error as { message: string }).message || 'Erro ao listar os registros.');
	}
};

const getAll = async (empresaID?: number): Promise<TSetoresComTotalCount | Error> => {
	try {
		const params = ObjectToQuery({ empresaID });
		const urlRelativa = `/Setor/ObterTodos?${params}`;
		const { data, headers } = await Api.get(urlRelativa);

		if (data) {
			return {
				data: data.data,
				totalCount: Number(headers['x-total-count'] || parseInt(process.env.REACT_APP_LIMITE_DE_LINHAS!)),
			};
		}
		return new Error('Erro ao listar os registros.');
	} catch (error) {
		return new Error((error as { message: string }).message || 'Erro ao listar os registros.');
	}
};

const getAllFiels = async (): Promise<IListagemSetorCampos[] | Error> => {
	try {
		const urlRelativa = '/SetorCampos/ObterTodos';
		const { data, headers } = await Api.get(urlRelativa);

		if (data)
			return data.data;

		return new Error('Erro ao listar os registros.');
	} catch (error) {
		return new Error((error as { message: string }).message || 'Erro ao listar os registros.');
	}
};

const getFields = async (setorId = '', processoId = ''): Promise<IListagemSetorCampos[] | Error> => {
	try {
		const urlRelativa = `/SetorCampos/ObterCamposPorSetorId?setorId=${setorId}&processoId=${processoId}`;
		const { data, headers } = await Api.get(urlRelativa);

		if (data) {
			return data.data;
		}
		return new Error('Erro ao listar os registros.');
	} catch (error) {
		return new Error((error as { message: string }).message || 'Erro ao listar os registros.');
	}
};

const getWithPagination = async (pagina: number = 1, qtdLinha: number = 10, campoOrdenacao: string, ordenacaoPor: EOrdenacao = EOrdenacao.ASC, filtros: any = {}): Promise<TSetoresComTotal | Error> => {
	try {
		const queryParams = ObjectToQuery({
			pagina,
			qtdLinha,
			ordenacaoPor,
			campoOrdenacao,
			...filtros
		});
		const urlRelativa = `/Setor/ObterPaginado?${queryParams}`;
		const { data } = await Api.get(urlRelativa);

		if (data && data.data)
			return {
				setores: data.data.lista?.map((setor: any) => new Setor(setor)),
				total: data.data.total
			};

		return new Error('Erro ao listar os registros.');
	} catch (error) {
		return new Error((error as { message: string }).message || 'Erro ao listar os registros.');
	}
};

const getById = async (id: number): Promise<Setor | Error> => {
	try {
		const { data } = await Api.get(`/Setor/ObterPorId?setorID=${id}`);

		if (data) {
			return data.data;
		}
		return new Error('Erro ao obter o registro.');
	} catch (error) {
		return new Error((error as { message: string }).message || 'Erro ao obter o registro.');
	}
};

const getByEnterpriseId = async (id: number): Promise<Setor[] | Error> => {
	try {
		const { data } = await Api.get(`/Setor/ObterPorId?empresaID=${id}`);

		if (data) {
			return data.data;
		}
		return new Error('Erro ao obter o registro.');
	} catch (error) {
		return new Error((error as { message: string }).message || 'Erro ao obter o registro.');
	}
};

const getSectorsWithTipologies = async (empresaId: number): Promise<Setor[] | Error> => {
	try {
		const { data } = await Api.get(`/Setor/ObterSetoresComTipologias?empresaID=${empresaId}`);

		if (data) {
			return data.data;
		}
		return new Error('Erro ao obter o registro.');
	} catch (error) {
		return new Error((error as { message: string }).message || 'Erro ao obter o registro.');
	}
};

const save = async (setor: SetorRequest): Promise<Setor | Error> => {
	try {
		const urlRelativa = `/Setor`;
		const { data } = await Api.post(urlRelativa, setor);

		if (data) {
			if (data.data)
				return data.data;

			return new Error(data.errors.join('<br/>'));
		} else
			return new Error('Erro ao cadastrar setor.');
	} catch (error) {
		return new Error((error as { message: string }).message || 'Erro ao salvar!');
	}
};

const update = async (setor: SetorRequest): Promise<Setor | Error> => {
	try {
		const urlRelativa = `/Setor/Alterar`;
		const { data } = await Api.post(urlRelativa, setor);

		if (data) {
			if (data.data)
				return data.data;

			return new Error(data.errors.join('<br/>'));
		} else
			return new Error('Erro ao alterar setor.');
	} catch (error) {
		return new Error((error as { message: string }).message || 'Erro ao atualizar!');
	}
};

const inative = async (setorID: number): Promise<boolean | Error> => {
	try {
		const urlRelativa = `/Setor/Inativar`;
		const { data } = await Api.post(urlRelativa, { setorID });

		if (data) {
			if (data.data)
				return data.data;

			return new Error(data.errors.join('<br/>'));
		} else
			return new Error('Erro ao inativar setor.');
	} catch (error) {
		return new Error((error as { message: string }).message || 'Erro ao inativar!');
	}
};

export const SetorService = {
	getTotal,
	getAll,
	getFields,
	getById,
	getByEnterpriseId,
	getSectorsWithTipologies,
	getAllFiels,
	getWithPagination,
	save,
	update,
	inative
};