import { EPerfil, ESituacao } from '../enums';
import { Modulo } from './Modulo';

export class Perfil {
	perfilID: EPerfil;
	nome?: string;
	situacaoID: ESituacao;
	usuarioIDCadastro?: number;
	usuarioNomeCadastro?: string;
	dataHoraCadastro?: string;
	usuarioIDAlteracao?: number;
	usuarioNomeAlteracao?: string;
	dataHoraAlteracao?: string;
	modulos?: Modulo[];

	constructor(dto?: any) {
		this.perfilID = dto?.perfilID as EPerfil;
		this.nome = dto?.nome;
		this.situacaoID = dto?.situacaoID as ESituacao;
		this.usuarioIDCadastro = dto?.usuarioIDCadastro;
		this.usuarioNomeCadastro = dto?.usuarioNomeCadastro;
		this.dataHoraCadastro = dto?.dataHoraCadastro?.replace('Z', '');
		this.usuarioIDAlteracao = dto?.usuarioIDAlteracao;
		this.usuarioNomeAlteracao = dto?.usuarioNomeAlteracao;
		this.dataHoraAlteracao = dto?.dataHoraAlteracao?.replace('Z', '');
		this.modulos = dto?.modulos.map((modulo: any) => new Modulo(modulo));
	}
}