import { EPermissao } from '../enums';

export class Permissao {
	permissaoID: number;
	nome: string;

	constructor(dto: any) {
		this.permissaoID = dto?.permissaoID;
		this.nome = dto?.nome;
	}
}